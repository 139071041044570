.footer .group-cols {
  display: flex;
  justify-content: space-between;
  min-width: 40%;
}

@media only screen and (max-width: 767px) {
    .footer .group-cols {
        border-top:1px solid #1a444b;
        padding-top: 32px;
        margin-top: 32px
    }
}



.footer .links-3 {
  margin-left: 3%
}

@media only screen and (max-width: 929px) {
  .footer .links-3 {
      margin:0
  }
}


.footer .social {
  margin-left: -5px
}

.footer .social li {
  display: inline-block;
  margin-right: 10px
}

.footer .social a {
  display: block;
  padding: 5px
}

.footer .social a:hover svg {
  opacity: 1
}

.footer .social svg {
  height: 20px;
  width: 20px;
  fill: #8eb1b2;
  fill: #fff;
  opacity: .75;
  transition: opacity .2s ease-out
}




.accessibe-only {
  position: absolute;
  display: block;
  cursor: text;
  margin: -1px 0 0 -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  font-weight: 400;
  font-size: 15px;
  line-height: 1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
  outline: 0
}

.accessibe-only .accessibe-only-links {
  position: static;
  display: initial
}
