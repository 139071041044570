.m-pageTop {
  z-index: 10;
  position: relative;
  height: 0;
  width: 100%;
  transition: all 300ms 0s ease;
  a {
    color: #fff;
    display: flex;
    background: $orange;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    margin: 0 100px 150px 0;
    transition: all 300ms 0s ease;
    @include sp() {
      width: 80/750 * 100vw;
      height: 80/750 * 100vw;
      margin: 0 30/750 * 100vw 30/750 * 100vw 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }
  &.is-fixed a {
    position: fixed;
    margin: 0 100px 50px 0;

    @include sp() {
      margin: 0 30/750 * 100vw 50/750 * 100vw 0;
    }
  }
  &.is-top{
    display: none;
  
  }
}
