.p-fundsListHead{
  padding:0 0 60px;

  h3 {
		font-family: $sans2;
		@include fs(22);
    line-height: 1;
    font-weight: 400;

		text-transform: uppercase;
		color: $marine;
		margin-bottom: 30px;
		padding-bottom: 10px;
		border-bottom: 4px solid #E5E6E5;

  }
  p{
    @include fs(14);
  }

}
