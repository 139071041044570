/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.globalxfunds.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2016
 */

@font-face {
    font-family: "Brown-Regular";
    src:url("/assets/fonts/lineto-brown-regular.eot");
    src:url("/assets/fonts/lineto-brown-regular.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/lineto-brown-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?t5l4r3');
  src:  url('/assets/fonts/icomoon.eot?t5l4r3#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.woff2?t5l4r3') format('woff2'),
    url('/assets/fonts/icomoon.ttf?t5l4r3') format('truetype'),
    url('/assets/fonts/icomoon.woff?t5l4r3') format('woff'),
    url('/assets/fonts/icomoon.svg?t5l4r3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e900";
}
.icon-cal:before {
  content: "\1f4c6";
}
.icon-chart:before {
  content: "\1f4ca";
}
.icon-help:before {
  content: "\2753";
}
.icon-down:before {
  content: "\2b07";
}
.icon-grid:before {
  content: "\25a6";
}
.icon-export:before {
  content: "\2398";
}
.icon-arrow:before {
  content: "→";
}
