

/* ==============================================
   PAGE SPECIFIC 
============================================== */




#page-404 {

	.sidebar-search {

		form {
			position: relative;
			max-width: 440px;
		}

		.field {
			height: 60px;
			width: 100%;
			border: 1px solid $sea;
			background-color: #fff;
			font-size: 16px;
			font-family: $sans2;
			font-weight: 300;
			padding: 0 94px 0 26px;
			margin-bottom: 15px;
			-webkit-box-shadow: none;
			box-shadow: none;
			-webkit-appearance: none;

			&::placeholder {
				transition: opacity .5s ease-out;
			}

			&:focus,
			&.focused {
				outline: none;

				&::placeholder {
					opacity: 0;
				}
			}
		}
		.s-btn {
			// The label associates the submit btn and svg elements together, so if you click one of them it acts like you clicked on the another as well.
			position: absolute;
			right: 0;
			top: 0;
			width: 60px;
			height: 60px;
			border-left: 1px solid $sea;
			line-height: 60px;
			display: block;
			cursor: pointer;
			transition: background-color 0.3s ease-out;

			&:hover {
				background-color: $sea;

				svg {
					fill: #fff;
				}
			}
		}
		.submit {
			display: none;
		}
		svg {
			width: 24px;
			position: absolute;
			top: 20px;
			right: 18px;
			fill: $sea;
			transition: fill 0.7s ease-out;
		}

	}

}











/* CONTACT
---------------------------------------------- */


#contact {

	h2 {
		@include hhh4;
		text-transform: uppercase;
		border-bottom: 3px solid $lines;
		padding-bottom: 30px;
		margin-bottom: 30px;
		color: $marine;
	}

	.c-info {
		margin-bottom: 60px;

		h3 {
			font-family: $sans2;
			@include fs(24,34);
			margin-bottom: 8px;
			color: $marine;
		}
	}
}









// /* SEARCH
// ---------------------------------------------- */

// #search {

// 	.query {
// 		margin-top: 80px;
// 		margin-bottom: 60px;
// 	}
// 	h1 {
// 		@include hhh4;
// 		text-transform: uppercase;
// 		border-bottom: 3px solid $lines; 
// 		padding-bottom: 25px;
// 		margin-bottom: 25px;
// 		color: $marine;
// 	}
// 	// .group {
// 	// 	margin-bottom: 50px;
// 	// }
// 	// .group-title {
// 	// 	display: block;
// 	// 	@include hhh2;
// 	// }
// 	.results {
// 		margin-bottom: 80px;

// 		.entry {
// 			border-bottom: 1px solid $lines;
// 			padding: 30px 0;

// 			// img {
// 			// 	float: left;
// 			// 	width: 75px;
// 			// 	height: 75px;
// 			// 	margin: 8px 20px 10px 0;
// 			// 	display: block;
// 			// 	background-color: $marine;
// 			// }
// 			p {
// 				@include fs(14,23,400);
// 				// margin-left: 95px;
// 				padding-bottom: 1px;
// 			}
// 		}
// 		strong {
// 			background-color: yellow;
// 			font-weight: 700;
// 		}
// 	}
// }




