

/* PRESS
---------------------------------------------- */

#press {

	.col1,
	.col2 {
		h2 {
			@include hhh4;
			text-transform: uppercase;
			border-bottom: 4px solid $lines;
			padding-bottom: 24px;
			margin-bottom: 20px;
			color: $marine;
		}
	}
	.press-entries {

		li {
			border-bottom: 1px solid $lines;
			padding-bottom: 40px;
			margin-bottom: 20px; 
		}
		.dt {
			color: $granite;
			font-family: $sans2;
			@include fs(15,28,400,0.5);
			margin-top: 7px;
		}
		h3 {
			font-family: $sans2;
			@include fs(24,40,400);
			margin-bottom: 6px;

			a {
				color: $marine;
			}
		}
		h4 {
			font-family: $sans2;
			@include fs(17,40,400,0.2);
		}
		p {
			margin-top: 15px;
			color: $granite;
			@include fs(15,28,400,0.5);
		}
	}
	.view-all-press {
		font-family: $sans2;
		@include fs(17,25,400,0.5);

		&:hover {

			&:after {
				bottom: -6px;
			}
			&.opened:after {
				bottom: 6px;
			}
		}

		&:after {
			display: inline-block;
			content: '→';
			transform: rotate(90deg);
			transition: left 0.3s;
			position: relative;
			bottom: 0;
			margin-left: 6px;
			transition: bottom 0.3s;
		}
		&.opened {
			 &:after {
				transform: rotate(-90deg);
			 }
		}

	}
	.press-contact {
		color: $granite;
		@include fs(17,27,400,0.1);
		margin-bottom: 60px;

		h3 {
			font-style: italic;
			margin-bottom: 13px;
		}
	}
	#press-archive {
		margin-top: 20px;

		li {
			border-bottom: 1px solid $lines;
			padding: 10px 0;

			h3 {
				@include fs(16,20,400,0.1);

				a {
					color: $marine;
				}

			}
			h4 {
				@include fs(16,20,400,0.1);
				margin-bottom: 6px;
			}
			p {
				@include fs(12,16,400);
				color: $granite;
			}
		}
	}
}


