.p-topHeadNews{
  display: block;
  margin: auto;
  background: #fff;
  color: #002F37;
  padding: 18px 60px;
  width: 100%;
  @include sp() {
    @include vw(30);
    padding: 18/750*100vw 60/750*100vw;
    
  }

}
