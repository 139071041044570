
/* Clearing
================================================== */
.container:after {
	content: "\0020";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.clearfix:before,
.clearfix:after,
.cf:before,
.cf:after,
.row:before,
.row:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.row:after,
.clearfix:after,
.cf:after {
	clear: both;
}

.clear {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.ir {
	display: block;
	text-indent: -999em;
	overflow: hidden;
	background-repeat: no-repeat;
	text-align: left;
	direction: ltr;
}

.hidden {
	display: none;
	visibility: hidden;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

.invisible {
	visibility: hidden;
}


/* Classes For Wordpress
================================================== */
.center {
	text-align: center;
}

.aligncenter {
	display: block;
	margin: 0 auto;
}

.alignleft {
	float: left;
	margin: 6px  1.2em 1.2em 0 !important;
	width: 50%;
}

.alignright {
	float: right;
	margin: 0 0 1.2em 1.2em !important;
}

.wp-caption-text {
	font-size: 0.8125em;
	text-align: center;
	font-style: italic;
	letter-spacing: 0.1em;
}

.wp-caption {
	width: 100%;
	margin-bottom: 1.5em;
}

.wp-caption img {
	display: block;
}


/* Responsive Image and Video
================================================== */
.fullimg img, .full, .size-full { display: block; width: 100%; }
.iframe { position: relative; width: 100%; padding-bottom: 56.25%; /* 16/9 ratio */ height: 0; overflow: hidden; }
.iframe iframe { position: absolute; left: 0px; top: 0px; width: 100%; height: 100%; max-width: 100%; border: none;}




/* Internet Explorer
================================================== */
.update-yo-browser {
	margin: 0 0 0.5em 0;
	background-color: #f1f5f8;
	color: black;
	padding: 0.8em;
	text-align: center;
}
