.m-footListA {
  display: block;
  max-width: 690px;
  padding-bottom: 50px;
  width: 100%;
	@include mq(767) {
	    width: 100%;

	}

  &__item {
    display: block;
    width: 100%;
    
    a:not(:root){
      opacity: 1;
      display: block;
    }
  }
  &__btn {
    border-bottom: 1px solid #707070;
    padding: 10px 0;
    display: block;
    position: relative;


    &::before {
      content: "↓";
      transition: left 0.3s;
      position: absolute;
      right: 0;
      top: 10px;
      font-family: $sans2;
      font-weight: 400;
      display: block;
      transition: transform 0.3s;
      font-size: 18px;
      color: $orange;
    }
    &.is-open {
      &::before {
        // transform: rotate(180deg);
        content: "↑";
        
      }
    }
  }
  &__body {
    
    height: 0;
    padding:0;
    display: block;
    transition: all 300ms 0s ease;
    overflow: hidden;
    &.is-open {
      height: auto;

      padding: 10px 0 35px 0;
    }
  }
  &__text {
    @include fs(14);
    color: #fff;
    font-family: Brown-Regular, sans-serif;
    // &+&{
    //   padding-top: .6em;
    // }
  }
}
