.p-comTopList {
  padding-bottom: 110px;
  &__item {
    border-bottom: 4px solid $ngrey6;
    padding: 20px 0;
    @include fs(22);
    @include sp() {
      @include fs(18);
    }
  }
}
