@mixin font-brown {
	font-family: "Brown-Regular", sans-serif;
}
@mixin font-brownbold {
	font-family: "Brown-Bold", sans-serif;
}

@mixin hhh1 {
	font-family: $serif;
	@include fs(44,50,400);
}
@mixin hhh2 {
	font-family: $sans;
	font-weight: 700;
	font-size: 12px;
	line-height: 30px;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: $marine;
}
@mixin hhh3 {
	@include fs(36,44);
	font-family: $serif;
}
@mixin hhh4 {
	font-family: $sans2;
	@include fs(26,28,400,1.5);
}
@mixin hhh5 {
	@include font-brown;
	@include fs(26,28,400);
}


@mixin p1 {
	@include fs(14,26,400,0.1);
	margin-bottom: $mb;
}



@mixin thinfont {
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


@mixin after-arrow($color) {
	position: relative;

	&:hover {

		&:after {
			left: 15px;
		}
	}
	&:after {
		content: '→';
		@include font-brown;
		transition: left 0.3s;
		position: relative;
		left: 0;
		margin-left: 6px;
		color: $color;
	}
}




@mixin mq($width) {
	@if $width == 5999 {
		@media only screen and (max-width: 5999px) { @content; }
	}
	@else if $width == 1599 {
		@media only screen and (max-width: 1599px) { @content; }
	}
	@else if $width == 1399 {
		@media only screen and (max-width: 1399px) { @content; }
	}
	@else if $width == 1267 {
		@media only screen and (max-width: 1267px) { @content; }
	}
	@else if $width == 1239 {
		@media only screen and (max-width: 1239px) { @content; }
	}
	@else if $width == 1199 {
		@media only screen and (max-width: 1199px) { @content; }
	}
	@else if $width == 1189 {
		@media only screen and (max-width: 1189px) { @content; }
	}
	@else if $width == 1150 {
		@media only screen and (max-width: 1150px) { @content; }
	}
	@else if $width == 1101 {
		@media only screen and (max-width: 1101px) { @content; }
	}
	@else if $width == 1100 {
		@media only screen and (max-width: 1100px) { @content; }
	}
	@else if $width == 1079 {
		@media only screen and (max-width: 1079px) { @content; }
	}
	@else if $width == 1023 {
		@media only screen and (max-width: 1023px) { @content; }
	}
	@else if $width == 991 {
		@media only screen and (max-width: 991px) { @content; }
	}
	@else if $width == 929 {
		@media only screen and (max-width: 929px) { @content; }
	}
	@else if $width == 879 {
		@media only screen and (max-width: 879px) { @content; }
	}
	@else if $width == 767 {
		@media only screen and (max-width: 767px) { @content; }
	}
	@else if $width == 639 {
		@media only screen and (max-width: 639px) { @content; }
	}
	@else if $width == 599 {
		@media only screen and (max-width: 599px) { @content; }
	}
	@else if $width == 575 {
		@media only screen and (max-width: 575px) { @content; }
	}
	@else if $width == 479 {
		@media only screen and (max-width: 479px) { @content; }
	}
	@else if $width == 413 {
		@media only screen and (max-width: 413px) { @content; }
	}
	@else if $width == 374 {
		@media only screen and (max-width: 374px) { @content; }
	}

	@else if $width == 1400 {
		@media only screen and (min-width: 1400px) { @content; }
	}
	@else if $width == min1190 {
		@media only screen and (min-width: 1190px) { @content; }
	}
	@else if $width == min1101 {
		@media only screen and (min-width: 1101px) { @content; }
	}
	@else if $width == 992up {
		@media only screen and (min-width: 992px) { @content; }
	}
	@else if $width == 992 {
		@media only screen and (min-width: 992px) { @content; }
	}
	@else if $width == 960 {
		@media only screen and (min-width: 960px) { @content; }
	}
	@else if $width == 768 {
		@media only screen and (min-width: 768px) { @content; }
	}
	@else if $width == 600 {
		@media only screen and (min-width: 600px) { @content; }
	}

	@else if $width == h900 {
		@media only screen and (max-height: 900px) { @content; }
	}
	@else if $width == h800 {
		@media only screen and (max-height: 800px) { @content; }
	}
	@else if $width == h700 {
		@media only screen and (max-height: 700px) { @content; }
	}
	@else if $width == h600 {
		@media only screen and (max-height: 600px) { @content; }
	}
	@else if $width == h500 {
		@media only screen and (max-height: 500px) { @content; }
	}

	/* IE10+ CSS styles */
	@else if $width == ie {
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { @content; }
	}

}


@mixin retina($file, $type, $width, $height) {
	background-image: url('/images/' + $file + '.' + $type);

	@media
	(-webkit-min-device-pixel-ratio: 1.5),
	( min--moz-device-pixel-ratio: 1.5),
	( -o-min-device-pixel-ratio: 3/2),
	( min-device-pixel-ratio: 1.5),
	( min-resolution: 1.5dppx) {
		& {
			background-image: url('/images/' + $file + '@2x.' + $type);
			background-size: unquote( $width + 'px ' + $height + 'px ');
		}
	}
}


// Font Size Calculator 2.1
// -------------------------
// Calculate pixels to rem/em
// Provides a px fallback
// Context = default font size - set on html or body tag - usually 100% (16px)
//
// Usage @include fs(30, 36, 400);
//
// @mixin fs($font-size, $line-height, $weight: 400, $letter-spacing: 0, $context: 16) {
// 	font-size: $font-size * 1px;
// 	font-size: calc($font-size / $context * 1rem);
// 	line-height: calc($line-height / $font-size * 1em);
// 	font-weight: $weight;
// 	letter-spacing: calc($letter-spacing / $font-size * 1em);
// }

// Font Size Calculator 2
// -------------------------
// Calculate pixels to rem/em
// Provides a px fallback
// Context = default font size - set on html or body tag - usually 100% (16px)
//
// Usage @include fs(30, 36, 400);
//
@mixin fs($font-size, $line-height, $weight: 400, $letter-spacing: 0, $context: 16) {
	font-size: $font-size * 1px;
	font-size: ($font-size / $context) * 1rem;
	line-height: ($line-height / $font-size) * 1em;
	font-weight: $weight;
	letter-spacing: ($letter-spacing / $font-size) * 1em;
}

@mixin ie() {

}
