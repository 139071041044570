.p-fundCal {
  table {
    width: 100%;
    table-layout: fixed;
    margin: 20px 0;
  }
  th {
    background: #ff5400;
    color: #fff;
    border: 1px solid #e5e6e5;
    padding: 5px;
    @include fs(15);
  }
  td {
    border: 1px solid #e5e6e5;
    padding: 5px;
    text-align: right;
    @include fs(15);

    &:first-child {
      text-align: center;
    }
  }
}
