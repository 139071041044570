.p-conInte{
  padding: 0 0 80px;
  &__block{
    padding: 24px 0 20px;
    p+p{
      margin-top: 15px;
    }
    p{
      text-indent: 1em;
    }
    li{
      padding-left: 2em;
      text-indent: -2em;
    }
    li+li{
      margin-top: 15px;
    
    }
    table{
      margin-top: 15px;
      text-indent: 0;
      
      th{
        border: 1px solid #606060;
        padding: 2px 1em;
        font-weight: normal;
      }
      td{
        border: 1px solid #606060;
        padding: 10px 1em;
      }
      li{
        padding-left: 1em;
        text-indent: -1em;
      
      }
      li+li{
        margin-top: 8px;
      
      }
    }

  }
    &__listA{
    
      margin-top: 15px;
      padding-left: 1em;
      
        li{
      padding-left: 1.5em;
      text-indent: -1.5em;
    }
    }
}


