.sidebar-videos,.sidebar-videos figure {
    margin-bottom: 30px
}

// .sidebar-videos .video-img {
//     position: relative;
//     display: block;
//     overflow: hidden;
//     padding-bottom: 60%
// }

// .sidebar-videos .video-img:hover .play-button {
//     transform: translate(-50%,-50%) scale(1.1)
// }

// .sidebar-videos .video-img img {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%
// }

// .sidebar-videos .play-button {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     width: 70px;
//     height: 70px;
//     transform: translate(-50%,-50%) scale(1);
//     background-color: #fff;
//     border-radius: 50%;
//     opacity: .8;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     transition: all .2s ease-in-out
// }

// .sidebar-videos .play-button svg {
//     width: 24px;
//     height: 24px;
//     fill: #ff5400;
//     margin-left: 5px
// }

.sidebar-videos figcaption {
    padding: 8px 0 0;
    font-family: proxima-nova-condensed,sans-serif;
    font-size: 15px;
    font-size: .9375rem;
    line-height: 1.2em;
    font-weight: 400;
    letter-spacing: .01333em
}

.sidebar-videos .more {
    font-family: Brown-Regular,sans-serif;
    text-align: right;
    margin-top: -10px
}

.sidebar-videos .more a {
    color: #ff5400
}



.post-content h1 {
    font-size: 42px;
    font-size: 2.625rem;
    line-height: 1.09524em;
    letter-spacing: 0
}

.post-content h1,.post-content h2 {
    font-family: Brown-Regular,sans-serif;
    font-weight: 400;
    margin-bottom: 40px;
    color: #002f37
}

.post-content h2 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.41667em;
    letter-spacing: .00417em
}

.post-content p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.58824em;
    font-weight: 400;
    letter-spacing: .00588em;
    margin-bottom: 1.625rem;
    color: #606060
}

.post-content h4,.post-content strong {
    font-weight: 700;
    color: #002f37
}

.post-content em {
    font-style: italic
}

.post-content small {
    font-size: 13px;
    font-size: .8125rem;
    line-height: 1.38462em;
    font-weight: 400;
    letter-spacing: .01538em;
    color: #606060
}

.post-content ol,.post-content ul {
    margin-bottom: 1.625rem
}

.post-content ol {
    list-style-type: decimal
}

.post-content ol li {
    margin-left: 1.8em
}

.post-content ul li {
    margin-left: 1.8em;
    margin-bottom: 18px
}

.post-content ul li:before {
    content: "-";
    display: block;
    font-size: 3em;
    font-weight: 700;
    left: -.6em;
    max-height: 0;
    min-height: 0;
    position: relative;
    top: -2px;
    color: #ff5400
}

.post-content blockquote {
    text-align: left;
    border-left: 5px solid #ff5400;
    padding: 0 25% 0 50px;
    margin: 70px 0
}

@media only screen and (max-width: 479px) {
    .post-content blockquote {
        padding:0 0 0 25px
    }
}

.post-content blockquote p {
    color: #ff5400;
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1.28571em;
    font-weight: 400;
    letter-spacing: 0
}

.post-content video {
    width: 100%;
    height: auto;
    -o-object-fit: inherit;
    object-fit: inherit
}

.post-content .iframe {
    margin-bottom: 2rem
}
