.p-conAnti{
  padding-bottom: 100px;
  
  li{
    margin-top: 24px;
    padding-left: 1.2em;
    position: relative;
    @include fs(16);
    font-weight: 600;
    &+&{
      margin-top: 40px;
    }
    &::before {
      content:"";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #606060;
      position: absolute;
      top:  6px;
      left: 3px;
      border-radius: 5px;
    }
  }

}
