.p-resVideo{
  margin-top: 20px;
  // overflow: hidden;
  &__imgWrap{
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    
    img{
    display: block;
    width: 100%;

    }
    &::after {
      content:"";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 70px;
      height: 70px;
      // background: #fff;
      background:rgba($color: #ffff, $alpha: .8) url(/images/play.svg) no-repeat calc(50%  + 3px) 50% ;
      background-size: 24px 24px;
      border-radius: 50%;
      transition: all .2s ease-in-out;

      a:hover &{
          transform: translate(-50%,-50%) scale(1.1);
      }
    }
    
  }


}
