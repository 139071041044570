.m-pagePath {
  display: block;
  padding-top: 16px;
  display: flex;
  @include sp() {
    display: none;
  }
  &__item {
    display: block;
    @include fs(13);
    line-height: 1;
    font-family: Brown-Regular,sans-serif;
    a{
      color: inherit;
      @include fs(13);
      font-family: Brown-Regular,sans-serif;
      &:hover{
      text-decoration: underline;
      }
    }

    & + & {
      position: relative;
      padding-left: 3em;

      &::before {
        content: "";
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        position: absolute;
        top: 50%;
        left: 1em;
        margin-right: 1em;
        transform: translate(0, -50%)scaleY(.7) rotate(45deg) ;
      }
    }
  }
}
