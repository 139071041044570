/* Forms
================================================== */

/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */

/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */

button,
input,
optgroup,
select,
textarea {
	color: inherit; /* 1 */
	font: inherit; /* 2 */
	margin: 0; /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */

button {
	overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */

// button,
// select {
//   text-transform: none;
// }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button; /* 2 */
	cursor: pointer; /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
	cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

input {
	line-height: normal;
}

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box; /* 1 */
	padding: 0; /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
	-webkit-appearance: textfield; /* 1 */
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box; /* 2 */
	box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */

legend {
	border: 0; /* 1 */
	padding: 0; /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */

textarea {
	overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */

optgroup {
	font-weight: bold;
}









form input, 
form textarea,
form select { 
	font-family: $sans; 
}
input, textarea {
	-webkit-border-radius: 0;
}

::placeholder {
	color: $marine;
}




/* Spinner */
.gform_ajax_spinner {
	margin-left: 20px;
	position: relative;
	top: 8px;
	border: 4px solid $ngrey1;
	border-left: 4px solid $ngrey4;
	animation: spinner 1.1s infinite linear;
	border-radius: 50%;
	width: 30px;
	height: 30px;
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}









// reset .post-content
.post-content {

	.form-c1 {

		ul {
			margin-bottom: inherit;

			li {
				margin-left: inherit;

				&:before {
					display: none;
				}
			}
		}
	}
}


.form-c1 {
	padding-top: 30px;
	margin-bottom: 30px;

	.group {
		margin-bottom: 20px;
	}
	.left {
		float: left;
		width: 50%;
		clear: left;
		padding-right: 10px;

		@include mq(479) {
			float: none;
			width: 100%;
			padding: 0;
		}
	}
	.right {
		float: right;
		width: 50%;
		padding-left: 10px;

		@include mq(479) {
			float: none;
			width: 100%;
			padding: 0;
		}
	}
	label {
		font-family: $sans2;
		@include fs(14,18,400);

		span {
			color: $orange;
		}
	}
	.description {
		font-family: $sans;
		@include fs(14,18,400);
		margin-bottom: 8px;
	}
	.box {
		width: 100%;
		border: none;
		background-color: $ngrey7;
		padding: 10px;
		-webkit-appearance: none;

		@include mq(479) {
			width: 100%;
		}

		&:focus {
			background-color: $ngrey6;
			outline: none;

			&::placeholder {
				color: $text-color;
			}
		}
		&.large {
			width: 100%;
		}
	}
	.cb {
		clear: both;
	}
	.radio li {
		position: relative;
		margin-top: 18px;
		margin-bottom: 20px;
		display: inline-block;
		margin-right: 40px;
		clear: both;
	}
	.checkbox li {
		position: relative;
		margin-top: 18px;
		margin-bottom: 20px;
	}
	input[type=radio],
	input[type=checkbox] {
		/*display: none;*/
		left: 0px;
		top: 0;
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
		z-index: -1;
	}
	.choice label {
		padding-left: 50px;
	}
	.choice label:before,
	.choice label:after {
		bottom: 0;
		content: '';
		margin: auto;
		position: absolute;
		top: 0;
	}
	.choice label:after {
		content: "";
		background-color: transparent;
		border: solid 2px $ngrey3;
		display: inline-block;
		height: 34px;
		left: 0;
		position: absolute;
		top: 0;
		transition: left .25s, background-color .13s;
		width: 34px;
	}
	input[type="checkbox"]:checked + label:before,
	input[type="radio"]:checked + label:before {
		content: '';
		width: 19px;
		height: 14px;
		background: transparent url('/images/check.svg') 0 no-repeat; 
		left: 8px;
		top: 1px;
		z-index: 9;
	}
	select {
		border: none;
		background-color: $ngrey7;
		padding: 6px 30px 6px 10px;
		-webkit-appearance: none;
		background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="9"%3E%3Cpath d="M9 9L.008 0h17.984z" fill="%23ff5400"/%3E%3C/svg%3E');
		background-repeat: no-repeat, repeat;
		background-position: right 10px top 50%, 0 0;
		background-size: .65em auto, 100%;
	}
	textarea {
		height: 150px;
	}
	.button {
		border: none;
		background-color: $orange;
		padding: 10px 50px;
		transition: background-color 0.2s ease-out;
		color: $marine;

		&:hover {
			background-color: $link-hover-color;
		}
		&:focus {
			background-color: $link-hover-color;
		}
	}
	.msg {
		@include fs(14,19,400);
		color: $orange;
	}
	.validation-error {
		margin-bottom: 20px;
	}
	.error {
		@include fs(14,19,400);
		color: $orange;
		display: block;
	}
	.radio {
		em {
			display: block;
		}
	}
	.form-info {
		margin-top: 60px;
	}
}


#form-1 {

	label {
		color: #fff;
	}
	.box {
		border-radius: 5px;
		padding: 20px;
		@include fs(20,20,400);
		font-family: $sans2;
	}
	.button {
		color: #fff;
		@include fs(15,31,400,2);
		font-family: $sans2;
		text-transform: uppercase;
	}
	.choice label:after {
		border-color: #fff;
	}
	.validation_error {
		@include fs(14,18,400);
		color: $orange;
	}
}
#form-1-msg {

	.error {
		@include fs(16,19,400);
		color: $orange;
	}
}
#form-1-msg-success {

	.success-msg {
		color: #fff;
	}
}
#form-3 {
	.left {
		width: 100%;
	}
	.box {
		width: 100%;
		color: $marine;
	}
	.radio li {
		margin-right: 0;
	}
	.radio .error {

	}
}

