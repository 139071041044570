/* ==============================================
   GENERAL FORMATTING
============================================== */



/* HTML, BODY, LINKS, SELECTION
================================================== */

// Momentum Scrolling on iOS Overflow Elements
html,
body {
	-webkit-overflow-scrolling: touch;
}
html {
	font-size: 100%; /* 16px */
	line-height: $mb; /*26*/
}
body {
	overflow-y: scroll;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	font-family: $sans;
	font-weight: 400;
	color: $text-color;
}
a {
	background: transparent;
	transition: color 0.2s ease-out;
	outline: 0;
	text-decoration: none;
}
a, a:visited {
	color: $link-color;
}
a:hover, a:focus {
	color: $link-hover-color;
}
::selection {
	background: $orange;
	color: #fff;
	text-shadow: none;
}


/* Grouping content
================================================== */
hr {
	box-sizing: content-box;
	display: block;
	height: 1px;
	border: none;
	border-top: 1px solid $lines;
	margin: 0;
	text-align: left;
}
pre {
	overflow: auto;
}
code,
kbd,
pre,
samp {
	font-family: monospace;
	font-size: 1em;
}



/* Text-level semantics
================================================== */

b, strong {
	font-weight: 400;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: "";
	content: none;
}
blockquote {
	font-style: normal;
	text-align: center;
}
em {
	font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: inherit;
	font-size: inherit;
}
ol {
	list-style: none outside;
}
small {
	font-size: 100%;
}
sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sub {
	bottom: -0.25em;
}
sup {
	top: -0.5em;
}
ul {
	list-style: none outside;
}
svg {
	display: block;
	width: 100%;
}




.mb1 { margin-bottom: $mb; }
.ttu { text-transform: uppercase; }
.bold { font-weight: 700; }
.ital { font-style: italic; }
.uc { text-transform: uppercase; }
.wh { color: #fff !important; }
.mb0 { margin-bottom: 0!important; }
.nw { white-space: nowrap; }
.blue { color: $marine; }
.bg-grey { background-color: $ngrey7 }




.hhh1 {
	@include hhh1;
}
.hhh2 {
	@include hhh2;
}
.hhh3 {
	@include hhh3;
}
.hhh4 {
	@include hhh4;
}
.hhh5 {
	@include hhh5;
}



.p1 {
	@include p1;
}




/* ==============================================
   GLOBAL STYLES
============================================== */


.container-fluid {
	max-width: $container-lg;
	padding-left: 30px;
	padding-right: 30px;

	@include mq(991) {
		padding-left: 15px;
		padding-right: 15px;
	}
}
.popover .arrow {
	@include mq(767){
   	display: none;
   }
}
.site-w {
	max-width: $container-lg;
	padding-left: 30px;
	padding-right: 30px;
	margin: 0 auto;

	@include mq(991) {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.show-more-btn {
	text-align: center;
	margin-bottom: 20px;

	button {
		border: none;
		position: relative;
		background-color: $lines;
		color: $text-color;
		transition: color 0.2s ease-out;
		font-family: $sans2;
		padding: 5px 0;
		display: block;
		width: 100%;

		&:hover {
			background-color: $lines;
			color: $link-hover-color;
		}
		&:focus {
			background-color: $lines;
			color: $text-color;
		}
		span {
			padding: 0 45px;
			display: inline-block;
			position: relative;

			&:after {
				font-family: $sans2;
				content: '→';
				transform: rotate(90deg);
				transition: transform 0.3s;
				transform-origin: 50% 60% 0;
				position: absolute;
				top: -2px;
				right: 22px;
				font-size: 18px;
			}
		}
		&.open span {
			&:after {
				transform: rotate(270deg);
			}
		}
	}
}


.fader {
	position: relative;
	opacity: 0;
	animation: fade-down 1s cubic-bezier(.54,.17,.41,.86) forwards;
}
.fade-in {
	animation: fade-up 1s cubic-bezier(.54,.17,.41,.86) forwards;
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fade-up {
	0% {
		opacity: 0;
		transform: translateY(-80px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes fade-down {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(-80px);
	}
}


.pad1,
.container-fluid.pad1 {
	padding-top: 80px;
	padding-bottom: 80px;
}


.vcenter {
	display: table;
	margin: 0 auto;
	height: 100%;
}
.vcenter:before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.vcenter .content {
	display: table-cell;
	vertical-align: middle;
}


// .post-content {

// 	h1 {
// 		font-family: $sans2;
// 		@include fs(42,46);
// 		margin-bottom: 40px;
// 		color: $marine;
// 	}
// 	h2 {
// 		font-family: $sans2;
// 		@include fs(24,34,400,0.1);
// 		margin-top: 60px;
// 		margin-bottom: $mb;
// 		color: $marine;
// 	}
// 	h3 {
// 		font-family: $sans2;
// 		@include fs(20,30,400,0.1);
// 		margin-top: 60px;
// 		margin-bottom: $mb;
// 		color: $sea-dk;
// 	}
// 	h4 {
// 		font-family: $sans2;
// 		@include fs(17,27,400,0.1);
// 		margin-top: 60px;
// 		margin-bottom: $mb;
// 		color: $ngrey1;
// 	}
// 	h5 {
// 		font-family: $sans2;
// 		@include fs(12,27,700,2);
// 		text-transform: uppercase;
// 		margin-top: 60px;
// 		margin-bottom: $mb;
// 		color: $marine;
// 	}

// 	p {
// 		@include fs(17,27,400,0.1);
// 		margin-bottom: $mb;
// 		color: $granite;
// 	}
// 	strong {
// 		font-weight: 700;
// 		color: $marine;
// 	}
// 	em {
// 		font-style: italic;
// 	}
// 	small {
// 		@include fs(13,18,400,0.2);
// 		color: $granite;
// 	}
// 	ol, ul {
// 		margin-bottom: $mb;
// 	}
// 	ol {
// 		list-style-type: decimal;
// 	}
// 	ol li {
// 		margin-left: 1.8em;
// 	}
// 	ul li {
// 		margin-left: 1.8em;
// 		margin-bottom: 18px;
// 	}
// 	ul li:before {
// 		content: "-";
// 		display: block;
// 		font-size: 3em;
// 		font-weight: bold;
// 		left: -0.6em;
// 		max-height: 0;
// 		min-height: 0;
// 		position: relative;
// 		top: -2px;
// 		color: $orange;
// 	}
// 	blockquote {
// 		text-align: left;
// 		border-left: 5px solid $orange;
// 		padding: 0 25% 0 50px;
// 		margin: 70px 0;

// 		@include mq(479) {
// 			padding: 0 0 0 25px;
// 		}

// 		p {
// 			color: $orange;
// 			@include fs(28,36,400);
// 		}
// 	}
// 	video {
// 		width: 100%;
// 		height: auto;
// 		object-fit: inherit; // make poster fill width
// 	}
// 	.iframe {
// 		margin-bottom: 2rem;
// 	}
// 	.preview-w {
// 		max-width: 80%;

// 		@include mq(479) {
// 			max-width: none;
// 		}
// 	}
// 	dfn {
// 		font-style: normal;
// 		color: $sea;
// 		font-weight: 700;
// 		cursor: pointer;
// 	}
// }

header.topper {
	background-color: $marine;
	height: 440px;
	position: relative;

	@include mq(767) {
		height: 320px;
	}

	&.tall {

		@include mq(h700) {
			height: 400px;
		}
	}
	&.bg1 {
		background: $marine url("/images/bg1.png") no-repeat center center;
		background-size: cover;
	}
	&.bg2 {
		background: $marine url("/images/bg2.png") no-repeat center center;
		background-size: cover;
	}
	&.bg3 {
		background: $marine url("/images/bg3.png") no-repeat center center;
		background-size: cover;
	}
	&.bg4 {
		// background: $marine url("/images/bg4b.png") no-repeat center center;
		background: $marine url("/images/bg4.png") no-repeat center center;
		background-size: cover;
	}
	&.bg-map {
		background-image: url("/images/map.svg") !important;
		background-color: $marine;
		background-position: top;
		background-repeat: no-repeat;
		background-size: contain !important;
		background-position: center;
		@include mq(767) {
			background-size: 900/750*100vw !important;
			padding-bottom: 80/750*100vw;
		}
		&.explore-hero {
			background-size: 1109px !important;
			background-position: center top;
			@include mq(767) {
				background-size: 900/750*100vw !important;
				background-position: center;
			}
		}
		&.en-top {
			background-size: 800px !important;
			@include mq(767) {
				background-size: 900/750*100vw !important;
				padding-bottom: 80/750*100vw;
			}
		}
	}
	&.small-top {
		height: 200px;

		@include mq(767) {
			height: 115px;
		}
	}
	.height {
		height: calc(100% - 138px);
		display: flex;
		align-items: center;

		@include mq(879) {
			height: calc(100% - 90px);
		}
	}
	hr {
		border-top: 7px solid $orange;
		width: 30px;
		margin: 0 0 15px 5px;

		@include mq(991) {
			margin-left: 3px;
		}
	}
	h1 {
		font-family: $sans2;
		@include fs(72,75,400,0.2);
		color: #fff;
		padding-bottom: 66px;

		@include mq(991) {
			@include fs(45,48,400,0.2);
		}
		@include mq(767) {
			padding-bottom: 50px;
		}
		@include mq(413) {
			font-size: 40px;
		}

		&.adjust {
			padding-bottom: 0px;
			margin-bottom: -9px;

			@include mq(991) {
				margin: 0;
				padding-bottom: 18px;
			}
			@include mq(767) {
				padding-bottom: 2px;
			}
			@include mq(413) {
				padding-bottom: 8px;
			}
		}
	}
	.has-p {
		color: #fff;

		hr {

			@include mq(599) {
				margin-top: 20px;
			}
		}
		h1 {

			@include mq(599) {
				padding-bottom: 20px;
			}
		}
		p {
			@include fs(19,36,400);
			@include font-brown;

			@include mq(599) {
				font-size: 14px;
			}
		}
	}
	sup {
		font-size: 0.3em;
		position: relative;
		top: -1.6em;
	}
}
.last-update {
	font-family: $sans2;
	@include fs(26,29,400,1);
	text-transform: uppercase;
	margin-bottom: $mb;
	color: $marine;
}
.offset-left {
	padding-left: 8.33333%;

	@include mq(767) {
		padding-left: 0;
	}
}

.modal-dialog button.close {
	float: right;
	font-size: 50px;
	font-weight: 400;
	line-height: 1;
	color: #fff;

	// Additional properties for button version
	// iOS requires the button element instead of an anchor tag.
	// If you want the anchor version, it requires `href="#"`.
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;

	&:hover,
	&:focus {
		cursor: pointer;
		color: #fff;
	}
}

.the-dl {
	background-color: #fff;
	text-align: center;

	a {
		@include fs(24,29,400,0.2);

		@include mq(767) {
			font-size: 18px;
		}
		@include mq(479) {
			font-size: 14px;
		}
	}
	i {
		position: relative;
		top: 2px;
		margin-right: 6px;
	}
	span {
		color: $ngrey1;
		@include fs(16,29,400,0.2);
	}
}
.side-blue-cta {
	background-color: $marine;
	color: #fff;
	padding: 30px;
	margin-bottom: 30px;

	h6 {
		font-family: $sans2;
		@include fs(24,30,400);
		margin-bottom: 4px;

		&.pad {
			margin-bottom: 20px;
		}

		@include mq(479) {
			@include fs(20,28);
		}
	}
	p {
		margin-bottom: 20px;
		font-family: $sans2;
		@include fs(14,21,400);

		&.grey {
			color: $ngrey4;
		}
	}

	a.btn {
		background-color: $orange;
		display: block;
		padding: 10px 0;
		transition: background-color 0.2s ease-out;
		color: #fff;
		@include fs(15,31,400,2);
		font-family: $sans2;
		text-transform: uppercase;
		margin-bottom: 30px;
		text-align: center;

		&:hover {
			background-color: $link-hover-color;
		}
		&:focus {
			background-color: $link-hover-color;
		}
	}
}
.hide {
	display: none;
}

.border-banner-link {
	border: 2px solid #8eb1b2;
	border-radius: 5px;
	padding: 0 1rem 0 0.5rem;
	.flex {
		display: flex;
		flex-direction: row;
		align-items: center;
		.item {
			&.img {
				width: 80px;
				margin-left: 0.5rem;
				margin-right: 0.5rem;
			}
			&.text {
				flex-shrink: 0;
			}
		}
	}
	.text {
		color: #fff;
		.em {
			color: $orange;
		}
	}
	&:hover {
		border: 2px solid $orange;
		.text {
			color: $orange !important;
		}
	}
	&.half {
		.flex {
			padding: 0.2rem 0;
			.item {
				&.img {
					width: 70px;
					margin-left: 0;
					margin-right: 0;
				}
				&.text {
					width: 180px;
					span {
						font-size: 14px;
					}
				}
			}
		}
		@include mq(767) {
			.flex {
				.item {
					&.img {
						margin-right: 0.5rem;
						width: 55px;
					}
					&.text {
						width: auto;
						span {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
}
.news-tab {
    margin-bottom: 60px;

    .nav-tabs {
        border-bottom: 0;

        .nav-item {
            .nav-link {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
				color: $marine;
				&.active {
					color: #fff;;
				}
            }
        }
    }

    .tab-content {
        border-top: 1px solid $lines;

        .item {
            border-bottom: 1px solid $lines;
            padding: 20px 0;
            display: flex;
			h3 {
				a {
					color: $marine;
				}
			}
			.text {
				p {
					color: $granite;
					font-size: 0.85rem;
					line-height: 1.4em;
					font-weight: 400;
					letter-spacing: 0;
				}
			}
        }
    }

	&.press-entries {
		li {
			margin-bottom: 0 !important;
			padding-bottom: 0 !important;
			border-bottom: 0 !important;
		}
	}

    @include mq(767) {
        .nav-tabs {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0.5rem;
            margin-bottom: 1rem;

            .nav-item {
                flex-basis: auto;
                flex-grow: 0;
                width: 48%;
            }

            .nav-link {
                border: 1px solid #ddd;
                border-radius: .25rem;
                text-align: center;

                // &.active {
                //     background-color: $orange;
                //     color: white;
                // }
            }
        }
    }
}
#press {
	.news-tab {
			.tab-content {
				.item {
					flex-wrap: wrap;
					flex-direction: column;
					.date {
						flex: 0;
					}
				}
			}
	}
	&[data-lang="en"] {
		.news-tab {
			.tab-content {
				border-top: 0 !important;
				.item {
					&:first-child {
						padding-top: 0;
					}
				}
			}
		}
	}
}