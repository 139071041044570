.p-conOrgMap{
  padding:24px 0 100px;
    p+p{
      margin-top: 15px;
    }
    p.textRight{
    text-align: right;
    }
  &__img{
    padding-top: 40px;
  }
}
