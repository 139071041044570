.p-resVideoModal {
  max-width: 869px;
  margin-top: 30px;
  &__content {
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    border: none;
    padding: 61px 40px 40px;
    position: relative;
  }
  &__button {
    position: absolute;
    right: 34px;
    top: 12px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    line-height: 20px;
    display: block;
    margin-bottom: 4px;
    padding: 10px;
    margin-right: -10px;
    width: 50px;
    color: #ff5400;
  }
  .icon-close {
    color: #ff5400;
  }
  &__body {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    iframe {
      margin-bottom: 2rem;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
  &__title {
    font-family: Brown-Regular, sans-serif;
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.07692em;
    font-weight: 400;
    letter-spacing: 0.05769em;
    color: #002f37;
    margin-bottom: 20px;
  }
  &__description {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1.58824em;
    font-weight: 400;
    letter-spacing: 0.00588em;
    margin-top: 2rem;
    margin-bottom: 1.625rem;
    color: #606060;
  }
}
