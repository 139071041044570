
/* Landing
================================================== */

.blog {
	padding: 80px 0;

	@include mq(767) {
		padding: 40px 0;
	}
	@include mq(599) {
		background-color: $ngrey7;
	}

	.entries {

		article:last-of-type {
			border-bottom: 4px solid $lines; 

			@include mq(599) {
				border: none;
			}
		}	
	}
	.post {

		.th {
			display: block;
			max-width: 220px;
			margin: 3px 0 20px;
			float: left;
			background-color: $marine;

			@include mq(991) {
				max-width: 140px;
			}
			@include mq(599) {
				float: none;
				max-width: 220px;
				background-color: transparent;
				margin: 0 auto 10px;
			}
		}
		img {
			display: block;
		}
		.textwrap {
			margin-left: 240px;
      word-wrap: break-word;
			@include mq(991) {
				margin-left: 160px;
			}
			@include mq(599) {
				margin-left: 0px;
			}
		}
		.dt {
			text-transform: capitalize;
		}
		h2 {
			font-family: $sans2;
			@include fs(26,35);

			a {
				color: $marine;
			}
		}
		.ex {
			@include fs(14,23,400);
			padding: 10px 0;
			margin-top: 20px;

			span {
				font-style: italic;
			}
		}
	}
	.card {
		background-color: #fff;
		border-top: 4px solid $lines;
		padding: 30px 30px 20px 0;

		@include mq(599) {
			padding: 20px 30px;
			margin-bottom: 50px;
			border: 1px solid $lines; 
			border-top: 2px solid $orange;
		}
	}
	.cats {
		@include fs(13,27,400,0.5);
		text-transform: uppercase;
	}
	.sticky {

		.card {
			padding: 0;
			border: none;
		}
		.th {
			max-width: none;
			width: 100%;
			margin: 0;
			float: none;
		}
		.textwrap {
			margin: 0;
			padding: 30px 0 20px;

			@include mq(599) {
				padding: 24px 30px 20px;
			}
		}
	}
	.sidebar {

		h3 {
			font-family: $sans2;
			@include fs(22,27,400,0.5);
			text-transform: uppercase;
			border-bottom: 4px solid $lines;
			padding-bottom: 12px;
			margin-bottom: 20px;
			color: $marine;

			@include mq(599) {
				border: none;
				border-top: 4px solid $lines;
				padding: 25px 0 0;
			}
		}
		ul {
			font-family: $sans-c;
			@include fs(15,18,400,0.2);

			li {
				margin: 10px 0;
			}
		}
		a {
			color: $text-color;

			&:hover {
				color: $link-color;
			}
		}
		span {
			font-family: $sans;
			@include fs(13,18,400,0.5);
			margin-left: 4px;
			color: $link-color;
		}
		.bold a {
			color: $link-color;

			&:hover {
				color: $link-hover-color;
			}
		}
		@include mq(599) {

			.ct {
				margin: 0 -5px;

				li {
					margin: 5px;
					display: inline-block;
				}
				a {
					border: 1px solid $lines;
					padding: 4px 8px;
					display: block;
				}
			}
		}
	}
	.bloc {
		background-color: $ngrey7;
		padding: 18px 14px 10px;
		margin-bottom: 30px;

		@include mq(599) {
			padding: 0;
		}
	}
}
.posts-nav {
	text-align: center;
	margin: 60px 0 3em;
	font-family: $sans2;

	@include mq(599) {

	}

	a {
		text-decoration: none;
		padding: 3px 8px 6px;
		margin: 6px;
		border: none;

		&.current {
			color: $ngrey2;
			border-bottom: 2px solid $ngrey2; 
		}
	}
	.pg {

		@include mq(599) {
			display: none;
		}
	}
	.previouspostslink,
	.nextpostslink {
		border: 1px solid $orange;
		transition: all 0.2s ease-out;

		span {
			display: none;

			@include mq(599) {
				display: inline;
			}
		}

		&:hover {
			border-color: $link-hover-color;
		}
	}
}
.dropdown-posts-nav {
	display: none;
	padding: 0 0 50px 0;
	text-align: center;

	@include mq(599) {
		display: block;
	}
}
.blogbc {
	@include fs(14,30,400);
	padding: 14px 0;
	text-transform: uppercase;

	span {
		display: inline-block;
		margin: 0 4px;
	}
}





/* Single
================================================== */

#blog {


	.meta {
		@include fs(13,18,400,0.2);
		color: $granite;

		p {
			margin-bottom: 10px;
		}
		.author {
			font-family: $sans2;
			@include fs(20,22,400,0.2);
			color: $marine;
			display: block;
		}
		.inner {
			border-top: 1px solid $lines;
			border-bottom: 1px solid $lines; 
			padding: 15px 0;
			margin-bottom: 30px;
		}
	}
	.related {

		h4 {
			font-family: $sans2;
			@include fs(20,22,400,0.2);
			color: $marine;
			margin-bottom: $mb;
		}
		ul {
			@include mq(767) {
				margin: 0 -10px;
			}
		}
		li {
			margin-bottom: 20px;
			text-align: center;

			@include mq(767) {
				float: left;
				width: 25%;
			}
			@include mq(639) {
				width: 50%;

				&:nth-of-type(3) {
					clear: left;
				}
			}
		}
		a {
			display: block;
			border: 1px solid $lines; 
			padding: 5px 15px 15px;
			transition: background-color 0.4s ease-out;
			color: $orange;
			height: 100%;

			&:hover {
				background-color: $ngrey7;
			}

			@include mq(767) {
				margin: 0 10px;
			}
		}
		h5 {
			font-family: $sans2;
			@include fs(15,21,400,0.2);

			@include mq(479) {
				@include fs(13,18,400,0.2);
			}
		}
	}
	.back-to-all {
		border-top: 1px solid $lines;
		font-family: $sans-c;
		@include fs(17,19,700,0.2);
		padding-top: 20px;

		@include mq(767) {
			margin-top: 40px;
		}

		a {
			color: $granite;

			&:hover {
				color: $orange;
			}
		}
		span {
			color: $orange;
			margin-right: 5px;
			position: relative;
			top: 1px;
		}
	}
	.single {
		padding-bottom: 80px;
	}
	.footnotes {
		background-color: $ngrey7;
		padding: 80px 0;

		h4 {
			font-family: $sans2;
			@include fs(17,20,400,1);
			text-transform: uppercase;
			margin-bottom: $mb;
		}
		p {
			@include fs(13, 20, 0.1);
			margin-bottom: $mb;
			color: $granite;

			&:last-of-type {
				margin: 0;
			}
		}
	}
}




/* Contributors
================================================== */

.pcc {

	&.pad {
		margin: 60px 0;
	}
	.pct {
		@include mq(991) {
			margin-top: 60px;
		}
	}
	h2 {
		font-family: $sans2;
		@include fs(22,27,400,0.5);
		text-transform: uppercase;
		border-bottom: 4px solid $lines;
		padding-bottom: 12px;
		margin-bottom: $mb;
		color: $marine;
	}
	.person {
		padding-bottom: $mb;
		border: 1px solid $lines; 
		margin-bottom: $mb;
		text-align: justify;
		padding: 30px;
		background-color: $ngrey7;

		@include mq(599) {
			background-color: transparent;
			padding: 0 0 30px;
			border: none;
			border-bottom: 1px solid $lines; 
			text-align: left;
		}
	}
	.pad-r {
		margin-right: 50px;

		@include mq(991) {
			margin-right: 0;
		}
	}
	.pad-l {
		margin-left: 50px;

		@include mq(991) {
			margin-left: 0;
		}
	}

	img {
		max-width: 122px;
		float: left;
		margin: 0 22px 0 0;

		@include mq(991) {
			max-width: 140px;
		}
		@include mq(599) {
			float: none;
			max-width: none;
			margin: 0 0 10px;
		}
	}
	.text {
	}
	h3 {
		font-family: $sans2;
		@include fs(24,30,400,0.5);
		margin-bottom: 2px;
		color: $marine;

		@include mq(479) {
			@include fs(20,28);
		}
	}
	h4 {
		font-family: $sans2;
		@include fs(14,21,400,0.3);
		color: $marine;
		margin-bottom: $mb;
	}
	.bio {
		margin-bottom: $mb;
	}
	strong {
		font-weight: 700;
		color: $marine;
	}
	em {
		font-style: italic;
	}
	small {
		@include fs(13,18,400,0.2);
		color: $granite;
	}
	ol, ul {
		margin-bottom: $mb;
	}
	ol {
		list-style-type: decimal;
	}
	ol li {
		margin-left: 1.8em;
	}
	ul li {
		margin-left: 1.8em;
		margin-bottom: 18px;
	}
	ul li:before {
		content: "-";
		display: block;
		font-size: 3em;
		font-weight: bold;
		left: -0.6em;
		max-height: 0;
		min-height: 0;
		position: relative;
		top: -2px;
		color: $orange;
	}
}


// move this to global
// .bread {
// 	background-color: $earth;
// 	padding: 10px 0;

// 	a {
// 		color: #fff;
// 		font-family: $sans2;
// 		@include fs(14,24,400,1);
// 		text-transform: uppercase;
// 		opacity: 0.75;

// 		@include mq(413) {
// 			font-size: 13px;
// 		}
// 		@include mq(374) {
// 			font-size: 11px;
// 		}

// 		&:hover {
// 			opacity: 1;
// 		}
// 	}
// }

