.p-conComp{
  padding: 0 0 20px;
  &__block{
    padding: 24px 0 80px;
    p+p{
      margin-top: 15px;
    }

  }
  &__list{
    padding: 24px 0;
    &--noMag{
      padding:4px 0 0;
    }
  
    li{
    // margin-top: 24px;
    padding-left: 1em;
    position: relative;

    &::before {
      content:"";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #606060;
      position: absolute;
      top:  6px;
      left: 0;
      border-radius: 5px;
    }
  }
  }
  &__table{
  padding: 40px 0;
  
  table {
    width: 100%;
    margin-top: 4px;
  }
  th {
    text-align: left;
    padding: 10px 0;
    @include fs(15);
    width: 38%;
    vertical-align: top;
    font-weight: normal;
  }
  td {
    padding: 10px 0;
    @include fs(15);
    
  }
  tr{
	 border-bottom: 1px solid $lines;
  
  }
  }

}
