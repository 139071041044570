.p-topTitleB{
  text-align: center;
  @include fs(34);
  font-family: Brown-Regular,sans-serif;
  color: #002f37;
  @include sp() {
    @include vw(64);
    
  }
}
