@charset "utf-8";
.u{
    &-mt00{
        margin-top: 0;
    }
    &-pci{
        display:inline-block;
        @include sp{
            display:none;
        }
    }
    &-spi{
        display:none;
        @include sp{
            display:inline-block;
        }
    }
    &-pc{
        display:block;
        @include sp{
            display:none;
        }
    }
    &-sp{
        display:none;
        @include sp{
            display:block;
        }
    }
    &-att{
        // color: #00f;
        color: #000;
    }
    &-load{
        // color: #f0c;
        // display: none;
        // .is-loadingfinally &{
            // display: inline;
        // }
    }
    &-textNoWrap{
        white-space: nowrap;

    }
    &-textRight{
        text-align: right;
    }

}

.icon_long-arrow-right{
    height: 1em;
    width: 100%;
    display: inline-block;
    position: relative;
    &::before {
        content:"";
        display: inline-block;
        width: .5em;
        height: .5em;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        position: absolute;
        top:50%;
        right: 0;
        transform: translate(0, -50%) rotate(45deg);
    }
    &::after {
        content:"";
        display: inline-block;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #000;
        position: absolute;
        top: 50%;
        right: 0;

    }
}
.icon_long-arrow-left{
    height: 1em;
    width: 100%;
    display: inline-block;
    position: relative;
    &::before {
        content:"";
        display: inline-block;
        width: .5em;
        height: .5em;
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
        position: absolute;
        top:50%;
        left: 0;
        transform: translate(0, -50%) rotate(45deg);
    }
    &::after {
        content:"";
        display: inline-block;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #000;
        position: absolute;
        top: 50%;
        right: 0;

    }
}

.css-br {
    &::after {
        content: "\A" ;
        white-space: pre;
    }
}
.css-br-sp {
    &::after {
        @include sp{
            content: "\A" ;
            white-space: pre;
        }
    }
}

.mt-6 {
    margin-top: 4rem;
}

.reduce-letter-spacing {
    letter-spacing: -1px !important;
}

[data-toggle="popover"] {
    cursor: pointer;
}