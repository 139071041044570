
.p-fundsListTableWrap {
  @include sp() {
    overflow: auto;
    width: 100%;
    // border: 1px solid #000;
  }

  // @include mq(1239) {
  //   overflow-x: scroll;
  // }
}
.p-fundsListTable {
  width: 100%;
  line-height: 1;
  @include sp() {
    width: 1200px;
  }
  @include mq(767) {
    font-size: 11px;
    line-height: 15px;
  }
  thead {
    border-bottom: 2px solid #e5e6e5;
    th {
      text-align: left;
      color: $orange;
      padding: 10px;
      // @include fs(16);
      // @include sp() {
      //   @include vw(22);
      // }
      vertical-align: middle;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e5e6e5;

    }
    th {
      text-align: left;
      padding: 12px 10px 8px;
      vertical-align: middle;

      @include mq(767) {
        font-size: 11px;
        line-height: 15px;
      }
    }
    td {
      vertical-align: middle;

      padding: 10px;
      @include mq(767) {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }

  &__name {
    color: #002f37;
    // @include fs(16);
    // @include sp() {
    //   @include vw(22);
    // }
    a {
      color: #002f37;
      // &:hover{
      //   text-decoration: underline;
      // }
    }
  }
  &__nav {
    text-align: right;
    @at-root th#{&} {
      text-align: center !important;
    }
    span {
      display: inline-block;
      text-align: left;
    }
  }
  &__navps {
    text-align: right;
    // @include fs(12);
    font-weight: bold;
    // @include sp() {
    //   @include vw(20);
    // }
    @at-root th#{&} {
      text-align: center !important;
    }
  }
  &__change {
    text-align: right;
    // @include fs(12);
    font-weight: bold;
    // @include sp() {
    //   @include vw(20);
    // }
    @at-root th#{&} {
      text-align: center !important;
    }
    span {
      display: inline-block;
      text-align: left;
    }
  }
  &__pdf {
    text-align: center !important;
    // @include fs(12);
    font-weight: bold;
    // @include sp() {
    //   @include vw(20);
    // }
  }
  &__date {
    // @include fs(12);
    font-weight: bold;
    // @include sp() {
    //   @include vw(20);
    // }
  }
  &__nav_disp {
    text-align: center !important;
    span {
      display: inline-block;
      text-align: left;
    }
  }
  &__trading {
    text-align: center !important;
    span {
      display: inline-block;
      text-align: left;
    }
  }
}
