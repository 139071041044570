
.home-more {
	text-align: center;

	a {
		@include fs(16,28,700);
		display: inline-block;
		position: relative;
		color: $marine;

		// @include mq(991) {
		// 	color: $orange;
		// }

		&:hover {

			&:after {
				left: 15px;
			}
		}
		&:after {
			@include font-brown;
			content: '→';
			transition: left 0.3s;
			position: relative;
			left: 0;
			margin-left: 6px;
			color: $orange;
		}
	}
	svg {
		fill: $orange;
	}
}

.home-see {
	text-align: center;

	a {
		@include fs(16,28,700);
		display: inline-block;
		position: relative;
		color: $marine;

		// @include mq(991) {
		// 	color: $orange;
		// }
		&:after {
			@include font-brown;
			content: '↓';
			position: relative;
			left: 0;
			margin-left: 6px;
			color: $orange;
		}
	}
	svg {
		fill: $orange;
	}
}

.home-less {
	text-align: center;

	a {
		@include fs(16,28,700);
		display: inline-block;
		position: relative;
		color: $marine;

		// @include mq(991) {
		// 	color: $orange;
		// }
		&:after {
			@include font-brown;
			content: '↑';
			position: relative;
			left: 0;
			margin-left: 6px;
			color: $orange;
		}
	}
	svg {
		fill: $orange;
	}
}



.home-1 {
	// background: $ngrey7;
	position: relative;

	&:after {
		content: '';
		background: $ngrey7;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: -2;
	}

	@include mq(599) {
		padding-bottom: 50px;
		overflow: hidden;
	}

	.home-bg {
		background: $marine url('/images/map.svg') center top no-repeat;
		background-size: 1109px;
		position:absolute;
		width:100%;
		height:1200px;
		z-index:-1;
		@include mq(767) {
			background-size: 700px;
			background-position: center 110px;
		}
		@include mq(599) {
			background-size: 500px;
			padding-bottom: 50px;
			overflow: hidden;
		}
	}

	.text {
		display: flex;
		flex-direction: column;
	}
	.header {
		margin: 100px auto 60px;
		padding: 0 15px;

		@include mq(767) {
			margin: 60px auto 50px;
			text-align: center;
		}
	}
	h1 {
		@include fs(72,88,400,0.2);
		@include font-brown;
		color: #fff;
		position: relative;

		@include mq(991) {
			font-size: 66px;
		}
		@include mq(767) {
			font-size: 45px;
		}
		@include mq(ie) {
			max-width: 760px;
			margin: 0 auto;
		}

		&:before {
			content: '';
			position: absolute;
			top: -16px;
			left: 6px;
			width: 30px;
			height: 7px;
			background-color: $orange;

			@include mq(991) {
				left: 5px;
			}
			@include mq(991) {
				left: 5px;
			}
			@include mq(767) {
				top: auto;
				bottom: -28px;
				left: 50%;
				margin-left: -15px;
			}
		}
	}
	sup {
		font-size: 0.3em;
		position: relative;
		top: -1.6em;
	}
	.intro p {
		text-align: center;
		color: #fff;
		max-width: 570px;
		@include fs(18,30,400);
		margin: 0 auto 90px;
		padding: 0 15px;

		@include mq(767) {
			margin: 0 auto 30px;
		}
	}


}
.home-2 {
	background-color: $ngrey7;
	padding: 80px 0;

	h2 {
		// @include font-brownbold;
		// @include thinfont;
		text-align: center;
		color: $marine;
		// @include fs(28,30,400);
		@include fs(28,30,700);
		margin-bottom: 60px;
		text-align: center;
	}
	.excerpt {
		margin-bottom: 60px;
	}
	.card {
		background-color: #fff;
		border: 1px solid $ngrey5;
		border-top: 2px solid $link-color;
		padding: 30px 30px 15px;
		height: 100%;
		display: flex;
		flex-direction: column;
		box-shadow: 0 6px 9px 0 rgba(41,52,56,0.17);

		@include mq(575) {
			height: auto;
		}

	}
	.textwrap {
		display: -webkit-box;
		flex-grow: 1;
    	flex-direction: column;
	}
	h3 {
		font-family: $sans2;
		@include fs(18,24,400);
		margin-bottom: 16px;

		a {
			color: $marine;
		}
	}
	.ex {
		@include fs(14,20,400);
		margin-top: auto;
		// padding-bottom: 20px;
		margin-bottom: 20px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;

		span {
			font-style: italic;
		}
	}
	.rm {
		transition: background-color 0.2s ease-out;
		@include fs(16,28,700);
		position: relative;
		align-self: bottom;
		@include after-arrow($orange);
		margin-top: auto;
	}
}
.home-3 {
	padding: 80px 0;

	@include mq(991) {
	}
	@include mq(767) {
	}

	h2 {
		// @include font-brownbold;
		// @include thinfont;
		text-align: center;
		color: $marine;
		// @include fs(28,30,400);
		@include fs(28,30,700);
		margin-bottom: 60px;
		text-align: center;
	}
	.news {
		border-top: 1px solid $lines;
		margin-bottom: 60px;
	}
	.item {
		border-bottom: 1px solid $lines;
		padding: 20px 0;
		display: flex;
	}
	.date {
		flex: 0 0 200px;
		max-width: 200px;

		@include mq(479) {
			flex: 0 0 140px;
			max-width: 140px;
		}

		p {
			@include fs(16,24,400);
		}
	}
	h3 {
		@include fs(18,24,400);
		margin-bottom: 5px;

		a {
			color: $marine;
		}
	}
	p {
		@include fs(15,24,400);
	}
	span {
		display: inline-block;
		position: relative;
		top: 3px;
		margin-left: 10px;
	}
	svg {
		display: inline-block;
		width: 16px;
		fill: $orange;
		transition: fill 0.2s ease-out;
	}
	a:hover svg {
		fill: $link-hover-color;
	}
}
.home-4 {
	background-color: $ngrey7;
	padding: 80px 0;

	h2 {
		// @include font-brownbold;
		// @include thinfont;
		text-align: center;
		color: $marine;
		// @include fs(28,30,400);
		@include fs(28,30,700);
		margin-bottom: 60px;
		text-align: center;
	}
	.excerpt {
		margin-bottom: 60px;
	}
	.card {
		background-color: #fff;
		border: 1px solid $ngrey5;
		border-top: 2px solid $link-color;
		padding: 30px 30px 15px;
		height: 100%;
		display: flex;
		flex-direction: column;
		box-shadow: 0 6px 9px 0 rgba(41,52,56,0.17);

		@include mq(575) {
			height: auto;
		}

	}
	.video-img {
		position: relative;
		display: block;
		overflow: hidden;
		padding-bottom: 60%;
		margin-bottom: 15px;

		&:hover {

			.play-button {
				transform: translate(-50%,-50%) scale(1.1);
			}
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}
	.play-button {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 70px;
		height: 70px;
		transform: translate(-50%,-50%) scale(1);
		background-color: #fff;
		border-radius: 50%;
		opacity: 0.8;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s ease-in-out;

		svg {
			width: 24px;
			height: 24px;
			fill: $marine;
			margin-left: 5px;
		}
	}
	.textwrap {
		flex-grow: 1;
	}
	h3 {
		font-family: $sans2;
		@include fs(18,24,400);
		margin-bottom: 16px;

		a {
			color: $marine;
		}
	}
	.ex {
		@include fs(14,20,400);
		padding-bottom: 20px;

		span {
			font-style: italic;
		}
	}
}


// Home Featured Insights Video Modal
#modal-video {
	padding-right: 0px !important;

	.modal-dialog {
		max-width: 869px;
		margin-top: 30px;

		@include mq(767) {
			margin-top: 60px;
		}
	}
	.modal-backdrop.in {
		opacity: 0.8;
	}
	.modal-content {
		background-color: #fff;
		box-shadow: none;
		border-radius: 0;
		border: none;
		padding: 61px 40px 40px;
		position: relative;

		@include mq(479) {
			padding: 20px;
		}
	}
	.close {
		position: absolute;
		right: 34px;
		top: 12px;
		background-color: transparent;
		border: none;
		font-size: 20px;
		line-height: 20px;
		display: block;
		margin-bottom: 4px;
		padding: 10px;
		margin-right: -10px;
		width: 50px;
		color: $orange;

		@include mq(479) {
			top: 0;
			right: 2px;
		}
	}
	h1 {
		@include hhh4;
		color: $marine;
		margin-bottom: 20px;

		@include mq(479) {
			padding-right: 15px;
		}
	}
}

.banner-area-wrap {
	display: flex;
	justify-content: right;
	gap: 0.5rem;
	@include mq(767) {
		justify-content: center;
		flex-direction: column;
		gap: 0;
	}
	.banner-image-wrap {
		.image-wrap {
			&.pc {
				@include mq(767) {
					display: none;
				}
			}
			&.sp {
				@include mq(768) {
					display: none;
				}
			}
		}
	}
}