
/* Landing
================================================== */

.public {
	padding: 40px 0;

	@include mq(767) {
		padding: 40px 0;
	}
	@include mq(599) {
		// background-color: $ngrey7;
	}

	h2 {
		font-family: $sans2;
		@include fs(22,22,400,1);
		text-transform: uppercase;
		color: $marine;
		margin-bottom: 30px;
		padding-top: 24px;
		padding-bottom: 10px;
		border-bottom: 4px solid #E5E6E5;
	}
	p {
		@include fs(15,24,400,0.2);
	}

	.sidebar {

		h3 {
			font-family: $sans2;
			@include fs(22,27,400,0.5);
			text-transform: uppercase;
			border-bottom: 4px solid $lines;
			padding-bottom: 12px;
			margin-bottom: 20px;
			color: $marine;

			// @include mq(599) {
			// 	border: none;
			// 	border-top: 4px solid $lines;
			// 	padding: 25px 0 0;
			// }
		}
		ul {
			font-family: $sans-c;
			@include fs(15,18,400,0.2);

			li {
				margin: 10px 0;
			}
		}
		a {
			color: $text-color;

			&:hover {
				color: $link-color;
			}
		}
		span {
			font-family: $sans;
			@include fs(13,18,400,0.5);
			margin-left: 4px;
			color: $link-color;
		}
		.bold a {
			color: $link-color;

			&:hover {
				color: $link-hover-color;
			}
		}
		// @include mq(599) {

		// 	.ct {
		// 		margin: 0 -5px;

		// 		li {
		// 			margin: 5px;
		// 			display: inline-block;
		// 		}
		// 		a {
		// 			border: 1px solid $lines;
		// 			padding: 4px 8px;
		// 			display: block;
		// 		}
		// 	}
		// }
	}
	.bloc {
		background-color: $ngrey7;
		padding: 18px 14px 10px;
		margin-bottom: 30px;

		// @include mq(599) {
		// 	padding: 0;
		// }
	}
}




