.m-footListB {
  display: flex;
  @include sp() {
    display: block;
    padding-top: 3em;
  }

  &__item {
    a {
      color: #fff;
      @include fs(14);
      opacity: 0.75;
      transition: opacity 0.2s ease-out;
      font-family: Brown-Regular, sans-serif;
      &:hover {
        opacity: 1;
      }
    }
    & + & {
      padding:0 0 0 3em;
      @include sp() {
        padding: .27em 0 0 0; 
        
      }
    }
  }
}
