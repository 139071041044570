.m-btnA {
  display: block;
  border: 1px solid #606060;
  padding: 10px;
  text-align: center;
  position: relative;
  @include fs(16);
  @include sp() {
    @include vw(34);
  }

  &__iconToB {
    &::before {
      content: "→";
      transition: left 0.3s;
      position: absolute;
      right: 14px;
      bottom: 12px;
      font-family: $sans2;
      font-weight: 400;
      display: block;
      transition: transform 0.3s;
      font-size: 20px;
      font-style: normal;
      transform: rotate(90deg);
    }
  }
  &__iconToR {
    &::before {
      content: "→";
      transition: left 0.3s;
      position: absolute;
      right: 14px;
      bottom: 12px;
      font-family: $sans2;
      font-weight: 400;
      display: block;
      transition: transform 0.3s;
      font-size: 20px;
      font-style: normal;
    }
  }
}
