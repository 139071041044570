.p-topTabBox{

  &__box{
    display: none;
    &.is-active{
      display: block;
    
    }
  }

}
