svg{
	.a{
		fill:#ff5400;
	}
}
.fund-detail #why img {
		fill: #ff5400;
    max-width: 150px;
    margin: 10px auto 30px;
    display: block;
}

.menu-util .toggle-search svg {
  width: 20px;
  height: 20px;

}
.h-drop .close svg{
  width: 24px;
  height: 24px;
}

.fund-next a span img{
    width: 26px;
    height: 20px;
    display: inline-block;
}

.logotype img{
    width: 190px;
    height: 25px;
    display: inline-block;

    @include sp() {
      width: 159.6px;
      height: 21px;
    }

}
