/* Resetalize v1.2
================================================== */

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td, figure {
	margin: 0;
	padding: 0;
}

/* Apply a natural box layout model to all elements: http://paulirish.com/2012/box-sizing-border-box-ftw/ */
*, *:before, *:after {box-sizing: border-box; }

html {
	font-family: sans-serif; /* 1 */
	-ms-text-size-adjust: 100%; /* 2 */
	-webkit-text-size-adjust: 100%; /* 2 */
}




/* HTML5 display definitions   ! normalize.css v3.0.1
================================================== */

/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
	display: inline-block; /* 1 */
	vertical-align: baseline; /* 2 */
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
	display: none;
	height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */

[hidden],
template {
	display: none;
}




/* Tables  ! normalize.css v3.0.1
================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}




/* Embedded content
================================================== */

/**
 * Remove border when inside `a` element in IE 8/9/10.
 */

img {
	border: 0;
	max-width: 100%;
	height: auto;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */

svg:not(:root) {
	overflow: hidden;
}





/* Other
================================================== */

/**
 * Prevent address from being italicized
 */
address {
     font-style: normal;
}


/**
 * Remove black outline in Windows Chrome
 */

button {

	&:focus {
		outline: none;
	}
}
