.p-topExplore{
  padding-bottom: 100px;
  @include sp() {
    padding-bottom: 100/750*100vw;
    
  }
  &--research{
    padding: 100px 0;
    background: #F4F4F4;
  }
  &--movie{
    padding: 100px 0;
    background: #F4F4F4;
  }
  &--news{
  
    padding: 100px 0;
  
  }
  &__title{
    // padding-bottom: 100px;
  
  
  }

}
