.p-fundPageList {
  position: relative;

  &__btn {
    // border-bottom: 1px solid #e5e6e5;
    // padding:0 0 10px 0;
    display: block;
    position: relative;
    cursor: pointer;

    &::before {
      content: "↓";
      transition: left 0.3s;
      position: absolute;
      right: 0;
      // top: 10px;
      font-family: $sans2;
      font-weight: 400;
      display: block;
      transition: transform 0.3s;
      font-size: 18px;
      color: $orange;
    }
    &.is-open {
      &::before {
        // transform: rotate(180deg);
        content: "↑";
      }
    }
  }
  &__body {
    height: 0;
    padding: 0;
    display: block;
    transition: all 300ms 0s ease;
    overflow: hidden;
    &.is-open {
      height: auto;

      padding: 10px 0 1px 0;
    }
  }
}
