
.wp-video {
	position: relative; 
	width: 100%; 
	height: 0; // avoid the flash of video larger than container on load
	overflow: hidden;
	margin-bottom: $mb;

	.mejs-controls .mejs-time-rail .mejs-time-current {
		background-color: $orange;
	}
	.mejs-container, .mejs-container .mejs-controls, .mejs-embed, .mejs-embed body {
		background-color: $dblue;
	}
}

