/* HEADER
---------------------------------------------- */

.h-drop {
	background-color: $marine;
	position: relative;
	max-width: none;
	margin: 0;

	.modal-content {
		background-color: transparent;
		border: none;
		padding-bottom: 120px;
	}
	.hl {
		@include fs(28,28,400,0.5);
		margin-bottom: 50px;
		color: #fff;
		padding-top: 20px;
		position: relative;

		&:before {
			content: '';
			width: 30px;
			height: 7px;
			background-color: $orange;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	.close {
		position: absolute;
		top: 10px;
		right: 10px;

		a {
			display: inline-block;
			padding: 20px;
		}
		svg {
			fill: white;
			width: 20px;
			height: 20px;
		}
	}
	.wrap-pf {
		padding: 100px 50px 0;
		width: 1000px;
		margin: 0 auto;

		@include mq(1199) {
			width: 100%;
			padding: 100px 50px 0;
		}
		@include mq(599) {
			padding: 100px 30px 0;
		}

		.flex {
			display: flex;
			justify-content: space-between;
			margin-left: -30px;
			margin-right: -30px;

			@include mq(879) {
				display: block;
			}
		}
		.col1 {
			flex: 0 0 50%;
			max-width: 50%;

			@include mq(879) {
				max-width: none;
			}
		}
		a {
			display: block;
			padding: 0 30px;
			color: $marine;
		}
		.card {
			background-color: #fff;
			padding: 20px;
			display: flex;
			justify-content: space-between;
		}
		.data {
			flex: 0 0 250px;
			max-width: 250px;

			@include mq(879) {
				max-width: none;
			}
		}
		h6 {
			background-color: $bg;
			padding: 18px 20px;
			font-family: $sans-c;
			@include fs(18,33,700,0.2);
		}
		svg {
			height: 70px;
			width: auto;
			fill: $sea;
		}
		table {
			width: 100%;
			border-top: 1px solid $lines;
		}
		td {
			font-family: $sans-c;
			@include fs(15,22,700,0.2);
			color: $granite;
			border-bottom: 1px solid $lines;
			padding: 7px 0;


			&:nth-of-type(even) {
				padding-right: 10px;

				@include mq(767) {
					padding: 0;
				}
			}

			&:nth-of-type(2n) {
				font-weight: 400;
				text-align: right;
			}
		}
		button {
			width: 100%;
			background-color: $sea;
			border: none;
			color: #fff;
			font-weight: 700;
			text-align: center;
			padding: 6px 20px;
			display: block;
			@include after-arrow(#fff);
		}
	}
	.wrap-subscribe {
		padding: 100px 50px 0;
		width: 1200px;
		margin: 0 auto;

		@include mq(1199) {
			width: 100%;
			padding: 100px 50px 0;
		}
		@include mq(599) {
			padding: 100px 30px 0;
		}

		.flex {
			display: flex;
			justify-content: space-between;

			@include mq(991) {
				display: block;
			}
		}
		.col1 {
			flex: 0 0 66%;
			max-width: 66%;

			@include mq(991) {
				max-width: none;
			}
		}
		.col2 {
			flex: 0 0 30%;
			max-width: 30%;

			@include mq(991) {
				max-width: none;
			}
		}
	}
	.wrap-contact {
		padding: 100px 50px 0;
		width: 1200px;
		margin: 0 auto;

		@include mq(1199) {
			width: 100%;
			padding: 100px 50px 0;
		}
		@include mq(599) {
			padding: 100px 30px 0;
		}
		.sub-head {
			color: #fff;
			margin-bottom: 0.5rem;
		}
		.flex {
			display: flex;
			gap: 3rem;
			margin-bottom: 2rem;
			@include mq(879) {
				display: block;
			}
		}
		.col {
			width: 100%;
			max-width: 380px;
			padding: 0;
			.link {
				margin-bottom: 0;
				@include mq(879) {
					margin-bottom: 1rem;
				}
				.mobi {
					display: none;

					@include mq(879) {
						display: inline;
					}
				}
				.desk {
					display: inline;

					@include mq(879) {
						display: none;
					}
				}
			}
		}
	}
	.subscribe-copy {
		color: $ngrey5;

		h6 {
			@include fs(18,43,400);
			font-family: $sans2;
			color: #fff;
			margin-bottom: 10px;
		}
		p {
			@include fs(16,20,400);
			margin-bottom: $mb;
		}
	}
	.c-links {

		a {
			color: #fff;
			display: block;
			border: 2px solid $ngrey2;
			border-radius: 3px;
			@include fs(20,32,400);
			font-family: $sans2;
			padding: 0 0 0 20px;
			display: flex;
			align-items: center;
			height: 61px;
			margin-bottom: 30px;
			white-space: nowrap;

			&:hover {
				color: $ngrey2;
			}

			img {
				margin-right: 20px;
				display: inline-block;
			}
		}
		.mobi {
			display: none;

			@include mq(1199) {
				display: inline;
			}
		}
		.desk {
			display: inline;

			@include mq(1199) {
				display: none;
			}
		}
	}
	.wrap-search {
		padding: 100px 50px 0;
		width: 600px;
		margin: 0 auto;

		@include mq(599) {
			width: 100%;
			padding: 100px 30px 0;
		}
		@include mq(374) {
			padding-left: 15px;
			padding-right: 15px;
		}

		p {
			color: #fff;
			margin-bottom: 15px;
		}
	}
	.toggle-s-types {
		padding-bottom: 30px;

		button {
			@include fs(28,28,400);
			color: $ngrey2;
			padding: 0 0 14px;
			border: none;
			background: transparent;
			border-bottom: 3px solid $marine;
			margin-right: 40px;
			position: relative;
			transition: color 0.2s ease-out;

			@include mq(599) {
				font-size: 20px;
			}
			@include mq(413) {
				margin-right: 30px;
			}
			@include mq(374) {
				font-size: 18px;
				margin-right: 20px;
			}

			&.active {
				border-bottom: 3px solid $orange;
				color: #fff;
			}
			&:hover {
				color: #fff;
			}
			span {
				@include fs(11,11,400,2);
				@include font-brown;
				color: $orange;
				position: absolute;
				top: 0;
				right: -40px;

				@include mq(374) {
					font-size: 9px;
					right: -34px;
				}
			}
		}
	}
	.type {
		display: none;

		&.active {
			display: block;
		}
	}
	.s-form {
		position: relative;

		.field {
			color: $text-color;
			height: 44px;
			width: 100%;
			border: none;
			background-color: #fff;
			font-size: 16px;
			font-family: $sans;
			font-weight: 300;
			padding: 0 90px 0 14px;
			-webkit-box-shadow: none;
			box-shadow: none;
			-webkit-appearance: none;
			transition: width 0.2s ease-out;

			&::placeholder {
				transition: opacity .5s ease-out;
			}

			&:focus,
			&.focused {
				outline: none;

				&::placeholder {
					opacity: 0;
				}
			}
		}
		.field::-ms-clear {
			display: none; // remove the "x" in IE
		}
		.submit-wrap {
			height: 44px;
			width: 44px;
			position: absolute;
			top: 0;
			right: 0;

			svg {
				fill: $orange;
				width: 16px;
				height: 16px;
				position: absolute;
				top: 14px;
				left: 14px;
				cursor: pointer;
			}
		}
		.submit {
			border: none;
			background-color: transparent;
			height: 44px;
			width: 44px;
		}
	}
}
.modal-region {

	.modal-dialog {
		padding: 50px 0;
		max-width: 700px;

		@include mq(767) {
			max-width: none;
			margin: 0 15px;
			padding: 15px 0;
		}
	}
	.modal-content {
		border-radius: 0;
	}
	.close {
		position: absolute;
		top: 0;
		right: 0;

		a {
			display: block;
			padding: 20px;
		}
		svg {
			fill: $marine;
			width: 20px;
			height: 20px;
		}
	}
	.hl {
		padding: 20px 50px;
		background-color: $bg;
		color: $granite;
		@include font-brown;
		@include fs(18,20,400);
	}
	.sites {
		padding: 20px 50px;

		li {
			margin-bottom: 10px;
		}
		a, button {
			border: none;
			background: none;
			@include font-brown;
			@include fs(14,20,700);
			color: $marine;
			padding-left: 0;
			cursor: pointer;
			&.active {
				color: $ngrey2;
				pointer-events: none;
			}
		}
	}
	.msg {
		padding: 50px 50px;
		@include fs(14,21,400);

		strong {
			font-weight: 700;
		}
		p {
			margin-bottom: 21px;
		}
		a {
			display: inline-block;
			color: #fff;
			@include fs(15,31,400,2);
			font-family: $sans2;
			text-transform: uppercase;
			background-color: $orange;
			padding: 6px 30px;
			transition: background-color 0.2s ease-out;

			&:hover,
			&:focus {
				background-color: $link-hover-color;
			}
		}
	}
}
.util-header {
	background-color: $dblue;
	padding: 5px 30px;
	display: flex;
	justify-content: space-between;

	@include mq(991) {
		padding-left: 15px;
		padding-right: 15px;
	}

	a {
		font-size: 11px;
		line-height: 14px;
		color: #fff;

		&:first-of-type {
			margin-right: 15px;
			width: 45px;
		}
	}
	svg {
		fill: #fff;
		display: inline-block;
	}
	.svg-pin2 {
		width: 9px;
		height: 14px;
		position: relative;
		top: 3px;
		margin-right: 3px;
	}
	.svg-search {
		width: 14px;
		height: 14px;
		position: relative;
		top: 3px;
		margin-left: 6px;
	}
	.left {
		display: flex;
		font-size: 11px;
		line-height: 14px;
		color: #fff;
		align-items: center;

		.change-lang-wrap {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 0.3rem;
			.change-lang__item {
				&:not(:first-child) {
					border-left: 1px solid #fff;
					margin-left: 6px;
					padding-left: 6px;
				}
				.change-lang__item-link {
					margin: 0;
					&.active {
						color: $orange;
					}
				}
			}
		}
	}
}
.main-header2 {
	padding: 30px 0 20px;
	font-family: $sans2;
	position: relative;

	@include mq(991) {
		padding-top: 22px;
	}

	.logotype {
		width: 190px;
		display: block;
		height: 25px;

		@include mq(991) {
			width: 160px;
			height: 21px;
		}

		svg {
			fill: $orange;

			@include mq(991) {
				width: 160px;
				height: 21px;
				position: relative;
				top: 2px;
			}
		}
	}
	.main-menu {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
	.main-nav {
		margin-top: 10px;

		@include mq(991) {
			display: none;
			margin: 0;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 99;
			overflow: auto;
			position: fixed;
			background-color: $marine;
			padding: 70px 35px 60px;
		}

		li.top {
			display: inline;
			margin-left: 28px;

			@include mq(991) {
				display: block;
				margin: 0 0 20px;
			}
			&.active > a {
				color: $orange;
			}
		}
		.aa {
			@include fs(15,24,400);
			color: #fff;

			@include mq(991) {
				@include fs(20,30,400,1);
				@include font-brownbold;
				text-transform: none;
				display: table;
			}

			&:hover {
				color: $link-color;

				svg {
					fill: $link-color;
				}
			}

		}
		.show .aa svg {
			transform: rotate(180deg);
		}
		svg {
			transition: fill 0.2s ease-out;
			display: inline-block;
			fill: #fff;
		}
	}
	.svg-arrow {
		width: 9px;
		margin-left: 2px;

		@include mq(991) {
			width: 11px;
			position: relative;
			top: -2px;
		}
	}
	.toggle-search {
		padding: 0 5px;
		position: relative;
		top: 5px;

		svg {
			width: 16px;
			height: 16px;
		}
	}
	.dropdown-menu {
		background-color: #fff;
		box-shadow: 0 2px 4px 0px hsla(0,0%,20%,.25);
		padding: 28px 30px 10px;
		margin: 20px 0 0;
		border-radius: 0;
		border: none;
		border-top: 1px solid $orange;
		@include fs(14,20,400);
		left: -30px !important;

		a {
			color: $marine;
			display: block;
			margin-bottom: 18px;
			white-space: nowrap;

			@include mq(991) {
				color: #fff;
			}

			&:hover {
				color: $link-color;
			}
		}

		@include mq(991) {

			&.show {
				float: none;
				position: static !important;
				transform: none !important;
				background-color: transparent;
				box-shadow: none;
				border: none;
				font-size: 16px;
				padding-top: 20px;
				left: 0;
			}
		}
	}
}

.toggle-menu {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 101;

	@include mq(991) {
		display: block;
	}

	.navicon {
		display: block;
		height: 2px;
		margin: 34px 16px;
		width: 24px;
		transition: all 0.3s ease;
		position: relative;
		background-color: #fff;

		&:before,
		&:after {
			content: "";
			display: block;
			height: 2px;
			position: absolute;
			transition: all 0.3s ease;
			width: 24px;
			background-color: #fff;
		}
		&:before {
			top: -8px;
		}
		&:after {
			top: 8px;
		}
	}
}
.open-menu {
	overflow: hidden;

	.main-nav {
		display: block;
	}
	.toggle-menu {
		.navicon {
			background-color: transparent;
			&:before {
				top: 0;
				transform: rotate(-45deg);
			}
			&:after {
				top: 0;
				transform: rotate(45deg);
			}
		}
	}
}




/* FOOTER
---------------------------------------------- */

.footer {
	background-color: $marine;
	padding-top: 50px;
	padding-bottom: 50px;

	.flex {
		display: flex;
		justify-content: flex-start;
		padding-bottom: 30px;

		@include mq(767) {
			display: block;
		}
	}
	.first {
		flex: 0 0 16%;
		max-width: 16%;

		@include mq(767) {
			display: none;
		}
	}
	.links-1,
	.links-2 {
		flex: 0 0 22%;
		max-width: 22%;
		max-width: 40%;

		@include mq(929) {
			flex: 0 0 25%;
			max-width: 25%;
		}

		@include mq(767) {
			max-width: none;
		}

		a {
			font-family: $sans2;
			display: inline-block;
			color: #fff;
			opacity: 0.75;
			text-transform: uppercase;
			@include fs(14,30,400,1);
			transition: opacity 0.2s ease-out;

			span {
				text-transform: lowercase;
			}
			&:hover {
				opacity: 1;
			}
		}
	}
	.links-2 {
		flex: 0 0 50%;
		// max-width: 50%;
		margin-left: 3%;

		@include mq(767) {
			margin-left: 0;
		}
	}
	svg {
		fill: $orange;
	}
	.logo {
		width: 60px;
		height: 68px;
		display: block;
		// margin: 8px 0 60px;
		margin: 8px 0 20px;
	}
}
.brokercheck {
	border-top: 1px solid $bluelines;
	margin-top: -1px;
	padding: 40px 0 0;
	text-align: center;
	color: #fff;


	@include mq(991) {
		br {
			display: none;
		}
	}
	@include mq(767) {
		margin: 20px 15px 0;
		text-align: left;

		div {
			padding: 0;
		}
	}
}
.notices {
	// padding: 60px 0 44px;
	padding: 30px 0 30px;
	border-top: 1px solid $bluelines;

	@include mq(767) {
		padding: 30px 0 10px;
	}

	p {
		margin-bottom: 16px;
	}
	a {
		color: #fff;

		span {
			opacity: 0.75;
			transition: opacity 0.2s ease-out;
		}

		&:hover span {
			opacity: 1;
		}
	}
	.svg-arrow {
		display: inline-block;
		width: 9px;
		margin-left: 8px;

		@include mq(991) {
			width: 11px;
			position: relative;
			top: -2px;
		}
	}
	.a {
		max-width: 600px;
	}
}
.disclaim {
	padding: 60px 0 30px;
	border-bottom: 1px solid $bluelines;
	background-color: $dblue;

	& > div {
		color: $ngrey5;
		@include fs(17,25);
		column-count: 3;
		column-gap: 30px;

		@include mq(767) {
			column-count: 1;
		}

		a {
			color: #fff;
			text-decoration: underline;
		}
	}
	p {
		margin-bottom: $mb;
	}
	strong {
		font-weight: 700;
	}
	em {
		font-style: italic;
	}

}

// .ms-disclaim {
// 	border-bottom: 1px solid $bluelines;
// }
#modal-signup {

	.modal-content {
		padding: 20px;
	}
}
.back-to-top {
	background-color: $dblue;
	padding: 30px 0 12px;

	@include mq(767) {
		padding-bottom: 25px;
	}

	.to-top {
		font-family: $sans2;
		@include fs(17,25,400,0.5);
		color: #fff;
		opacity: 0.4;
		transition: opacity 0.2s ease-out;
		display: inline-block;
		margin-bottom: 15px;

		@include mq(767) {
			margin: 0;
		}

		&:hover {
			opacity: 1;

			&:before {
				top: -6px;
			}
		}

		&:before {
			display: inline-block;
			content: '→';
			transform: rotate(-90deg);
			transition: left 0.3s;
			position: relative;
			top: 0;
			margin-right: 6px;
			transition: top 0.3s;
		}
	}
}
