.p-conAbout {
  padding-bottom: 100px;
  table {
    width: 100%;
    margin-top: 4px;
  }
  th {
    text-align: left;
    padding: 10px 0;
    @include fs(15);
    width: 45%;
    vertical-align: top;
  }
  td {
    padding: 10px 0;
    @include fs(15);
    
  }
  tr{
	 border-bottom: 1px solid $lines;
  
  }
}
