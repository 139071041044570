.p-pubMain {
  padding: 0 0 100px;
  &+h2{
    padding-top: 0;
    margin-top: -20px;
  }
  p + p {
    margin-top: 15px;
  }
  &__list {
    padding-top: 24px;
    li {
      line-height: 1.6;
      padding: 24px 0;
      border-bottom: 1px solid $lines;
    }
  }
  &__listDate{
    @include fs(13);
    width: 9em;
    display: inline-block;
    @include sp() {
      display: block;
    }

  }
  &__listTitle{
    padding-right: 1em;
    display: inline-block;
    @include sp() {
      display: block;
    }
  }
  &__listLink{
    display: inline-block;
    @include sp() {
      display: block;
    }
  }
}
