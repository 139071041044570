

/* SEARCH
---------------------------------------------- */

.search {
	padding: 40px 0;

	@include mq(767) {
		padding: 40px 0;
	}
	@include mq(599) {
		// background-color: $ngrey7;
	}

	.query {
		margin-top: 80px;
		margin-bottom: 60px;
	}
	// h1 {
	// 	@include hhh4;
	// 	text-transform: uppercase;
	// 	border-bottom: 3px solid $lines; 
	// 	padding-bottom: 25px;
	// 	margin-bottom: 25px;
	// 	color: $marine;
	// }
	.group {
		margin-bottom: 50px;
	}
	.group-title {
		display: block;
		@include hhh2;
	}
	.results {
		margin-bottom: 80px;

		.entry {
			border-bottom: 1px solid $lines;
			padding: 30px 0;

			img {
				float: left;
				width: 75px;
				height: 75px;
				margin: 8px 20px 10px 0;
				display: block;
				background-color: $marine;
			}
			p {
				@include fs(14,23,400);
				margin-left: 95px;
				padding-bottom: 1px;
			}
		}
		strong {
			background-color: yellow;
			font-weight: 700;
		}
	}
}
