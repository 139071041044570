@media print {

  @page {
    size: A4 portrait;
    margin: 20mm 0;
  }


  .topper,
  .bg-map,
  .bg1,
  .bg2,
  .bg3,
  .bg4,
  .fund-head,
  .footer,
  .l-footerWrap,
  .disclaim{
    background: none !important;
    background-image: none !important;
    *{
      color: #606060 !important;
    }
  }


  .m-pageTop,
  .sidebar-videos {
    display: none;
  }


  .blog .post {
		page-break-inside:avoid;
  }

	body{
    width: 1200px;
  }

  body#home{
    .row{
      display: flex;

      .col-md-8{
        width: 70%;

      }
      .col-md-4{
        width: 30%;
      }

    }
  }
  body#fund{
    .row{
      display: flex;
      .col-md-4{
        width: 30%;
      }
      .col-lg-6{
        width: 50%;
      }
      .col-lg-7.col-xl-8{
        width: 70%;

      }
      .col-lg-5.col-xl-4{
        width: 30%;
      }

    }
    #summary,
    #objective,
    #why,
    #prices,
    #history,
    .vendor,
    .news{
      page-break-inside:avoid;
    }
    .financier .post-content > div{
      page-break-inside:avoid;


    }
  }
	body#blog{

    .p-movieList{
      &__item{
        width: 30% !important;
      }
    }
    .m-movie__imgWrap::after,
    .p-resVideo__imgWrap::after {
      opacity: 0;
    }
  }
	body#home{
    .container-fluid .m-btnWrap,
    .p-topExplore--research,
    .p-topExplore--movie,
    .p-topExGpTableWrap{
      page-break-inside:avoid;
    }
    .p-topVideo{

      .card{
        height: 400px;
        overflow: hidden;
        position: relative;
        *{
          background: #fff !important;
        }
      }
      &__imgWrap{
        &::after{
          opacity: 0;
        }
      }
      .textwrap{
        position: absolute;
        top: 200px;
      }
    }


  }
}