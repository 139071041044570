.p-notice{
  padding:50px 0 100px;
  


	h2 {
		// font-family: $sans2;
		@include fs(22);
		// text-transform: uppercase;
		color: $marine;
		// margin-bottom: 30px;
		padding-top: 24px;
		padding-bottom: 10px;
		border-bottom: 4px solid #E5E6E5;
		position: relative;
	}
	p {
		@include fs(15);
	}
  // p + p {
  //   margin-top: 15px;
  // }
  h3 {
    // font-family: $sans2;
    @include fs(15);
    // text-transform: uppercase;
    color: $marine;
    // margin-top: 30px;
    padding-top: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E5E6E5;
  }

  ul{
  padding-top: 24px;
  
  }
  &__block{
    padding-top: 24px;
    padding: 24px 0;
  
  }









}
