
@media print {

	.main-header {

		.main-menu,
		.menu-util {
			display: none;
		}
		.logotype {
			max-width: 300px !important;
			height: 39px !important;
		}
	}
	header.small-top {
		height: 30px !important;
	}

	.disclaim {
		padding: 20px 0;

		& > div {
			font-size: 12px !important;
			line-height: 16px !important;
			column-count: 1 !important;
		}
	}
	.footer {
		display: none;
	}




	// BLOG
	#blog {

		.row {
			display: block; // reset flex
		}
		article .col-md-7,
		article .col-md-3 {
			max-width: none !important;
			margin: 0 !important;
		}
		.meta .inner {
			margin-bottom: 0 !important;
			border-bottom: none !important;
		}
		.meta .related {
			display: none;
		}
		.back-to-all {
			display: none;
		}
		.footnotes {
			padding: 0;
		}
	}



	// FUNDS
	#fund {

	}
	.fund-head {
		color: $marine !important;
	}
	.fund-next {
		display: none;
	}

}