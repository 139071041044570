.p-sitemap{
  padding: 24px 0 100px;
  display: flex;
  justify-content: space-between;
  
  @include sp() {
    flex-direction: column;
  }
  &__list{
    width:calc(50% - 10px);
    @include sp() {
      width: 100%;
    }
    & > li{
      @include fs(22);
      break-inside: avoid;
     & > a{
        display: block;
        border-bottom: 4px solid $ngrey6;
        padding: 20px 0;
      }
    }
  
  }
  &__inList{
    padding-top: 10px;
    & > li{
      padding: 5px 0;
      @include fs(16);
    }
  }

}
