.accordian{
	li {
		padding:10px 0;

		@include mq(599) {
			// padding: 30px 0;
			padding: 5px 0;
		}
		&:hover {
			h3 {
				color: #fff;
			}
		}
		&.active {
			h3 {
				color: #fff;
				svg {
					 transform: rotate(180deg);
				}
			}
		}
		h3 {
			@include fs(16,24,500);
			transition: color 0.2s ease-out;
			cursor: pointer;
			color:rgba(255,255,255,.6);
		}
		.a {
			display: none;
			padding:10px 0;
			color:rgba(255,255,255,.6);
		}
	}

}
