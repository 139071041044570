.p-topNav{
  display: flex;
  &__item{
    // background: #000;
    flex-grow: 1;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include fs(15);
    @include sp() {
      line-height: 1.6;
      @include fs(15);
      padding: 10px 0;

    }
    &:hover{
      background: rgba($color: #fff, $alpha: .1)

    }
    span{
      // display: inline-block;
      &.is-english {
        @include sp() {
          @include fs(12);
        }
      }
    }
    &.is-active{
      background: #fff;
      color: $orange;
      position: relative;
      &::before {
        content:"";
        display: inline-block;
        width: 100%;
        height: 4px;
        position: absolute;
        bottom:100%;
        right: 0;
        left: 0;
        background:  $orange;
      }
    }

  }


}
