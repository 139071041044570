


/* EXPLORE
---------------------------------------------- */

.explore-hero.topper {
	// background:transparent url('/images/funds-hero.png') top center no-repeat;
	background-size: cover;
	height: auto;
	padding-bottom: 0px;
}
.explore-hero.explore-hero-en.topper {
	padding-bottom: 0px;
}
.explore-view-nav {
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 100%;
	height: 44px;
	text-align: center;

	a {
		display: inline-block;
		font-family: $sans2;
		@include fs(15,44,400,1);
		text-transform: uppercase;
		color: #fff;
		opacity: 0.5;
		position: relative;
		transition: opacity 0.2s ease-out;
		padding: 0 20px;

		svg {
			fill: #fff;
		}

		&.active {
			opacity: 1;
			color: $orange;
			background-color: #fff;
			box-shadow: 0 -5px 0 $link-color;

			svg {
				fill: $orange;
			}
		}
		&:hover {
			opacity: 1;
		}
		span {
			width: 26px;
			height: 20px;
			display: inline-block;
			position: relative;
			top: 5px;
			margin-right: 2px;
		}
	}
	.fl-loading {
		display: inline-block;
		font-family: $sans2;
		@include fs(15,44,400,1);
		text-transform: uppercase;
		opacity: 1;
		color: #fff;
		opacity: 0.75;
		display: none;
		padding: 0 20px;

		.spinner {
			width: 26px;
			height: 20px;
			display: inline-block;
			position: relative;
			top: 5px;
			margin-right: 2px;

			&:before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background: transparent center no-repeat url('data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=');
			}

		}
	}
}
.fund_map {
	text-align: center;
	// @include fs(34,46);
	@include fs(33,46);
	font-family: $sans2;
	padding: 20px 0 0;
	color: $marine;
	padding-top: 40px;

	@include mq(991) {
		@include fs(30,42);
	}

	@include mq(h700) {
		padding: 70px 0 0;
		@include fs(30,42);
	}

	@include mq(575) {
		padding: 40px 0 0;
		@include fs(24,32);
	}

	p {
		max-width: 1030px;
		margin: 0 auto;
	}
	&.en {
		@include mq(767) {
			margin-top: 140/750*100vw;
		}
	}
}

.explore-group {
	padding: 100px 0;

	@include mq(991) {
		padding: 80px 0;
	}

	&.odd {
		background-color: $ngrey7;

		h4 {
			background-color: $ngrey7;
		}
	}
	.icon {
		margin-bottom: 30px;

		@include mq(991) {
			max-width: 174px;
		}
		@include mq(575) {
			float: none;
			margin: 0 auto 30px;
		}
	}
	.desc {

		@include mq(575) {
			h2 {
				text-align: center;
			}
		}
	}
	svg {
		fill: $orange;
	}
	h2 {
		font-family: $sans2;
		@include fs(32,32,400,2);
		color: $marine;
		text-transform: uppercase;
		margin-bottom: 1em;
	}
	p {
		@include fs(17,28,400,0.5);
		color: $granite;
		margin-bottom: 20px;
	}
	h4 {
		@include fs(15,28,400,0.5);
		position: relative;
		display: table-cell;

		@include mq(413) {
			@include fs(13,28,400,0.5);
		}
	}
	li {
		font-family: $sans-c;
		border-bottom: 1px solid $ngrey6;

		a {
			display: table;
			@include fs(15,33,400,0.5);
			position: relative;
			padding: 2px 0;
		}
		span {
			font-weight: 700;
			width: 68px;
			display: block;
			display: table-cell;
		}
	}
}



// body.fl-open {

// 	#explore {
// 		display: none;
// 	}
// 	#fund-list {
// 		display: block;
// 	}
// }


#fund-list {
	// display: none;
	display: block;

	background-color: #fff;
	padding: 60px 0 0;
}


.explore-fund-list-intro {
	color: $ngrey2;
	padding: 26px 0 60px;

	h2 {
		font-family: $sans2;
		@include fs(22,22,400,1);
		text-transform: uppercase;
		color: $marine;
		margin-bottom: 30px;
		padding-bottom: 10px;
		border-bottom: 4px solid #E5E6E5;
	}
	p {
		@include fs(15,24,400,0.2);
	}

	&.is-noSort .sort th {
		// padding: 0 14px 14px;
		padding: 4px 10px 14px;
		height: 20px;
	}

}
.explore-fund-list {

	.loader {
		position: relative;
		margin-bottom: 60px;

		@include mq(1239) {
			overflow-x: scroll;
		}
	}
	.loading {
		overflow: hidden;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 300px;
			background: transparent center no-repeat url('data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=');
		}
	}
	table {
		min-width: 100%;
		font-family: $sans-c;

	}
	&.is-noSort .sort th {
		// padding: 0 14px 14px;
		padding: 4px 10px 14px;
		height: 20px;
	}

	.sort th {
		text-align: left;
		@include fs(14,20,700,0.5);
		text-transform: uppercase;
		// padding: 0 14px 40px 14px;
		padding: 4px 10px 40px 10px;
		height: 84px;
		vertical-align: top;
		background-color: transparent;
		position: relative;
		border-bottom: 2px solid $lines;
		line-height: 1;


		:focus {
			outline: none;
		}
		&.c-left {
			// padding-left: 0px;
			// padding-left: 4px;
			padding-left: 10px;

			.sorter {
				&:after {
					// left: 0;
					left: 10px;
				}
			}
		}
		&.c-right {
			padding-right: 0px;

			.sorter {
				&:after {
					right: 0 !important;
				}
			}
		}

		@include mq(767) {
			font-size: 11px;
			line-height: 15px;
		}

		// .pad {
		// 	padding-right: 18px;
		// }
		// .icon-help {
		// 	cursor: pointer;
		// 	color: $orange;
		// 	position: absolute;
		// 	top: 21px;
		// 	right: 13px;

		// 	@include mq(767) {
		// 		top: 16px;
		// 	}
		// }
		sup {
			position: absolute;
			top: 25px;
			right: 6px;

			@include mq(767) {
				top: 20px;
			}
		}

		button {
			height: 100%;
			width: 100%;
			padding: 4px 14px 40px 14px;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
		}

		.sorter {
			display: block;
			cursor: pointer;
			color: $orange;
			transition: color 0.2s ease-out;

			&:after {
				content: '→';
				transition: left 0.3s;
				position: absolute;
				left: 14px;
				bottom: 12px;
				font-family: $sans2;
				font-weight: 400;
				display: block;
				transition: transform 0.3s;
				font-size: 20px;
			}
			&:not(.sort-desc) {
				&:hover {

					&:after {
						transform: rotate(-90deg);
					}
				}
			}
		}
		.sort-desc,
		.sort-asc {
			color: $marine;
		}
		.sort-desc {

			&:after {
				transform: rotate(90deg);
			}
		}
		.sort-asc {

			&:after {
				transform: rotate(-90deg);
			}
		}
	}
	.sort-ignore {
		position: relative;

		td {
			text-align: right;
		}
		a {
			cursor: pointer;
			color: $ngrey5;
		}
	}
	td {
		vertical-align: middle;
		border-bottom: 1px solid $lines;
		// padding: 12px 14px 8px ;
		padding: 12px 10px 8px ;
		white-space: nowrap;
		// border: 1px solid #000;
		line-height: 1;


		@include mq(767) {
			font-size: 11px;
			line-height: 15px;
		}

		&.c-left {
			// padding-left: 0px;
			padding-left: 10px;
		}
		&.c-right {
			padding-right: 0px;
		}
	}
	td.tar {
		text-align: right;
		font-family: arial;
		font-size: 14px;

		@include mq(767) {
			font-size: 10px;
			line-height: 15px;
		}
	}
	b {
		font-weight: 700;
	}
	th.tar {
		text-align: right;

		.sorter {

			&:after {
				left: auto;
				right: 14px;
			}

		}
	}
	// tr:last-of-type td {
	// 	border: none;
	// }


	// tr th:nth-of-type(2),
	// tr th:nth-of-type(4),
	// tr th:nth-of-type(6),
	// tr th:nth-of-type(7) {
	// 	border-right: 1px solid $lines;
	// }
	// tr td:nth-of-type(2),
	// tr td:nth-of-type(4),
	// tr td:nth-of-type(6),
	// tr td:nth-of-type(7) {
	// 	border-right: 1px solid $lines;
	// }


	.footer_note{
		padding-bottom: 100px;

	}



	.footer_note,
	footer {
		@include fs(15,27);
		color: $ngrey2;
		margin-bottom: 20px;

		@include mq(991) {
			@include fs(13,24);
		}

		p {
			padding-left: 1em;
			margin-bottom: 20px;
			position: relative;
		}
		sup {
			position: absolute;
			top: 6px;
			left: 0;
		}
	}
	.dwn {
		font-size: 12px;
		letter-spacing: 1px;
		font-weight: 700;

		@include mq(767) {
			font-size: 10px;
			line-height: 15px;
		}

		span {
			margin-right: 3px;
			position: relative;
			top: 2px;
		}
	}
}



.fund-head {
	background-color: $marine;
	// font-family: $sans2;
	color: #fff;

	.main-header {
		margin-bottom: 16px;
	}
	hr {
		border-top: 7px solid $orange;
		width: 30px;
		margin-bottom: 15px;
	}
	h1 {
		@include fs(28,28,400,0.5);
		margin-bottom: 80px;
	}
	h2 {
		@include fs(72,72,400,0.5);
		margin-bottom: 40px;
	}
}
.fund-next {
	background-color: $earth;
	padding: 10px 0;
	font-family: $sans2;
	@include fs(14,24,400,1);
	text-transform: uppercase;

	@include mq(413) {
		font-size: 13px;
	}
	@include mq(374) {
		font-size: 11px;
	}

	a {
		opacity: 1;
		color: #fff;
		opacity: 0.75;
		height: 49px;
		top: -5px;

		&:hover {
			opacity: 1;
		}

		svg {
			fill: #fff;
			width: 26px;
			height: 20px;
		}
		span {
			width: 26px;
			height: 20px;
			display: inline-block;
			position: relative;
			top: 5px;
			margin-right: 2px;
		}
	}
	.all {
		float: left;
	}
	.next {
		text-align: right;
		float: right;
	}
}
.fl-loading {
	display: inline;
	opacity: 1;
	color: #fff;
	opacity: 0.75;
	height: 49px;
	top: -5px;
	display: none;
	margin: 0;

	.spinner {
		width: 26px;
		height: 20px;
		display: inline-block;
		position: relative;
		top: 5px;
		margin-right: 2px;

		&:before {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			background: transparent center no-repeat url('data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=');
		}
	}
}


.fund-detail {
	padding: 30px 0;

	.button {
		@include fs(12,20,700,1);
		text-transform: uppercase;
		font-family: $sans-c;
		display: inline-block;

		span {
			margin-right: 6px;
			font-size: 16px;
			position: relative;
			top: 2px;
		}
	}

	h3 {
		font-family: $sans2;
		@include fs(22,27,400,0.5);
		text-transform: uppercase;
		border-bottom: 4px solid $lines;
		padding-bottom: 12px;
		margin-bottom: 20px;
		color: $marine;
		position: relative;

		small {
			font-family: $sans;
			@include fs(15,27,400,0.2);
			color: $granite;
			text-transform: none;
			position: absolute;
			top: 3px;
			right: 0;
		}
	}
	.icon-help {
		cursor: pointer;
		color: $orange;
		position: relative;
		top: 2px;
		margin-left: 5px;
	}
	#summary {
		margin-top: 18px;
		margin-bottom: 40px;
	}
	#objective {
		margin-bottom: 40px;
	}
	#why {

		.post-content {
			margin-bottom: 60px;
		}
		svg {
			fill: $orange;
			max-width: 150px;
			margin: 10px auto 30px;
		}
		p.spec {
			@include fs(15,20,400,0.2);
			color: $ngrey2;
			font-family: $sans-c;
			margin-bottom: 60px;
		}
	}
	.prices {
		margin-bottom: 60px;
		h3 {
			margin: 0;

			small {
				float: right;
				position: relative;
				top: 3px;
			}
		}
		table {
			width: 100%;
			margin-top: 12px;
		}
		th {
			text-transform: uppercase;
			font-family: $sans-c;
			@include fs(13,20,700,0.2);
			color: $ngrey1;
			text-align: left;
			border-bottom: 1px solid $lines;
			padding: 13px 0 11px;
			border-top: 4px solid $lines;
		}
		td {
			font-family: $sans-c;
			@include fs(15,24,400,0.2);
			border-bottom: 1px solid $lines;
			padding: 5px 0;

			&.label {
				font-weight: 700;
			}

			@include mq(479) {
				font-size: 11px;
			}
		}
		small {
			top: 3px;
			position: relative;
		}
	}
	.header {

		h3 {
			border: none;
			margin: 0;
			padding: 0;
		}
		small {
			font-family: $sans;
			@include fs(15,27,400,0.2);
			color: $granite;
		}
		.as-of {
			@include mq(767) {
				text-align: right;
			}
		}
		.button {
			text-align: right;
			@include fs(12,20,700,1);
			text-transform: uppercase;
			font-family: $sans-c;
			padding-top: 6px;

			@include mq(767) {
				text-align: left;
				padding-top: 15px;
			}

			span {
				margin-right: 5px;
			}
		}
	}
	#history {
		margin-bottom: 60px;

		.header {
			margin-bottom: 15px;
		}
		p {
			@include fs(15,20,400,0.2);
			color: $ngrey2;
			font-family: $sans-c;
		}
		table {
			width: 100%;

			&:last-of-type {
				border-bottom: 1px solid $lines;
			}

			th {
				text-transform: uppercase;
				font-family: $sans-c;
				@include fs(13,16,700,0.2);
				color: $ngrey1;
				text-align: left;
				padding: 16px 5px 14px;
				background-color: $ngrey7;
				width: 20%;
				vertical-align: top;

				&.label {
					@include fs(18,20,700,0.2);
					text-transform: none;
					width: 40%;
				}
			}
			td {
				font-family: $sans-c;
				@include fs(15,24,400,0.2);
				border-top: 1px solid $lines;
				padding: 6px 5px 4px;

				&.label {
					font-weight: 700;
				}
				@include mq(479) {
					.tablesaw-cell-label {
						width: 45%;
					}

					.tablesaw-cell-content {
						width: 55%;
					}
				}
			}
			tr:first-of-type td {
				border: none;
			}
		}
		.post-content {
			margin-top: 15px;
		}
	}
	.details {
		background-color: $ngrey7;
		padding: 18px 14px;

		table {
			width: 100%;
		}
		h3 {
			margin-bottom: 5px;
		}
		h4 {
			font-family: $sans-c;
			@include fs(18,33,700,0.2);
			color: $marine;
			position: relative;
			transition: color 0.2s ease-out;
			border-bottom: 1px solid $lines;
			padding: 4px 0;
		}
		td {
			font-family: $sans-c;
			@include fs(15,22,700,0.2);
			border-bottom: 1px solid $lines;
			padding: 2px 0;

			&:nth-of-type(odd) {
				padding-left: 10px;
			}

			&:nth-of-type(2n) {
				font-weight: 400;
				text-align: right;
			}
		}
	}
	.dcal {
		font-family: $sans-c;
		@include fs(15,22,700,0.2);
		margin-top: 12px;
	}
	.fex {
		margin: 18px 0 4px;
		@include fs(12,20,400);
	}
	.add{
		margin-bottom: 20px;
	}
	.docs {
		background-color: $ngrey6;
		padding: 18px 14px;
		// margin-bottom: 60px;
		// margin-bottom: 20px;
		margin-bottom: 5px;

		h3 {
			border-color: $ngrey5;
		}

		ul {
			font-family: $sans-c;
			@include fs(15,24,700,0.2);
		}
		span {
			margin-left: 6px;
			font-size: 12px;
		}
		&.research {
			li:not(:last-child) {
				padding-bottom: 0.5rem;
			}
		}
	}
	// .research {
	// 	background-color: $ngrey7;
	// 	padding: 18px 14px 10px;
	// 	margin-top: -60px;
	// 	margin-bottom: 60px;

	// 	ul {
	// 		font-family: $sans-c;
	// 		@include fs(15,18,400,0.2);

	// 		li {
	// 			margin: 10px 0;
	// 		}
	// 	}
	// 	span {
	// 		margin-left: 6px;
	// 		font-size: 12px;
	// 	}
	// }
	.chars {
		margin-bottom: 60px;

		h3 {
			margin-bottom: 0px;

			small {

				@include mq(1239) {
					float: none;
					display: block;
				}
				@include mq(1100) {
					float: right;
					display: inline-block;
				}
				@include mq(413) {
					float: none;
					display: block;
				}
			}
		}
		table {
			width: 100%;
			margin-bottom: 5px;
		}
		td {
			font-family: $sans-c;
			@include fs(15,24,400,0.2);
			border-bottom: 1px solid $lines;
			padding: 6px 5px 4px;

			&.label {
				font-weight: 700;
			}
		}
		p {
			font-size: 13px;
			color: $ngrey2;
		}
		.yr {
			color: $granite;
			font-weight: 700;
			font-size: 13px;
			line-height: 24px;
			padding: 10px 5px;
		}
	}
	.stats {
		margin-bottom: 60px;

		h3 {
			margin: 0;
		}
		h4 {
			color: $ngrey1;
			@include fs(18,20,700,0.2);
			font-family: $sans-c;
			padding: 15px 0;
		}
		table {
			width: 100%;
		}
		p {
			font-size: 13px;
			color: $ngrey2;
		}
		th {
			text-transform: uppercase;
			font-family: $sans-c;
			@include fs(13,20,700,0.2);
			color: $ngrey1;
			text-align: left;
			border-bottom: 1px solid $lines;
			padding: 6px 5px 4px;
			background-color: $ngrey7;
		}
		td {
			font-family: $sans-c;
			@include fs(15,24,400,0.2);
			border-bottom: 1px solid $lines;
			padding: 6px 5px 4px;

			&.label {
				font-weight: 700;
			}
		}
		tfoot {

			td {
				border: none;
				padding: 15px 5px 10px;
			}
		}
		.icon-help {
			font-size: 15px;
		}
	}

	.fd-pies {
		margin-bottom: 50px;

		.flex {
		    display: flex;
			flex-direction: column;
			height: 100%;
			align-items: flex-start;

			.end, .pie-wrap, h3 {
				width: 100%;
			}
		}

		.pie-wrap {
			display: flex;
			flex-grow: 1;
		}

		.chart {
			max-width: 200px;
			flex: 0 0 200px;
			margin-bottom: 20px;
		}
		.table {
			width: calc(100% - 220px);
			margin-left: 20px;
			margin-bottom: 20px;

			td, th {
				font-family: proxima-nova-condensed,sans-serif;
				border-bottom: 1px solid #e5e6e5;
			}
			th {
				text-transform: uppercase;
				font-size: 13px;
				font-size: .8125rem;
				line-height: 1em;
				font-weight: 700;
				letter-spacing: .01538em;
				color: #727272;
				padding: 0 0 7px;
				text-align: center;
			}
			td {
				font-size: 15px;
				font-size: .9375rem;
				line-height: 1.33333em;
				font-weight: 400;
				letter-spacing: .01333em;
				padding: 3px 5px 1px;
			}
			td:nth-of-type(3) {
				text-align: right;
				padding-left: 30px;
				font-variant-numeric: tabular-nums;
			}
			.dot {
				padding-right: 10px;
				width: 22px;

				span {
					width: 12px;
					height: 12px;
					display: inline-block;
					border-radius: 50%;
					background-color: #ddd;
				}

				span.color1 {
					background-color: #ff5400;
				}
				span.color2 {
					background-color: #002f37;
				}
				span.color3 {
					background-color: #8eb1b2;
				}
				span.color4 {
					background-color: #606060;
				}
				span.color5 {
					background-color: #3d3935;
				}
				span.color6 {
					background-color: #727272;
				}
				span.color7 {
					background-color: #8e8e8e;
				}
				span.color8 {
					background-color: #a9a9a8;
				}
				span.color9 {
					background-color: #bcbebc;
				}
				span.color10 {
					background-color: #d2d3d1;
				}
				span.color11 {
					background-color: #e5e6e5;
				}
				span.color12 {
					background-color: #f4f4f4;
				}
			}
		}
	}


	.options-details-container {
		margin-bottom: 5rem;
	}
	.options-details-wrap {
		// margin-bottom: 60px;
		margin-bottom: 40px;

		.add-text {
			font-family: $sans-c;
			color: $ngrey2;
			@include fs(15,20,400,0.2);
			padding: 5px;
		}
	}
	// #options-details {
	.options-details {
		width: 100%;
		margin-top: 10px;

		th {
			text-transform: uppercase;
			font-family: $sans-c;
			@include fs(13,20,700,0.2);
			color: $ngrey1;
			text-align: left;
			border-bottom: 1px solid $lines;
			border-top: 4px solid $lines;
			padding: 16px 5px 14px;

			&.col-side {
				width: 220px;
				@include mq(639) {
					width: auto;
				}
			}
		}
		td {
			font-family: $sans-c;
			@include fs(15,24,400,0.2);
			border-top: 1px solid $lines;
			border-bottom: 1px solid $lines;
			padding: 6px 5px 4px;

			&.label {
				font-weight: 700;

				@include mq(413) {
					line-height: 16px;
				}
			}
			@include mq(479) {
				.tablesaw-cell-label {
					width: 45%;
				}

				.tablesaw-cell-content {
					width: 55%;
				}
			}
		}
		@include mq(639) {
			border-top: 4px solid $lines;

			tr:nth-of-type(even) {
				td {
					background-color: $ngrey7;
				}
			}
		}
	}


	.top-10 {

		h3 {
			border: none;
			margin: 0;
			padding: 0;
		}
		small {
			font-family: $sans;
			@include fs(15,27,400,0.2);
			color: $granite;
		}
		.as-of {
			@include mq(767) {
				text-align: right;
			}
		}
		.xls-download {
			text-align: right;
			@include fs(12,20,700,1);
			text-transform: uppercase;
			font-family: $sans-c;

			@include mq(767) {
				text-align: left;
				margin-top: 15px;
			}

			span {
				margin-right: 5px;
			}
		}
	}
	.top-10-wrap {
		overflow: auto;
		margin-bottom: 60px;

		.add-text {
			font-family: $sans-c;
			color: $ngrey2;
			@include fs(15,20,400,0.2);
			padding: 5px;
		}
	}
	#top-ten {
		width: 100%;
		margin-top: 10px;

		th {
			text-transform: uppercase;
			font-family: $sans-c;
			@include fs(13,20,700,0.2);
			color: $ngrey1;
			text-align: left;
			border-bottom: 1px solid $lines;
			border-top: 4px solid $lines;
			padding: 16px 5px 14px;
		}
		td {
			font-family: $sans-c;
			@include fs(15,24,400,0.2);
			border-bottom: 1px solid $lines;
			padding: 6px 5px 4px;

			&.label {
				font-weight: 700;

				@include mq(413) {
					line-height: 16px;
				}
			}
			@include mq(479) {
				.tablesaw-cell-label {
					width: 45%;
				}

				.tablesaw-cell-content {
					width: 55%;
				}
			}
		}
		tfoot {

			td {
				padding: 15px 5px;
				color: $ngrey2;
				border: none;
				@include fs(15,20,400,0.2);
			}
			.tablesaw-cell-label {
				display: none;
			}
			.tablesaw-cell-content {
				width: 100%;
			}
		}
		@include mq(639) {
			border-top: 4px solid $lines;

			tr:nth-of-type(even) {
				td {
					background-color: $ngrey7;
				}
			}
		}
	}
	.tablesaw-bar.mode-stack {
		display: none;
	}
	.vendor{
			margin-bottom: 60px;
		h3{
		  padding-bottom: 12px;
			border-bottom: 4px solid #e5e6e5;
			margin-bottom: 20px;
		}
		p {
			@include fs(15,25,400,0.2);
			// color: $ngrey2;
			font-family: $sans-c;

		}
	}

	.financier {
		margin-bottom: 60px;
		h3{
		  padding-bottom: 12px;
			border-bottom: 4px solid #e5e6e5;
			margin-bottom: 20px;
		}
		h4{
			margin-top: 70px;
			margin-bottom: 20px;
			line-height: 1;
		}
		h5{
			color: #606060;
			font-weight: bold;
			margin-top: 20px;
		}

		.header {
			margin-bottom: 15px;
		}
		p {
			@include fs(15,25,400,0.2);
			// color: $ngrey2;
			font-family: $sans-c;

		}
		.table_day{
			table-layout:fixed;
			text-align: center;
			th{
				text-align: center;
				border: 1px solid  $ngrey7;
			}
		}
		table {
			width: 100%;
			margin-top: 12px;

			border-top: 1px solid $lines;
			border-bottom: 1px solid $lines;

			th {
				text-transform: uppercase;
				font-family: $sans-c;
				@include fs(13,16,700,0.2);
				color: $ngrey1;
				text-align: left;
				padding: 16px 5px 14px;
				background-color: $ngrey7;
				width: 20%;
				vertical-align: top;

				&.label {
					@include fs(18,20,700,0.2);
					text-transform: none;
					width: 40%;
				}
			}
			td {
				font-family: $sans-c;
				@include fs(15,24,400,0.2);
				border-top: 1px solid $lines;
				padding: 6px 5px 4px;

				&.label {
					font-weight: 700;
				}
				@include mq(479) {
					.tablesaw-cell-label {
						width: 45%;
					}

					.tablesaw-cell-content {
						width: 55%;
					}
				}
			}
			tr:first-of-type td {
				border: none;
			}
		}
		.post-content {
			margin-top: 15px;
		}

	}
	.financier_basket {

		ul {
			width: 100%;
			margin-top: 12px;
			border-top: 1px solid $lines;
			border-bottom: 1px solid $lines;

			li {
				font-family: $sans-c;
				@include fs(15,24,400,0.2);
				border: none;
				line-height: 1;
				transition: all 300ms 0s ease;

				padding: 6px 5px 4px;
				margin: 14px 0;

				&::before{
					display: none;
				}

			}

		}
			.is-hide li {
				&:nth-child(n + 5) {
					// display: none;
					padding: 0 5px;
					margin: 0;
					height: 0;
					overflow: hidden;
				}
			}

	}
	.news{
			margin-bottom: 60px;
		h3{
		  padding-bottom: 12px;
			border-bottom: 4px solid #e5e6e5;
			margin-bottom: 20px;
		}
			p {
			@include fs(15,25,400,0.2);
			// color: $ngrey2;
			font-family: $sans-c;

		}

	}













}
#modal-dcal {

	.modal-dialog {
		width: 869px;

		@include mq(991) {
			width: auto;
			margin: 10px;
		}
	}
	.modal-content {
		border-radius: 0;
		padding: 20px;
	}

	.legend {
		text-transform: uppercase;
		padding: 6px 5px 4px;
		vertical-align: top;
		background-color: transparent;
		font-family: $sans-c;
		@include fs(14,20,700,0.5);

		@include mq(479) {
			font-size: 9px;
		}
		span {
			display: block;
			width: 25%;
			float: left;
		}

	}
	.year {
		color: #fff;
		background-color: $orange;
		padding: 6px 5px 4px;
		font-family: $sans-c;
		@include fs(14,20,700,0.5);
		border-bottom: 2px solid #fff;
		cursor: pointer;
		transition: background-color 0.2s ease-out;

		&:hover {
			background-color: $link-hover-color;
		}
	}
	li li {
		border-bottom: 1px solid $lines;
		padding: 6px 5px 4px;
		@include fs(15,20,400,0.2);

		@include mq(479) {
			font-size: 9px;
		}

		span {
			display: block;
			width: 25%;
			float: left;
		}
	}
	li ul {
		display: none;
	}
	li:last-of-type ul {
		display: block;
	}
	.dcal-link {
		background-color: $ngrey7;
		padding: 40px 0;
		text-align: center;
		@include fs(15,20,400,0.2);
		font-family: $sans2;
	}
}
#modal-pdc {

	.modal-dialog {
		max-width: 869px;

		@include mq(991) {
			max-width: none;
			margin: 10px;
		}
	}
	.modal-content {
		border-radius: 0;
		padding: 20px;
	}
	.col1 {
		float: left;
		width: 50%;

		@include mq(991) {
			float: none;
			width: 100%;
		}

		.pad {
			padding-right: 15px;

			h3 {
				margin: 0;
			}
		}
	}
	.col2 {
		float: right;
		width: 50%;

		@include mq(991) {
			float: none;
			width: 100%;
		}

		.pad {
			padding-left: 15px;

			@include mq(991) {
				padding: 30px 0 0;
			}

			h3 {
				margin: 0;
			}
		}
	}
	.info {
		font-family: $sans-c;
		color: $ngrey2;
		@include fs(15,20,400,0.2);

		h4 {
			font-weight: 700;
		}
		p {
			margin-bottom: 20px;
		}
		em {
			font-style: italic;
		}
	}
	.cols {
		margin: 15px 0 60px;
	}
	.table1 {
		width: 100%;

		th {
			text-transform: uppercase;
			font-family: $sans-c;
			@include fs(13,24,700,0.2);
			color: $ngrey1;
			text-align: left;
			border-bottom: 1px solid $lines;
			padding: 6px 5px 4px;
		}
		td {
			font-family: $sans-c;
			@include fs(15,24,400,0.2);
			border-bottom: 1px solid $lines;
			padding: 6px 5px 4px;

			&.label {
				font-weight: 700;
			}
		}
		.spacer {
			td {
				padding-top: 30px;
			}
		}
	}
	.button {
		@include fs(12,20,700,1);
		text-transform: uppercase;
		font-family: $sans-c;
		color: $orange;
		cursor: pointer;

		span {
			margin-right: 5px;
		}
	}
	.icon-help {
		margin-left: 0;
	}
	.popover {
		color: $marine;
	}

	.ct-bar {
		stroke: $orange;
	}
	.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
		margin-left: -4px;
	}

	.ct-chart {
    	position: relative;
	    clear: both;
	}
	.hover-note {
	    float: left;
	}
	.date-dropdown {
    	float: right;
	}

	.chartist-tooltip {
    	position: absolute;
    	display: inline-block;
    	opacity: 0;
    	min-width: 5em;
    	padding: .5em;
    	background: rgba(255,84,0,.95);
    	color: #fff;
    	font-weight: 300;
    	font-size: 15px;
    	text-align: center;
    	pointer-events: none;
    	z-index: 1;
    	transition: opacity .2s linear;
	}
	.chartist-tooltip:before {
    	content: "";
		position: absolute;
    	top: 100%;
    	left: 50%;
    	width: 0;
    	height: 0;
    	margin-left: -15px;
    	border: 15px solid transparent;
    	border-top-color: rgba(255,84,0,.95);
	}
	.tooltip-show {
		opacity: 1;
	}
}






/* FAMILY
---------------------------------------------- */

.family-hero {
	// background:transparent url('/images/funds-hero.png') top center no-repeat;
	background-size: cover;
	height: 476px;

	img {
		width: 102px;
		height: 105px;
		float: left;
		margin-right: 40px;
		display: block;

		@include mq(639) {
			float: none;
		}
	}
	h1 {
		margin-top: 15px;

		@include mq(991) {
			margin-top: 28px;
		}
	}
}
#family {

	.fam {
		margin-bottom: 60px;

		h2 {
			@include fs(24,30,400,0.5);
			font-family: $sans2;
			margin-bottom: 24px;
			color: $marine;
		}
		p {
			@include fs(16,30,400);
			font-family: $sans2;
			margin-bottom: 24px;
			color: $marine;
		}
		.fam-links {
			border-left: 1px solid $lines;
			max-width: 300px;

			@include mq(767) {
				max-width: none;
			}
		}
		.fam-link {
			width: 50%;
			float: left;
			text-align: center;
			border-top: 1px solid $lines;
			border-right: 1px solid $lines;
			border-bottom: 1px solid $lines;

			&:nth-of-type(3),
			&:nth-of-type(4) {
				border-top: none;

				@include mq(767) {
					border-top: 1px solid $lines;
				}
				@include mq(599) {
					border-top: none;
				}
			}
			@include mq(767) {
				width: 25%;
			}
			@include mq(599) {
				width: 50%;
			}
			&:hover,
			&.active {
				background-color: $ngrey7;
				color: $orange;
			}
			img {
				display: block;
				width: 100%;
			}
			h3 {
				@include fs(15,33,400,0.5);
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom: 15px;
			}
		}
	}
	.fam-group {

		h2 {
			@include hhh4;
			text-transform: uppercase;
			margin-bottom: 25px;
			color: $marine;
		}
		h3 {
			font-family: $sans-c;
			@include fs(20,27,700,0.2);
			color: $marine;
			margin-bottom: 20px;
		}
		h4 {
			background-color: #fff; // background to cover up the <b>NEW!</b>
			display: inline-block;
			@include fs(15,33,400,0.5);
			position: relative;
			z-index: 2;
			padding-right: 5px;

			@include mq(413) {
				@include fs(13,30,400,0.5);
			}
		}
		p {
			margin-bottom: 40px;
		}
		ul {
			border-top: 4px solid $lines;
			max-width: 400px;
		}
		li {
			font-family: $sans-c;
			border-bottom: 1px solid $lines;

			a {
				display: block;
				padding: 2px 0;
				@include fs(15,33,400,0.5);
				position: relative;
			}
			span {
				font-weight: 700;
				width: 68px;
				display: inline-block;
				padding-left: 4px;
			}
			b {
				display: inline-block;
				position: absolute;
				top: 2px;
				right: 6%;
				font-weight: 700;
				font-size: 12px;
				color: $ngrey2;
			}
		}
	}
}


.download-btn{
	width: 320px;
	margin: 40px 0 0 auto;
	border: 1px solid#606060;
	@include mq(599){
		width: 100%;
	}

	a{
		position: relative;
		display: block;
		padding: 10px 10px 10px 0;

		font-size: 16px;
		text-align: center;
		@include mq(599){
			font-size: 14px;
		}

		.icon-down {
			position: absolute;
			right: 24px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 18px;
			@include mq(599){
				font-size: 16px;
			}
		}
	}

	&--en{
		font-size: 14px !important;
	}
}
