@import '../../swiper-vars.scss';

:root {
  --swiper-navigation-size: 30px;
  --swiper-navigation-color: #ffffff;
}

$navigation-size:30px;

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  // width: (30 / 44 * 27) * 1px ;
  // width: calc($navigation-size / 44 * 27);
  width: 30px;
  height: 30px;
  height: $navigation-size;
  margin-top: calc(-1 * 30px / 2);
  margin-top: calc(-1 * $navigation-size / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  display: none;
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &:after {
    // font-family: swiper-icons;
    // font-size:  30px;
    // font-size: $navigation-size;
    text-transform: none !important;
    letter-spacing: 0;
    // text-transform: none;
    // font-variant: initial;
    line-height: 1;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    width: 30px;
    height: 30px;
    content: '';
    position: absolute;
  }
  // &::before {
  //   text-transform: none !important;
  //   letter-spacing: 0;
  //   line-height: 1;
  //   border-bottom: 4px solid rgba($color: #000000, $alpha: .3);
  //   border-left: 4px solid rgba($color: #000000, $alpha: .3);
  //   width: 30px;
  //   height: 30px;
  //   content: '';
  //   position: absolute;
  //   filter: blur(3px);
  //   }
  &:focus {
    outline: none;
  }
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  // &:before{
  //   transform: rotate(45deg);
  //   left: -2px;
  // }
  &:after {
    transform: rotate(45deg);
  }
  // left: 20px;
  left: -10px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  // &:before {
  //   right: -1px;
  //   transform: rotate(-135deg);
  // }
  &:after {
    transform: rotate(-135deg);
  }
  right: 20px;
  left: auto;
}

@each $navColorName, $navColorValue in $colors {
  .swiper-button-prev,
  .swiper-button-next {
    &.swiper-button-#{$navColorName} {
      --swiper-navigation-color: #{$navColorValue};
    }
  }
}
.swiper-button-lock {
  display: none;
}
