.p-topTitle{
  text-align: center;
  color: #fff;
  // @include fs(34);
  @include fs(55);
  font-weight: normal;
  font-family: Brown-Regular,sans-serif;
  padding-bottom: 80px;
  @include sp() {
    // @include vw(64);
    @include vw(59);
    padding-bottom: 80/750*100vw;

  }

}
