/* ABOUT
---------------------------------------------- */

.about-main {
	@include fs(34,46);
	text-align: center;
	font-family: $sans2;
	color: $marine;

	@include mq(991) {
		@include fs(30,42);
	}

	@include mq(h700) {
		padding: 70px 0;
		@include fs(30,42);
	}

	@include mq(575) {
		@include fs(24,32);
	}
}
.pillars {
	background-color: $ngrey7;
	text-align: center;
	padding: 40px 0;
	margin-bottom: 80px;

	@include mq(991) {
		display: none;
	}

	h2 {
		font-family: $sans2;
		@include fs(22,27,400,0.5);
		text-transform: uppercase;
		color: $marine;
		text-align: center;
		padding: 10px 30px;
		margin-bottom: 60px;
	}
	a {
		color: $marine;
		position: relative;
		display: block;

		&:hover {
			color: $orange;

			span {
				opacity: 1;
			}
		}
	}
	.line {
		border-right: 2px solid $lines; 
	}
	img {
		max-width: 200px;
		margin-bottom: 12px;
	}
	p {
		font-family: $sans2;
		@include fs(16,20,400);
	}
}
.pillar {
	text-align: center;
	margin-bottom: 80px;

	img {
		max-width: 100px;
		margin-bottom: 12px;
	}
	h3 {
		font-family: $sans2;
		@include fs(28,34,400);
		color: $marine;
		margin-bottom: 20px;

		br {
			display: none;
		}
	}
}
.next-pillar {
	text-align: center;
	margin: -50px 0 60px;

	button {
		font-size: 30px;
		line-height: 1;
		font-family: $sans2;
		background-color: transparent;
		border: 1px solid $lines; 
		padding: 5px 34px 7px;
		margin-top: 8px;
		transition: color 0.2s ease-out;
		color: $link-color;			

		&:hover {
			color: $link-hover-color;			
		}		
	}	
}
.abt-logos {
	padding: 80px 0;

	img {
		margin: 40px 0;
	}
	.gxlogo {
		fill: $orange;
	}
}

