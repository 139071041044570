.p-conPhil{

  padding: 0 0 80px;
  &__block{
    padding: 24px 0 20px;
    p+p{
      margin-top: 15px;
    }

  }


}
