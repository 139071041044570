.p-topExGpHead{
  display: flex;
  align-items:center;
  max-width: 880px;
  margin: auto;
  padding-bottom: 100px;
  
  @include sp() {
    display: flex;
    justify-content:center;
    flex-direction: column;
    padding-bottom: 100/750*100vw;
    
  }
  &__icon{
    width: 30%;
    max-width: 200px;
    flex-shrink: 0;
    img{
      display: block;
    }
    @include sp() {
      padding-bottom: 50/750*100vw;
      
    }
  }
  &__text{
  
    flex-grow: 1;
    p{
      @include fs(17);
      @include sp() {
        @include vw(34);
        
      }
    }
  }


}
