.pickup-page-banner-container {
  position: fixed;
  right: 20px;
  bottom: 10px;
  z-index: 98;
  display: block;
  opacity: 0;
  .close-layout {
    padding: 0;
    background: #fafafa;
    border: #aaa 2px solid;
    visibility: visible;
    position: absolute;
    top: -15px;
    right: -15px;
    border-radius: 50%;
    z-index: 1;
  }
  .close-layout > svg {
    fill: #aaa;
    .circle {
      border-radius: 50%;
    }
  }
  .splide__slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .slide-content[data-toggle="modal"] {
    cursor: pointer;
    pointer-events: auto;
  }

  .splide__arrows {
    opacity: 0;
    transition: opacity 0.3s;
  }
  .splide:hover .splide__arrows {
    opacity: 1;
  }
  .splide__arrow--prev {
    left: 0.3rem;
  }
  .splide__arrow--next {
    right: 0.3rem;
  }
  .shadow {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.35));
    transition: filter 0.3s;
    &:hover {
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.85));
    }
  }
}

.pickup-page-modal-container {
  .modal-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    overflow: auto;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-body {
      position: relative;
      max-width: 1024px;
      max-height: 680px;
      width: 100%;
      .modal-content {
        background: #ff5400;
        border-radius: 8px;
        text-align: center;
        padding: 35px;
        font-weight: bold;
        max-width: 924px;
        max-height: 680px;
        overflow-y: hidden;
        margin: auto;

        height: 80vh;
        @include mq(767) {
          padding: 16px;
        }
        .modal-header {
          text-align: center;
          padding: 0px 0px 25px;
          border: #ff5400;
          @include mq(767) {
            padding: 0px 0px 16px;
          }
          h3 {
            color: #ffffff;
            width: 100%;
            text-align: center;
            font-size: 35px;
            .bold-text {
              font-size: 40px;
            }
            @include mq(767) {
              font-size: 18px;
              .bold-text {
                font-size: 21px;
              }
            }
          }
        }

        .link-list {
          background: #fff;
          box-shadow: 4px 4px 10px 6px rgba(0, 0, 0, 0.25);
          color: #002f37;
          font-size: 25px;
          display: grid;
          gap: 20px 20px;
          padding: 18px 16px 18px 16px;
          min-height: 99px;
          line-height: 1.2;
          grid-template-columns: repeat(2, 1fr);

          background-color: #ffffff;
          border-radius: 8px;
          overflow: hidden;
          overflow-y: auto;
          margin-bottom: 1rem;

          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar {
            background: #e5e6e5;
            border-radius: 100px;
            width: 14px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #8e8e8e;
            border: 2px solid transparent;
            border-radius: 100px;
            background-clip: padding-box;
          }

          a {
            color: black;
            border-radius: 10px;
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            text-align: left;
            box-shadow: 4px 4px 10px 6px rgba(0, 0, 0, 0.25);
            font-size: 25px;
            font-weight: bold;
            gap: 15px;
            padding: 18px 16px 18px 33px;
            line-height: 1.2;
            @include mq(767) {
              font-size: 17px;
              padding: 9px 8px 9px 16px;
            }
          }
          @include mq(767) {
            grid-template-columns: auto;
          }
          img {
            width: 100%;
            @include mq(767) {
              width: 50px;
            }
          }
        }
      }
      .close {
        pointer-events: auto;
      }
    }
  }
}
