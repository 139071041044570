.p-topVideo{
  display: flex;
  justify-content: space-between;
  padding: 40px 0 100px;
  padding:0 0 100px;
  flex-wrap:wrap;
  &::after {
    content:"";
    display: inline-block;
    width: 30%;
    height: 1px;
  }
  
  @include sp() {
    flex-direction: column;
    
  }
  
  &__item{
    width: 30%;
    padding-top: 40px;
    @include sp() {
      width: 100%;
      
    }
    .card{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
    .th{
      background-color: #002f37;
      display: block;
      flex-shrink: 0;
    }
    .textwrap{
      padding: 20px ;
      flex-grow: 1;
      background: #fff;

    }

    .cats{
      padding: 10px 0;
      line-height: 1;
    }
    .title{
    
      a{
        color: #002f37;
        word-wrap: break-word;
      }
    
    }
  }
  
  &__imgWrap{
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    
    img{
    display: block;
    width: 100%;

    }
    &::after {
      content:"";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 70px;
      height: 70px;
      // background: #fff;
      background:rgba($color: #ffff, $alpha: .8) url(/images/play.svg) no-repeat calc(50%  + 3px) 50% ;
      background-size: 24px 24px;
      border-radius: 50%;
      transition: all .2s ease-in-out;

      a:hover &{
          transform: translate(-50%,-50%) scale(1.1);
      }
    }
    
  }

}
