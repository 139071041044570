.p-topExGpTableWrap {

  &--japan{
    padding-bottom: 80px;
    &.is-jpETF_tab{
      padding-bottom: 0;
    }
    #fund-list &{
      padding-bottom: 0;
    }
  }

  // @include mq(1239) {
  //   overflow-x: scroll;
  // }
}
.p-topExGpTable {
  width: 100%;
  line-height: 1;
  @include sp() {
    width: 900px;
  }
  @include mq(767) {
    font-size: 11px;
    line-height: 15px;
  }
  &--dm{
    @include sp() {
      width: 900px;
    }
  }
  thead {
    border-bottom: 2px solid #e5e6e5;
    th {
      text-align: left;
      color: $orange;
      padding: 10px;
      // @include fs(16);
      // @include sp() {
      //   @include vw(32);
      // }
      &.p-topExGpTable__pdf {
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e5e6e5;
    }
    th {
      text-align: left;
      padding: 10px;

      @include mq(767) {
        font-size: 11px;
        line-height: 15px;
      }
    }
    td {
      padding: 10px;


		@include mq(767) {
			font-size: 11px;
			line-height: 15px;
		}

    }
  }

  &__name {
    color: #002f37;
    // @include fs(16);
    // @include sp() {
    //   @include vw(32);
    // }
    a {
      color: #002f37;
      // &:hover{
      //   text-decoration: underline;
      // }
    }
  }
  &__navps {
    // @include fs(12);
    font-weight: bold;
    // @include sp() {
    //   @include vw(32);
    // }
  }
  &__change {
    // @include fs(12);
    font-weight: bold;
    // @include sp() {
    //   @include vw(32);
    // }
  }
  &__pdf {
    text-align: center;
    // @include fs(12);
    font-weight: bold;
    // @include sp() {
    //   @include vw(32);
    // }
  }
  &__date {
    // @include fs(12);
    font-weight: bold;
    // @include sp() {
    //   @include vw(32);
    // }
  }
}
