.p-conInvi{
  padding: 0 0 100px;
  &__block{
    padding: 24px 0 0;
    p+p{
      margin-top: 15px;
    }

  }

}
