.p-topBnr {
  display: block;
  margin: auto;
  text-align: center;
  img {
    display: block;
    margin: auto;
  }
  a {
    display: block;
    transition: all 300ms 0s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  .swiper-slide {
    // background: $ngrey5;
  }
  .swiper-container {
    padding-bottom: 58px;
  }
  /* Common Styles */
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 20px;
    left: 0;
    width: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    margin-top: calc((-1 * 30px / 2) - 24px);
  }
}
