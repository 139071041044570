
.container-fluid .p-schMf {
  // body.theme-search {
  .str-content {
    display: block;
    /* for ie11 */
  }

  .sct-search > .lyt-outer > .lyt-inner {
    max-width: 1020px;
  }

  .mf_finder_searchBox {
    // display: flex;
    width: 820px;
    // border: 1px solid #000;
    &::before {
      content: "キーワード";
      display: block;
      // flex: 0 1 180px;
      @include fs(22);
      // font-weight: bold;
      padding: 10px 10px 0 0;
      color: #002f37;
      font-weight: 600;

      :lang(en) &,
      :lang(en-US) & {
        content: "Keyword";
      }
    }

    // .mf_finder_searchBox_form {
    //   flex: 0 1 820px;
    // }

    .mf_finder_searchBox_items {
      height: 50px;
      padding: 0;
    }

    .mf_finder_searchBox_query_wrap {
      display: block;
      // border: 2px solid #0183cf;
      border: 1px solid #707070;
      border-right: none;
      // border-radius: 5px 0 0 5px;
      flex: 1 1 auto;
      overflow: hidden;

      .mf_finder_searchBox_query_input {
        display: block;
        border: 0;
        padding: 0 15px;
        @include fs(18);
        @include fs(18);
        height: 100%;
        width: 100%;
      }
    }

    .mf_finder_searchBox_submit {
      flex: 0 0 100px;
      // border-radius: 0 5px 5px 0;
      border-radius: 0;
      overflow: hidden;
      // background-color: #0183cf;
      background-color: #ff5400;

      > span {
        display: block;
        border: 0;
        color: #fff;
        @include fs(18);
        width: 100%;
        margin: 0;
      }

      &::before {
        display: none;
      }
    }

    .mf_finder_searchBox_selects {
      display: flex;
      margin-top: 37px;
      padding-top: 1.6em;
    }

    .mf_finder_searchBox_misc {
      display: inline-flex;
      width: 100%;
      overflow: visible;
    }

    .mf_finder_searchBox_category,
    .mf_finder_searchBox_misc label.mf_finder_searchBox_sort,
    .mf_finder_searchBox_pagemax {
      @include fs(14);
      font-weight: bold;

      display: flex;

      flex-direction: column;
      max-width: 240px;
      width: 100%;
      margin: 0 0 0 20px;
      position: relative;
    }

    .mf_finder_searchBox_category::before,
    .mf_finder_searchBox_misc label.mf_finder_searchBox_sort::before,
    .mf_finder_searchBox_pagemax::before {
      @include fs(16);
      // font-weight: bold;
      // font-weight: normal;
      font-weight: 600;

      color: #002f37;
      display: block;
      width: 100%;
      margin: 0;
      // font-family: "メイリオ", "Meiryo", "游ゴシック Medium", "YuGothic", "MyYuGothicM", "Yu Gothic", sans-serif;
      position: absolute;
      left: 0;
      top: -1.8em;
    }

    .mf_finder_searchBox_category::after,
    .mf_finder_searchBox_misc label.mf_finder_searchBox_sort::after,
    .mf_finder_searchBox_pagemax::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      // width: 0;
      // height: 0;
      // border-top: 11px solid #999;
      // border-right: 7px solid transparent;
      // border-bottom: 11px solid transparent;
      // border-left: 7px solid transparent;
      z-index: 10;
      content: "↓";
      pointer-events: none;
      @include fs(16);
      color: #ff5400;
    }

    .mf_finder_searchBox_category {
      max-width: 300px;
      margin-left: 0;
      &::before {
        content: "カテゴリー";

        :lang(en) &,
        :lang(en-US) & {
          content: "Category";
        }
      }
    }

    .mf_finder_searchBox_sort::before {
      content: "並べ替え";

      :lang(en) &,
      :lang(en-US) & {
        content: "Sort";
      }
    }

    .mf_finder_searchBox_pagemax::before {
      content: "表示件数";

      :lang(en) &,
      :lang(en-US) & {
        content: "Displayed results";
      }
    }

    .mf_finder_searchBox_category_select,
    .mf_finder_searchBox_sort_select,
    .mf_finder_searchBox_pagemax_select {
      display: block;
      // border-radius: 4px;
      border-radius: 0;
      background-color: #fff;
      border: solid 1px #707070;
      width: 100%;
      padding: 7px 34px 7px 15px;
      @include fs(14);

      line-height: inherit;
    }
  }

  .mf_finder_relatedkeywords {
    margin-top: 35px;
    padding: 0;

    display: flex;
    background-color: transparent;

    > {
      .mf_finder_relatedkeywords_head {
        flex: 0 1 180px;
        padding: 10px 10px 0 0;
        z-index: 1;

        &::before {
          content: "関連検索ワード";
          @include fs(18);
          font-weight: bold;
        }
      }

      .mf_finder_relatedkeywords_items {
        flex: 0 1 820px;

        display: flex;

        flex-wrap: wrap;
        margin-top: -5px;
        margin-left: -0.5%;

        > li {
          margin: 5px 0 0 0.5%;

          > a {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            text-decoration: none;
            padding: 3px 13px;

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              border: 1px solid #ccc;
              border-radius: 100px;
              background-color: #fff;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            }

            > span {
              color: #333;
              position: relative;
              z-index: 10;
              display: block;
              height: 100%;
              @include fs(12);

              &::before {
                display: none;
              }
            }

            &:hover {
              background-color: transparent;

              &::before {
                border-width: 2px;
                background-color: #f6f6f6;
              }
            }
          }
        }
      }
    }
  }

  .str-results-container {
    // display: flex;

    // flex-direction: row-reverse;

    // justify-content: flex-end;
    margin: auto;
    // max-width: 1020px;
    // border: 1px solid #000;

    // .refine-search-container {
    //   // flex: 0 0 270px;
    //   flex-shrink: 0;

    //   .mf_finder_drilldown {
    //     padding: 85px 10px 100px 18px;
    //   }

    //   .mf_finder_drilldown_head {
    //     border-top: 4px #01aac6 solid;
    //     color: #01aac6;

    //     @include fs(21);
    //     padding: 25px 0;
    //     text-align: center;
    //     position: static;

    //     &::before {
    //       content: "コンテンツ分類から探す";
    //       @include fs(21);
    //     }
    //   }

    //   .mf_finder_drilldown_items {
    //     border-top: 1px solid #ececec;
    //     margin: 0;

    //     li {
    //       border-bottom: 1px solid #ececec;
    //       padding: 0;

    //       .mf_finder_drilldown_item_depth_2 {
    //         border-bottom: none;

    //         .mf_finder_drilldown_item_bullet::before {
    //           height: 8px;
    //           top: calc(10px + 0.4em);
    //           width: 8px;
    //         }

    //         .mf_finder_drilldown_item_link {
    //           padding: 10px 10px 10px 0;

    //           .mf_finder_drilldown_item_count {
    //             @include fs(14);
    //           }
    //         }
    //       }

    //       div {
    //         display: flex;
    //         overflow: hidden;
    //       }

    //       .mf_finder_drilldown_item_bullet {
    //         display: block;
    //         min-width: 30px;
    //         position: relative;
    //         z-index: 2;

    //         &::before {
    //           border-right: 2px #01aac6 solid;
    //           border-top: 2px #01aac6 solid;
    //           content: "";
    //           display: block;
    //           left: 5px;
    //           position: absolute;
    //           transform: rotate(45deg);
    //           height: 11px;
    //           top: calc(15px + 0.35em);
    //           width: 11px;
    //         }
    //       }

    //       &.mf_finder_drilldown_item_selected > div {
    //         background-color: #e5f6f9;

    //         .mf_finder_drilldown_item_bullet::before {
    //           border: none;
    //           background-image: linear-gradient(#0183cf, #01cfbe);
    //           height: 100%;
    //           left: 0;
    //           transform: none;
    //           top: 0;
    //           width: 4px;
    //         }

    //         .mf_finder_drilldown_item_link {
    //           background-color: transparent;
    //         }
    //       }

    //       .mf_finder_drilldown_item_link {
    //         color: #333;
    //         display: block;
    //         @include fs(16);
    //         padding: 15px 10px 15px 0;
    //         position: relative;
    //         text-decoration: none;
    //         width: 100%;
    //         z-index: 1;

    //         span {
    //           z-index: 2;
    //         }

    //         &::after {
    //           content: "";
    //           display: block;
    //           width: calc(100% + 30px);
    //           height: 100%;
    //           background-color: transparent;
    //           position: absolute;
    //           top: 0;
    //           right: 0;
    //           z-index: -1;
    //         }

    //         &:hover::after {
    //           background-color: #e5f6f9;
    //         }

    //         .mf_finder_drilldown_item_title {
    //           font-weight: normal;
    //         }

    //         .mf_finder_drilldown_item_count {
    //           background-color: transparent;
    //           color: #333;
    //           @include fs(16);
    //           margin: 0;
    //           padding: 0;

    //           &::before {
    //             content: "（";
    //           }

    //           &::after {
    //             content: "）";
    //           }
    //         }
    //       }

    //       > .mf_finder_drilldown_item_children {
    //         margin: 0 0 0 20px;

    //         .mf_finder_drilldown_item_link {
    //           @include fs(14);
    //         }
    //       }
    //     }
    //   }

    //   .mf_finder_drilldown_reset .router-link-active {
    //     border-radius: 100px;
    //     border: 1px solid #ccc;
    //     background-color: #fff;
    //     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    //     @include fs(14);
    //     display: inline-block;
    //     margin-top: 10px;

    //     &:hover {
    //       background-color: #f6f6f6;
    //     }
    //   }
    // }

    .results_container {
      // flex: 0 1 750px;
      flex-grow: 1;
    }

    .mf_finder_msk_wrapper {
      padding: 55px 0 0 0;

      + .mf_finder_organic_header_wrapper {
        padding-top: 0;
      }
    }

    .mf_finder_msk {
      border-right: 2px solid #ececec;
      margin: 0;
      padding: 30px 40px 10px 0;
      text-align: right;
    }

    .mf_finder_organic_header_wrapper {
      &.mf_finder_organic_zerohit + .mf_finder_organic_docs_wrapper {
        display: none;
      }

      padding: 55px 0 0 0;
      display: block;
      margin: 0;
    }

    .mf_finder_organic_header {
      // border-right: 2px solid #ececec;
      padding: 30px 40px 0 0;

      flex-direction: row;
      align-items: flex-start;
      // align-items: flex-end;
      // text-align: left;
      // display: block;
    }

    .mf_finder_organic_docs_wrapper {
      // border-right: 2px solid #ececec;
      padding: 0 40px 0 10px;
    }

    // 「○○○」の検索結果
    .mf_finder_query,
    .mf_finder_organic_range_from,
    .mf_finder_organic_range_to,
    .mf_finder_organic_total {
      @include fs(15);
    }

    .mf_finder_query > span,
    .mf_finder_organic_range_from > span,
    .mf_finder_organic_range_to > span,
    .mf_finder_organic_total > span {
      @include fs(15);
      font-weight: 600;
    }

    .mf_finder_query {
      &::before,
      &::after {
        @include fs(15);
        font-weight: 600;
      }
      :lang(en) &,
      :lang(en-US) & {
        &::before {
          content: "「";
        }
        &::after {
          content: "」Search";
        }
      }
    }

    // 1 - 10件 / 00件中
    .mf_finder_organic_nums {
      align-items: center;
      flex-direction: row;
      order: 2;
      padding-left: 10px;
      :lang(en) &,
      :lang(en-US) & {
        padding-left: 0;
      }
    }

    .mf_finder_organic_range_from {
      &::before,
      &::after {
        @include fs(15);
        font-weight: 600;
      }
      &::before {
        :lang(en) &,
        :lang(en-US) & {
          padding-right: 10px;
        }
      }
    }

    .mf_finder_organic_range_to {
      &::before,
      &::after {
        @include fs(15);
        font-weight: 600;
      }
    }

    .mf_finder_organic_total {
      &::before,
      &::after {
        @include fs(15);
        font-weight: 600;
      }

      > span {
        @include fs(15);
        font-weight: 600;
      }

      &::before {
        content: "/";
      }

      &::after {
        content: "件中";
        :lang(en) &,
        :lang(en-US) & {
          content: "";
        }
      }
    }

    .mf_finder_organic_range_from {
      @include fs(15);
      font-weight: 600;

      &::after {
        content: "-";
        @include fs(15);
        font-weight: 600;
      }
    }

    .mf_finder_organic_range_to {
      @include fs(15);
      font-weight: 600;

      &::after {
        content: "件";
        @include fs(15);
        font-weight: 600;
        :lang(en) &,
        :lang(en-US) & {
          content: "";
        }
      }
    }

    .mf_finder_organic_docs {
      margin: 0;
      padding-top: 20px;
    }

    .mf_finder_organic_doc {
      // padding: 24px 18px 18px;
      padding: 24px 0 40px;
      position: relative;
      border-bottom: 4px solid #e5e6e5;
    }
    .mf_finder_organic_docs > div:first-child {
      border-top: 4px solid #e5e6e5;
    }

    .mf_finder_organic_doc_img_wrapper {
      max-width: 150px;
      padding-top: 20px;

      .mf_finder_organic_doc_img {
        position: static;
        min-width: 150px;

        // &::before {
        //   content: "";
        //   display: block;
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        // }

        // &::after {
        //   content: "";
        //   display: block;
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   z-index: -1;
        // }

        &:hover::after {
          // background-color: #e5f6f9;
          // background-color: #fcfcfc;
        }

        > img {
          // border-radius: 5px;
          // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
          max-width: none;
          max-height: none;
          width: 100%;
          display: block;
        }
      }

      .mf_finder_organic_doc_zoom {
        display: none;
      }
    }

    .mf_finder_organic_doc_contents_wrapper {
      // max-width: (550 + 40) * 1px;
      margin-left: 0;
      padding-left: 40px;

      .mf_finder_organic_doc_title_wrapper {
        word-break: break-word;
        color: #ff5400;
        &:visited {
          // color: #3d81a8;
          color: #ff5400;
        }

        > img {
          display: block;
        }

        .mf_finder_organic_doc_title {
          @include fs(18);
          display: block;
          margin-top: 15px;
          // text-decoration: underline;
        }
      }

      .mf_finder_organic_doc_body {
        @include fs(14);
        // overflow: visible;
        overflow: hidden;
        height: auto;
      }
    }

    .mf_finder_organic_doc_url_wrapper {
      word-break: break-word;
      margin-top: 10px;

      .mf_finder_organic_doc_url {
        // color: #005180;
        color: #ff5400;
        @media (max-width: 767px) {
          padding-left: 34%;
        }

        @include fs(14);
        // text-decoration: underline;

        &::before {
          display: none;
        }
      }

      &:visited .mf_finder_organic_doc_url {
        // color: #3d81a8;
        color: #ff5400;
      }
    }

    .mf_finder_mark {
      // background-color: #e5f6f9;
      background-color: #ff0;
    }

    .mf_finder_organic_pager_wrapper {
      margin-top: 30px;
    }

    .mf_finder_pager_items {
      align-items: center;

      > li {
        margin: 0 3px;
        padding: 0;

        > a {
          // color: #005180;
          color: #ff5400;

          background-color: transparent;
          display: block;
          padding: 0;

          &:hover span::after {
            background-color: #f6f6f6;
          }
        }

        span {
          display: flex;
          justify-content: center;

          align-items: center;
          width: 40px;
          height: 40px;
          text-decoration: none;
          position: relative;

          &::after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            // border-radius: 6px;
            // border: 2px solid #ececec;
            background-color: #fff;
            z-index: -1;
          }
        }
      }

      .mf_finder_pager_item_current span {
        // color: #fff;

        &::after {
          // border-color: transparent;
          // background-color: #0183cf;
          width: 80%;
          left: 0;
          right: 0;
          display: block;
          margin: 0 auto;
          border: none;
          border-bottom: 2px solid #707070;
        }
      }

      .mf_finder_pager_item_prev > a,
      .mf_finder_pager_item_next > a {
        // color: #01aac6;
        color: #ff5400;
      }

      .mf_finder_pager_item_prev > a:hover span::after,
      .mf_finder_pager_item_next > a:hover span::after {
        background-color: transparent;
        // text-decoration: underline;
      }

      .mf_finder_pager_item_prev span,
      .mf_finder_pager_item_next span {
        width: auto;
        height: auto;
      }

      .mf_finder_pager_item_prev span::before,
      .mf_finder_pager_item_next span::before {
        display: inline-block;
        width: 14px;
        height: 10px;
        content: "";
        z-index: 10;
      }

      .mf_finder_pager_item_prev span::after,
      .mf_finder_pager_item_next span::after {
        // color: #005180;
        content: none;
        position: static;
        border: none;
        display: inline-block;
      }

      .mf_finder_pager_item_prev {
        margin-right: 14px;

        span {
          position: relative;
          border: 1px solid #ff5400;
          width: 24px;
          height: 24px;
          &::before {
            // border-top: 2px solid #01aac6;
            // border-left: 2px solid #01aac6;
            // transform: rotate(-45deg);
            display: none;
          }

          &::after {
            content: "←";
            color: #ff5400;
            position: absolute;
            display: block;
            line-height: 22px;
            z-index: 1;
          }
        }
        a:hover span {
          background: #ff5400;
          &::after {
            color: #fff;
          }
        }
      }

      .mf_finder_pager_item_next {
        margin-left: 14px;

        span {
          position: relative;
          border: 1px solid #ff5400;
          width: 24px;
          height: 24px;
          display: block;

          &::before {
            // border-top: 2px solid #01aac6;
            // border-right: 2px solid #01aac6;
            // transform: rotate(45deg);
            // border: 1px solid #000;

            // display: block;
            // position: absolute;
            // top: 0;
            // left: 0;
            // content: "";
            // width: 100%;
            // height: 100%;
            // border-radius: 6px;
            // border: 2px solid #ececec;
            // background-color: transparent;
            // z-index: -1;
            display: none;
          }

          &::after {
            content: "→";
            position: absolute;
            display: block;
            line-height: 22px;
            @include fs(16);
            z-index: 1;
          }
        }

        a:hover span {
          background: #ff5400;
          &::after {
            color: #fff;
          }
        }
      }
    }

    .mf_finder_logo {
      display: none;
    }
  }

  .str-content {
    &:nth-last-of-type(2) {
      padding-bottom: 0;
    }

    &.of-column {
      max-width: 1020px;
    }
  }

  .search-word-container {
    display: flex;

    + .search-word-container {
      margin-top: 35px;
    }

    > .word-title {
      flex: 0 1 180px;
      @include fs(18);
      font-weight: bold;
      padding: 10px 10px 0 0;
    }

    .search-word-contents {
      flex: 0 1 820px;

      .condition {
        display: inline-flex;
        width: 100%;
        margin-top: 15px;

        > .condition-item {
          @include fs(14);
          font-weight: bold;

          display: flex;

          flex-direction: column;
          max-width: 240px;
          width: 100%;

          select {
            width: 100%;
          }

          + .condition-item {
            margin-left: 20px;
          }

          &._category {
            max-width: 300px;
          }
        }

        .mod-form-select {
          margin-top: 5px;
        }
      }
    }
  }

  .search-word-contents {
    .mod-form-comp {
      margin-top: 0;
    }

    .mod-form-select select {
      min-width: auto;
    }
  }

  .unq-result-set {
    text-align: right;

    .if-word {
      @include fs(14);

      .keyword {
        @include fs(16);
      }

      + .keyword-result {
        margin-top: 10px;
      }
    }

    .keyword-result .keyword {
      @include fs(18);
      font-weight: bold;
    }

    .number-result {
      .number {
        @include fs(18);
        font-weight: bold;
      }

      .whole-number {
        @include fs(24);
        font-weight: bold;
      }
    }
  }

  .search-result-list {
    padding-top: 20px;

    .result {
      border-top: 1px solid #ececec;

      &:last-child {
        border-bottom: 1px solid #ececec;
      }

      .link {
        display: block;
        padding: 24px 18px 18px;
        text-decoration: none;

        &:hover {
          background-color: #e5f6f9;
        }
      }
    }
  }

  .unq-text-around {
    display: flex;

    .content {
      max-width: 510px;
      padding-left: 20px;

      .title {
        @include fs(18);
        display: block;
        margin-top: 15px;
        text-decoration: underline;
      }

      .mod-txt {
        margin-top: 10px;

        .text {
          color: #333;
        }
      }

      .url {
        display: block;
        margin-top: 10px;
        text-decoration: underline;
      }
    }

    .image {
      min-width: 150px;

      > :first-child {
        margin-top: 0;
      }

      .mod-media-fluid > img {
        // border-radius: 5px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
  // }

  @media (max-width: 767px) {
    // body.theme-search .mf_finder_searchBox {
    .mf_finder_searchBox {
      display: block;
      margin: 0;
      max-width: 100%;

      &::before {
        @include fs(16);
        padding: 0 0 5px;
      }

      .mf_finder_searchBox_items {
        height: 40px;
      }

      .mf_finder_searchBox_submit {
        flex: 0 0 70px;

        > span {
          @include fs(12);
        }
      }

      .mf_finder_searchBox_selects,
      .mf_finder_searchBox_misc {
        display: block;
      }

      .mf_finder_searchBox_selects {
        margin-top: 9px;
      }

      .mf_finder_searchBox_misc label.mf_finder_searchBox_sort,
      .mf_finder_searchBox_pagemax {
        margin: 2.2em 0 0;
      }

      .mf_finder_searchBox_category,
      .mf_finder_searchBox_misc label.mf_finder_searchBox_sort,
      .mf_finder_searchBox_pagemax {
        // min-width: 300px;
        min-width:calc(100% - 70px);
        max-width: calc(100% - 70px);
        width: calc(100% - 70px);
      }

      .mf_finder_searchBox_category::before,
      .mf_finder_searchBox_misc label.mf_finder_searchBox_sort::before,
      .mf_finder_searchBox_pagemax::before {
        @include fs(12);
      }

      .mf_finder_searchBox_category_select,
      .mf_finder_searchBox_sort_select,
      .mf_finder_searchBox_pagemax_select {
        @include fs(16);
        margin-top: -1px;
      }
    }
  }

  @media (max-width: 767px) {
    body.theme-search .mf_finder_relatedkeywords {
      display: block;

      .mf_finder_relatedkeywords_head {
        padding: 0;
        position: static;

        &::before {
          @include fs(16);
        }
      }

      .mf_finder_relatedkeywords_items {
        margin-top: 0;

        .mf_finder_relatedkeywords_item_link {
          padding: 4px 10px;

          > span {
            font-size: 1rem;
            line-height: 1.2;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    // body.theme-search .str-results-container {
    .str-results-container {
      flex-direction: column-reverse;

      .results_container {
        border: none;
        padding: 30px 0 0;
        flex: 0 1 auto;
      }

      .mf_finder_msk_wrapper,
      .mf_finder_organic_header_wrapper {
        padding-top: 0;
      }

      .mf_finder_msk {
        padding: 0 0 10px 0;
      }

      .mf_finder_organic_header_wrapper,
      .mf_finder_organic_header {
        padding-right: 0;
      }

      .mf_finder_msk,
      .mf_finder_organic_header_wrapper,
      .mf_finder_organic_header {
        border: none;
      }

      .mf_finder_organic_docs_wrapper {
        border: none;
        padding: 0;
      }

      .mf_finder_msk {
        text-align: center;
      }

      .mf_finder_organic_header {
        align-items: center;
        display: flex;
      }

      .mf_finder_query {
        &::before,
        &::after {
          @include fs(14);
        }
      }

      .mf_finder_organic_range_from {
        &::before,
        &::after {
          @include fs(14);
        }
      }

      .mf_finder_organic_range_to {
        &::before,
        &::after {
          @include fs(14);
        }
      }

      .mf_finder_organic_total {
        &::before,
        &::after {
          @include fs(14);
        }
      }

      .mf_finder_organic_range_from,
      .mf_finder_organic_range_to {
        @include fs(14);
      }

      .mf_finder_query > span {
        @include fs(14);
      }

      .mf_finder_organic_total > span {
        // font-size: 2rem;
        @include fs(14);
      }

      .mf_finder_organic_doc {
        display: block;
        padding: 20px 0;
      }

      .mf_finder_organic_doc_img_wrapper {
        float: left;
        max-width: 31%;
        margin-right: 3%;
        margin-bottom: 3%;

        .mf_finder_organic_doc_img {
          min-width: auto;
        }
      }

      .mf_finder_organic_doc_contents_wrapper {
        display: inline;
        max-width: none;
        padding-left: 0;

        .mf_finder_organic_doc_contents,
        .mf_finder_organic_doc_title_wrapper,
        .mf_finder_organic_doc_url_wrapper {
          display: inline;
        }

        .mf_finder_organic_doc_title_wrapper .mf_finder_organic_doc_title {
          @include fs(14);
          font-weight: normal;
          margin-top: 5px;
        }

        .mf_finder_organic_doc_body {
          @include fs(14);
          margin-top: 5px;
        }
      }

      .mf_finder_organic_doc_url_wrapper .mf_finder_organic_doc_url {
        @include fs(14);
      }

      .mf_finder_pager_items {
        > li span {
          width: 30px;
          height: 30px;
        }

        .mf_finder_pager_item_prev span::before,
        .mf_finder_pager_item_next span::before {
          width: 10px;
        }

        .mf_finder_pager_item_prev span::after,
        .mf_finder_pager_item_next span::after {
          // content: none;
          line-height:24px;
        }
      }

      // .refine-search-container {
      //   margin: 85px -10px 0;
      //   padding: 0;

      //   .mf_finder_drilldown {
      //     padding: 0;
      //   }

      //   .mf_finder_drilldown_head {
      //     @include fs(16);
      //     margin-bottom: 0;
      //     padding: 15px 0;

      //     &::before {
      //       @include fs(16);
      //     }
      //   }

      //   .mf_finder_drilldown_items li {
      //     .mf_finder_drilldown_item_link {
      //       @include fs(14);
      //       padding: 12px 10px 12px 0;

      //       .mf_finder_drilldown_item_count {
      //         @include fs(14);
      //       }
      //     }

      //     .mf_finder_drilldown_item_depth_2 .mf_finder_drilldown_item_link {
      //       @include fs(12);

      //       .mf_finder_drilldown_item_count {
      //         @include fs(12);
      //       }
      //     }

      //     .mf_finder_drilldown_item_bullet {
      //       min-width: 25px;

      //       &::before {
      //         width: 9px;
      //         height: 9px;
      //         top: calc(11px + 0.4em);
      //       }
      //     }

      //     .mf_finder_drilldown_item_depth_2
      //       .mf_finder_drilldown_item_bullet::before {
      //       width: 6px;
      //       height: 6px;
      //     }

      //     .mf_finder_drilldown_item_children {
      //       margin-left: 10px;

      //       .mf_finder_drilldown_item_bullet {
      //         min-width: 20px;
      //       }
      //     }
      //   }
      // }
    }
  }

  /* .str-content (Extension)
 * ------------------------------------------ */

  /* .search-word-container
 * ------------------------------------------ */

  @media (max-width: 767px) {
    // body.theme-search .search-word-container {
    .search-word-container {
      display: block;

      > .word-title {
        display: block;
        @include fs(16);
        padding: 0 0 5px 0;
      }

      .search-word-contents .condition {
        flex-direction: column;
        width: auto;
        margin-top: 0;

        > .condition-item {
          flex: 0 1 auto;
          @include fs(12);
          margin-top: 10px;
          max-width: none;

          + .condition-item {
            margin-left: 0;
          }

          &._category {
            flex: 0 1 auto;
          }
        }

        .mod-form-select {
          margin-top: 2px;
        }
      }
    }
  }

  /* .mod-hoge (Extension)
 * ------------------------------------------ */

  @media (max-width: 767px) {
    // body.theme-search .search-word-contents .mod-form-select select {
    .search-word-contents .mod-form-select select {
      min-width: 300px;
    }
  }

  /* .unq-result-set
 * ------------------------------------------ */

  @media (max-width: 767px) {
    // body.theme-search .unq-result-set {
    .unq-result-set {
      text-align: center;

      .if-word {
        @include fs(12);

        .keyword {
          @include fs(14);
        }
      }

      .keyword-result .keyword {
        @include fs(16);
      }

      .number-result {
        .number {
          @include fs(14);
        }

        .whole-number {
          @include fs(20);
        }
      }
    }
  }

  /* .search-result-list
 * ------------------------------------------ */

  @media (max-width: 767px) {
    // body.theme-search .search-result-list .result .link {
    .search-result-list .result .link {
      padding: 20px 0;
    }
  }

  /* .unq-text-around
 * ------------------------------------------ */

  @media (max-width: 767px) {
    // body.theme-search .unq-text-around {
    .unq-text-around {
      display: block;

      .content {
        max-width: none;
        padding-left: 0;

        .title {
          @include fs(14);
          margin-top: 5px;
        }

        .mod-txt {
          margin-top: 5px;

          .text {
            @include fs(14);
          }
        }

        .url {
          @include fs(12);
          margin-top: 5px;
        }
      }

      .image {
        float: left;
        max-width: 31%;
        min-width: auto;
        margin-right: 3%;
        margin-bottom: 3%;
      }
    }
  }
}
