.m-btnWrap{
  display: flex;
  justify-content:center;
  
  @include sp() {
    flex-direction: column;
    justify-content:center;
    align-items:center;
  }
  &__item{
    max-width: 320px;
    width: 100%;
    margin: auto 10px;
    
    @include sp() {
      // max-width: none;
      &+&{
        margin-top: 10px;
      }
      
    }
  }
}
