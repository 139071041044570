.p-topResearch{
  display: flex;
  justify-content: space-between;
  padding: 40px 0 100px;
  @include sp() {
    flex-direction: column;
    
  }
  
  &__item{
    width: 30%;
    @include sp() {
      width: 100%;
      
    }
    .card{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
    .th{
      background-color: #002f37;
      display: block;
      flex-shrink: 0;
    }
    .textwrap{
      padding: 20px ;
      flex-grow: 1;
      background: #fff;

    }
    .tag{
      display: inline-block;
      background: $orange;
      color: #fff;
      padding:5px 10px;
      line-height: 1;
    }
    .cats{
      padding: 10px 0;
      line-height: 1;
    }
    .title{
      a{
        color: #002f37;
      }
    
    }
  }
  


}
