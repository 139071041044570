.p-topExGpTableHead{
		margin-bottom: 30px;

    // &--overseas{
    //   padding-top: 80px;
    // }

  p{
    @include fs(14);
    padding:0 ;

  }
  h3 {
		font-family: $sans2;
		@include fs(22);
    line-height: 1;
    font-weight: 400;

		text-transform: uppercase;
		color: $marine;
		margin-bottom: 10px;

    padding:0 0 10px;

		border-bottom: 4px solid #E5E6E5;

  }
}
