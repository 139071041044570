/* ETFS
---------------------------------------------- */


.flist {
	// max-width: 1208px;
	max-width: 1220px;
	border-top: 2px solid $orange;
	margin: 0 auto;
	background-color: #fff;

	.flist-wrap {
		padding: 40px 50px 50px;
	}
	.list {
		// overflow: hidden; // dont use this - breaks the floating header
	}
	.tbl-scroll {
		overflow-x: auto;
	}
	table {
		table-layout: fixed;
		// border-collapse: separate;
		width: 100%;
		font-family: $sans-c;

		@include mq(1079) {
			// padding: 0 30px;
		}
		@include mq(991) {
			padding: 0;
		}

		a {
			color: $marine;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}


/* =============== FILTER =============== */

.flist {

	.filter {
		margin-bottom: 20px;
		display: none;

		@include mq(1079) {
			padding: 0 15px;
		}

		h6 {
			@include fs(20,26,700);
			margin-bottom: 30px;
			padding-top: 12px;
			color: $marine;

			span {
				@include fs(12,16,400);
				color: $ngrey1;
				float: right;
				padding: 3px 3px 8px 0;
				cursor: pointer;
				display: none;

				&:hover {
					color: $orange;

					svg {
						fill: $orange;
					}
				}
			}
			svg {
				width: 16px;
				height: 16px;
				display: inline-block;
				fill: $ngrey1;
				position: relative;
				top: 3px;
				margin-right: 4px;
				transition: fill 0.2s ease-out;
			}
		}
		ul {
			@include mq(479) {
				border-top: 1px solid $ngrey4;
			}
		}
		li {
			background-color: $ngrey7;
			padding: 10px 20px;
			border-radius: 10px;
			margin-bottom: 10px;
			display: inline-block;

			@include mq(479) {
				display: block;
				margin: 0;
				background-color: transparent;
				border-bottom: 1px solid $ngrey4;
				border-radius: 0;
				padding: 0;
				position: relative;
			}

			&.open {

				.desc {
					display: block;
				}
			}
			&.all {

			}
		}
		button {
			border: none;
			background: none;
			padding: 0;
			display: inline-block;
			text-align: left;
			font-family: $sans-c;
			@include fs(16,20,700);
			color: $marine;
			transition: color 0.2s ease-out;
			outline: none;

			@include mq(479) {
				padding: 8px 0;
				margin: 8px 0;
				display: block;
				width: 100%;
			}

			&:hover {
				color: $orange;
			}
		}
		.close {
			position: absolute;
			right: 20px;
			top: 0;
			padding: 0;
			width: auto;
			display: none;

			@include mq(479) {
				display: block;
			}

			span {
				display: inline-block;
				width: 44px;
				height: 44px;
				position: relative;
				top: 16px;
				margin-left: 10px;
				background-color: $marine;
				border-radius: 50%;
			}
			svg {
				width: 18px;
				height: 18px;
				fill: #fff;
				position: relative;
				top: 13px;
				left: 13px;
			}
		}
	}
	.mobile-filter {
		padding: 0 15px;

		h6 {
			@include fs(14,20,700);
			color: $marine;
			margin-right: 6px;
			display: inline-block;
		}
		button {
			background-color: transparent;
			border: none;
			padding: 10px 20px 10px 6px;
			display: inline-block;
			font-family: $sans-c;
			@include fs(18,24,700);
			color: $marine;
		}
		svg {
			width: 8px;
			height: 14px;
			display: inline-block;
			transform: rotate(90deg);
			transition:all .1s;
			fill: $orange;
			margin-left: 6px;
			position: relative;
			top: 2px;
		}
	}
}
body.filter-open {
	.filter {
		display: block;
	}
	.mobile-filter  {
		svg {
			transform: rotate(-90deg);
		}
	}
}


/* =============== TOGGLE =============== */

.flist {

	.list-toggle {
		background-color: #fff;
		position: sticky;
		top: 0;
		// z-index: 100;
		z-index: 98;

		// @include mq(1079) {
		// 	padding: 5px 30px 0;
		// }
		@include mq(991) {
			padding: 5px 0 0;
		}
		@include mq(767) {
			padding-top: 20px;
			top: -64px
		}

		.flex {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			flex-direction: row-reverse;
			align-items: flex-end;
			padding-bottom: 20px;


			@include mq(767) {
				flex-direction: row;
			}

			.fund-base-info {
				@include mq(767) {
					width: fit-content;
				}
				.info-items {
					display: flex;
					justify-content: end;
					.item {
						text-align: left;
						&:nth-child(1) {
							width: 100px;
							@include mq(767) {
								width: 80px;
							}
						}
						&:nth-child(3) {
							width: 95px;
							@include mq(767) {
								width: 80px;
							}
						}
					}
					&.base-date {
						justify-content: start;
					}
				}
				&.en {
					.info-items {
						.item {
							&:nth-child(1) {
								width: 70px;
								@include mq(767) {
									width: 55px;
								}
							}
							&:nth-child(3) {
								width: 35px;
								@include mq(767) {
									width: 30px;
								}
							}
						}
					}
				}
			}
		}
		.filters {
			display: flex;
			// margin-bottom: 40px;

			p {

				&:first-child  {
					// margin-right: 20px;
					margin-right: 15px;
				}
			}
			strong {
				@include font-brown;
				@include fs(11,20,400);
				color: $marine;
			}
		}
		.dt {
			text-align: right;
			// margin-bottom: 20px;

			@include mq(767) {
				flex: 100%;
				text-align: left;
				// margin-bottom: 40px;
			}

			&.overseas {
				padding-top: 30px;
			}
		}
		small {
			@include fs(12,16,400);
			color: $ngrey1;
			display: block;
			position: relative;
			// top: 49px;

			@include mq(991) {
				font-size: 11px;
			}

			@include mq(767) {
				font-size: 10px;
				line-height: 15px;
			}
		}
		.annotation-nisa {
			&.item-wrap {
				padding-left: 5px;
				padding-top: 5px;
				padding-bottom: 5px;
				.item-text {
					font-size: .75rem;
					line-height: 1.33333em;
					font-weight: 400;
					letter-spacing: 0;
					color: #727272;

					@include mq(991) {
						font-size: 11px;
					}

					@include mq(767) {
						font-size: 10px;
						line-height: 15px;
					}
				}
			}
		}
	}

}


/* =============== TABLE HEADER =============== */

.flist {

	// NOTE - this tbl is needed for workaround to position: sticky bug on thead in Chrome
	.tbl-head {
		background-color: #fff;
		position: sticky;
		// top: 106px;
		top: 86px;
		height: 41px;
		z-index: 9;

		@include mq(991) {
			top: 91px;
		}
		@include mq(929) {
			height: 38px;
		}
		@include mq(767) {
			// top: 117px;
			top: 87px;
		}

		&.is-flexTicker {
			top: 92px;

			@include mq(991) {
				// top: 107px;
				top: 95px;
			}

			@include mq(767) {
				// top: 103px;
				top: 92px;
			}
		}
	}
	.scroller {
		overflow-x: hidden;
	}
	thead {
		// NOTE - the thead exists to maintain tablesorter functionality, but it is hidden from view
		background-color: #fff;
	}
	th:first-of-type {
		width: 75px;

		@include mq(929) {
			width: 65px;
		}
	}
	th:nth-of-type(2) {
		width: 300px;
	}
	th:nth-of-type(3) {
		width: 100px;
	}
	th:nth-of-type(4) {
		text-align: center !important;
		width: 130px;
	}
	th:nth-of-type(5) {
		text-align: center !important;
		width: 100px;
	}
	th:nth-of-type(6) {
		text-align: center !important;
		width: 80px;
	}
	th:nth-of-type(7) {
		text-align: center !important;
		width: 60px;
	}
	th:nth-of-type(8) {
		width: 90px;
	}
	.fund-list-thead-dm {
		th:first-of-type {
			width: 65px;
			@include mq(1199) {
				width: 80px;
			}
			@include mq(929) {
				width: 65px;
			}
		}
		th:nth-of-type(2) {
			width: 360px;
			@include mq(1199) {
				width: 480px;
			}
			@include mq(929) {
				width: 360px;
			}
		}
		th:nth-of-type(3) {
			width: 75px;

			@include mq(1199) {
				width: 105px;
			}
			@include mq(929) {
				width: 80px;
			}
		}
		th:nth-of-type(4) {
			width: 80px;
			@include mq(1199) {
				width: 100px;
			}
			@include mq(929) {
				width: 90px;
			}
		}
		th:nth-of-type(5) {
			width: 70px;
			@include mq(1199) {
				width: 90px;
			}
			@include mq(929) {
				width: 80px;
			}
		}
		th:nth-of-type(6) {
			width: 75px;
			@include mq(1199) {
				width: 100px;
			}
			@include mq(929) {
				width: 70px;
			}
		}
		th:nth-of-type(7) {
			width: 80px;
			@include mq(1199) {
				width: 100px;
			}
			@include mq(929) {
				width: 80px;
			}
		}
		&.ja {
		}
		&.en {
			th:first-of-type  {
				width: 55px;
				@include mq(929) {
					width: 50px;
				}
			}
			th:nth-of-type(2) {
				width: 330px;
				@include mq(1199) {
					width: 460px;
				}
				@include mq(929) {
					width: 330px;
				}
			}
			th:nth-of-type(3) {
				width: 80px;
				@include mq(1199) {
					width: 120px;
				}
				@include mq(929) {
					width: 90px;
				}
			}
			th:nth-of-type(4) {
				width: 90px;
				@include mq(1199) {
					width: 130px;
				}
				@include mq(929) {
					width: 90px;
				}
			}
			th:nth-of-type(7) {
				width: 60px;
			}
		}
	}
	.fund-list-thead-en {
		th:nth-of-type(2) {
			width: 440px;
		}
		th:nth-of-type(3) {
			width: 110px;
		}
		th:nth-of-type(5) {
			width: 80px;
		}
		th:nth-of-type(8) {
			text-align: center;
		}
		&.ja {
			th:nth-of-type(2) {
				@include mq(929) {
					width: 360px;
				}
			}
			th:nth-of-type(3) {
				@include mq(929) {
					width: 80px;
				}
			}
		}
		&.en {
			th:first-of-type {
				width: 55px;
				@include mq(929) {
					width: 50px;
				}
			}
			th:nth-of-type(2) {
				width: 320px;
				@include mq(929) {
					width: 260px;
				}
			}
			th:nth-of-type(3) {
				width: 90px;
				@include mq(1199) {
					width: 120px;
				}
				@include mq(929) {
					width: 90px;
				}
			}
		}
	}

	.tb3 {

		@include mq(767) {
			width: 180px;
		}
	}
	th {
		font-family: $sans-c;
		@include fs(14,16,700);
		text-align: left;
		padding: 13px 5px 10px;
		vertical-align: middle;
		border-top: 1px solid $ngrey4;
		border-bottom: 1px solid $ngrey4;
		position: relative;
		white-space: nowrap;
		user-select: none;

		@include mq(929) {
			// font-size: 68%;
			font-size: 11px;
			line-height: 13px
		}


		:focus {
			outline: none;
		}
		&.tal {
			text-align: left;
			// &.col-ticker {
			// 	width: 80px;
			// }
		}
		&.tar {
			text-align: right;
		}
		&.tar.tb2 {
			padding-right: 11px;

			@include mq(929)  {
				padding-right: 7px;
			}
		}
		&.tar.line5 {
			padding-right: 14px;

			@include mq(929)  {
				padding-right: 11px;
			}
		}
		&.line3 {
			&:before {
				content: '';
				width: 1px;
				height: 70%;
				background-color: $ngrey4;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		&.line4 {
			&:before {
				content: '';
				width: 1px;
				height: 70%;
				background-color: $ngrey4;
				position: absolute;
				left: 7px;
				top: 0;
			}
		}
		&.line5 {
			&:after {
				content: '';
				width: 1px;
				height: 70%;
				background-color: $ngrey4;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		span {
			display: inline-block;
			cursor: pointer;
			color: $marine;
			transition: color 0.2s ease-out;
			position: relative;

			&:hover {
				color: $orange;
			}
			&.sort-desc,
			&.sort-asc,
			&.sorting {
				color: $orange;
			}
			sup {
				position: absolute;
				top: 5px;
				text-decoration: none !important;
			}
		}
	}
	.extra {
		display: none;

		th {
			border-bottom: none;
			border-top: 1px solid $ngrey4;
		}
		.line1 {

			&:before {
				content: '';
				width: 1px;
				height: 70%;
				background-color: $ngrey4;
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
		th.line2 {

			&:before {
				content: '';
				width: 1px;
				height: 70%;
				background-color: $ngrey4;
				position: absolute;
				left: 7px;
				bottom: 0;
			}
			&:after {
				content: '';
				width: 1px;
				height: 70%;
				background-color: $ngrey4;
				position: absolute;
				right: 0;
				bottom: 0;
			}
			div {
				margin: 0 8px 0 15px;
			}
		}
		th.tb2 {
			color: $marine;
			padding: 0 5px;
		}
		div {
			padding: 8px 0 5px;
			margin: 0 1px 0 8px;
			font-weight: 400;
			border-bottom: 1px solid $ngrey4;
		}
		@include mq(767) {

			.line1 {
				width: 180px;
			}
			.line2 {
				width: 300px;
			}
			th:nth-of-type(10) {
				width: 60px;
			}
			.tb3 {
				width: 180px;
			}
		}
	}
}



/* =============== TABLE BODY =============== */

.flist {

	.tbl-scroll {
		position: relative;
		top: -41px;
		z-index: 1;

		@include mq(929) {
			top: -38px;
		}

		.even {
			background-color: $ngrey7;
		}
		td {
			font-family: $sans-c;
			@include fs(15,24,400,0.2);
			vertical-align: middle;
			padding: 7px 5px 5px;
			text-align: center;
			border-bottom: 1px solid $lines;
			white-space: nowrap;
			font-variant-numeric: tabular-nums;

			@include mq(929) {
				font-size: 68%;
			}

			&.tal {
				text-align: left;
			}
			&.tar {
				text-align: right;
			}
			&.nav {
				padding-right: 0;
			}
			&.na {
				padding-right: 30px;
			}
			&:nth-of-type(2) > a,
			&:nth-of-type(3) {
				white-space: normal;
			}
			&.item-nav {
				padding-right: 30px;
				@include mq(1199) {
					padding-right: 1.5rem;
				}
			}
			&.item-mgt {
				padding-left: 25px;
				@include mq(1199) {
					padding-left: 1.5rem;
				}
				@include mq(929) {
					padding-left: 20px;
				}
			}
			.flex-ticker {
				&.item-wrap {
					display: flex;
					gap: 0.3rem;
					@include mq(929) {
						gap: 0;
					}
					.item {
						&:first-child {
							width: 1.5rem;
						}
						.link-nisa {
							color: $orange;
							font-size: 0.75rem;
							@include mq(929) {
								font-size: 0.65rem;
							}
						}
					}
				}
			}
		}
		b {
			font-weight: 700;
		}
		svg {
			display: inline-block;
			width: 16px;
		}
	}
	footer {
		@include fs(15,24,400,0.2);
		color: $ngrey2;
		margin: 40px 0 60px;

		@include mq(5999) {
			margin-top: 20px;
		}
		@include mq(1079) {
			padding: 0 30px;
		}
		@include mq(991) {
			padding: 0 15px;
		}
		@include mq(929) {
			font-size: 68%;
		}

		p {
			margin-bottom: 20px;
			position: relative;
		}
		em {
			font-style: italic;
			font-weight: 700;
		}
	}
	.links {
		display: flex;
		justify-content: space-between;
		margin: 0 -15px 80px;

		@include mq(1079) {
			padding: 0 30px;
		}
		@include mq(991) {
			padding: 0 15px;
		}
		@include mq(767) {
			flex-wrap: wrap;
		}
		@include mq(599) {
			display: block;
		}
	}
	.xbrl {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
		padding: 15px;

		@include mq(767) {
			flex: 0 0 50%;
			max-width: 50%;
		}
		@include mq(599) {
			max-width: none;
		}

		a {
			font-family: $sans-c;
			@include fs(15,24,700,0.2);
			padding: 18px 5px 15px 20px;
			border: 1px solid $lines;
			display: block;
			position: relative;
			background-color: #fff;
			box-shadow: 0 6px 9px 0 rgba(41,52,56,0.17);
			color: $marine;
			white-space: nowrap;
			overflow: hidden;

			@include mq(991) {
				padding-left: 13px;
			}
		}
		i {
			padding-right: 10px;
			&:before {
				color: $orange;
			}
		}
	}
}













// .fund-detail {
// 	background-color: $ngrey7;

// 	.mb100 {
// 		margin-bottom: 100px;
// 	}
// 	.icon-help {
// 		cursor: pointer;
// 		color: $orange;
// 		position: relative;
// 		top: 2px;
// 		margin-left: 5px;
// 	}
// }
.fd-1 {
	background: $marine url(/images/bg1.svg) left top repeat;
	font-family: $sans2;
	color: #fff;
	padding-bottom: 360px;

	@include mq(991) {
		padding-bottom: 300px;
	}

	.main-header {
		margin-bottom: 80px;
	}
	.main-header2 {
		margin-bottom: 80px;
	}
	hr {
		border-top: 7px solid $orange;
		width: 30px;
		margin-bottom: 17px;
	}
	h2 {
		@include fs(72,72,700,0.5);
		margin-left: -6px;

		@include mq(991) {
			margin-bottom: 46px;
		}
	}
	h1 {
		@include fs(28,28,400,0.5);
		padding-bottom: 6px;
		margin-left: -2px;

		span {
			display: block;
			color: $sea;
			font-size: 20px;
			padding-top: 12px;
		}
	}
	.flex1 {
		display: flex;

		@include mq(991) {
			display: block;
		}
	}
	.flex2 {
		display: flex;

		@include mq(767) {
			display: block;
		}
	}
	.ticker,
	.invest {
		flex: 0 0 250px;
		max-width: 250px;

		@include mq(767) {
			flex: none;
			max-width: none;
		}
	}
	.name {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include mq(991) {
			display: block;
			margin-top: 15px;
		}
	}
	.invest {
		margin-top: 50px;

		@include mq(991) {
			margin-top: 70px;
		}

		a {
			@include fs(17,21,400,0.5);
			font-family: $sans2;
			display: inline-block;
			outline: none;
			transition: color 0.2s ease-out;
			color: #fff;
			border: 1px solid #fff;
			border-radius: 5px;
			padding: 8px 22px;
			transition: all 0.2s ease-out;

			&.active {
				color: $orange;
			}
			&:hover {
				color: $orange;
				border-color: $orange;
			}
		}
	}
	.morningstar {
		margin-top: 47px;
		margin-bottom: -50px;

		@include mq(991) {
			margin-top: 67px;
		}
		@include mq(767) {
			margin-top: 30px;
		}

		h3 {
			@include fs(14,18,400);
			padding-left: 3px;

			@include mq(767) {
				display: inline;
				position: relative;
				top: -5px;
			}
		}
		small {
			color: $sea;
			font-size: 12px;

			@include mq(767) {
				display: block;
			}
		}
		.stars {
			position: relative;
			padding-right: 15px;
			height: 26px;

			@include mq(767) {
				display: inline;
				padding-right: 5px;
			}
		}
		svg {
			width: 22px;
			display: inline-block;
			padding: 2px;
			fill: #fff;
		}
		img {
			max-width: 200px;
		}
		.icon-help {
			top: 0;
			font-size: 14px;
		}
	}
}
.fd-2 {
	margin-top: -220px;
	position: relative;
	z-index: 2;

	.links {

		@include mq(991) {
			overflow-x: auto;
		}

		a {
			@include fs(17,21,400,0.5);
			font-family: $sans2;
			display: inline-block;
			position: relative;
			// @include after-arrow($orange);
			margin-right: 50px;
			outline: none;
			transition: color 0.2s ease-out;
			padding-bottom: 20px;
			color: #fff;
			white-space: nowrap;

			&.active {
				// border-bottom: 5px solid $orange;
				color: $orange;
			}
			&:hover {
				color: $orange;
			}
		}
	}
	.links-container {

		@include mq(991) {
			display: flex;
		}
	}
	.sticky {
		position: fixed;
		top: -100px;
		background: #002f37;
		left: 0;
		width: 100%;
		padding-top: 14px;
		z-index:100;
		/*animation:slideTop;
		animation-duration:1s;
		animation-timing-function:cubic-bezier(.165,.84,.44,1);*/
		transition: top 1s;

		@include mq(575) {
			overflow-x:scroll;
			padding-right:60px;
		}
		.links-container {
			max-width: 1268px;
			padding-left: 30px;
    		padding-right: 30px;
    		margin:0 auto;
    		position:relative;
    		a:last-child {
				@include mq(575) {
					padding-right:100px;
				}
    		}

		}
	}
	.show {
		top:0;
	}

	.h2-sticky {
		padding-top:40px;
	}
	.box {
		background-color: #fff;
		padding: 50px;
		// border-top: 2px solid $orange;

		@include mq(767) {
			padding: 40px 15px;
		}
	}
	h2 {
		// font-family: $sans2;
		// @include fs(22,27,400,1);
		// text-transform: uppercase;
		// @include fs(17,21,400,0.5);
		// font-family: $sans2;
		@include font-brownbold;
		@include fs(28,30,400);
		color: $marine;
		margin-bottom: 30px;
	}
	.flex {
		display: flex;
		justify-content: flex-start;
		margin: 0 -30px;

		@include mq(767) {
			margin: 0;
			display: block;
		}
	}
	.fd-why {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
		margin-bottom: 40px;
		padding: 0 30px;

		@include mq(767) {
			max-width: none;
			padding: 0;
		}

		svg {
			fill: $orange;
			height: 120px;
			width: auto;
			margin-bottom: 20px;
			display: inline-block;

			@include mq(767) {
				height: 100px;
			}
		}
		p {
			@include fs(16,26,400);

			@include mq(767) {
				@include fs(15,26,400);
			}
		}
		sup {
			color: $ngrey2;
		}
	}
	h3 {
		font-family: $sans-c;
		@include fs(18,20,700,0.2);
		color: $marine;
		padding-bottom: 12px;
		margin-bottom: 8px;
		border-bottom: 1px solid $marine;
	}
	p.spec {
		@include fs(12,20,400);
		color: $ngrey2;
		margin-top: -20px;
	}
}
// .fd-3 {
// 	padding: 80px 0 0;

// 	.box {
// 		background-color: #fff;
// 		padding: 50px 50px;
// 		display: block;
// 		display: flex;
// 		justify-content: space-between;
// 		flex-wrap: wrap;
// 		@include mq(767) {
// 			display: block;
// 			padding: 40px 15px;
// 		}
// 		.footnotes {
// 			@include fs(12,20,400);
// 			color: $ngrey2;

// 			@include mq(767) {
// 				padding-top: 20px;
// 			}
// 		}
// 	}
// 	h3 {
// 		font-family: $sans-c;
// 		@include fs(18,33,700,0.2);
// 		// @include hhh5;
// 		border-bottom: 4px solid $lines;
// 		padding-bottom: 10px;
// 		color: $marine;

// 		small {
// 			font-family: $sans;
// 			color: $ngrey2;
// 			@include fs(13,20,400);
// 			text-transform: none;
// 			display: inline-block;
// 			margin-left: 8px;
// 			position: relative;
// 			top: 0;
// 		}
// 	}
// 	.table {
// 		flex: 0 0 45%;
// 		max-width: 45%;

// 		@include mq(767) {
// 			max-width: none;
// 		}
// 	}
// 	.fd-sum {
// 		margin-bottom: 20px;

// 		p {
// 			margin-top: 8px;
// 			@include fs(16,26,400);

// 			@include mq(767) {
// 				@include fs(15,26,400);
// 			}
// 		}
// 		small {
// 			@include fs(12,20,400);
// 			color: $sea;
// 		}
// 	}
// 	table {
// 		width: 100%;
// 	}
// 	td {
// 		font-family: $sans-c;
// 		@include fs(15,22,700,0.2);
// 		color: $marine;
// 		border-bottom: 1px solid $lines;
// 		padding: 7px 0;


// 		&:nth-of-type(odd) {
// 			padding-left: 10px;

// 			@include mq(767) {
// 				padding: 0;
// 			}
// 		}

// 		&:nth-of-type(2n) {
// 			font-weight: 400;
// 			text-align: right;
// 		}
// 	}
// 	.dwn {
// 		display: inline-block;
// 		position: relative;
// 		top: 3px;

// 		svg {
// 			width: 16px;
// 			fill: $marine;
// 		}
// 	}
// 	.dcal {
// 		font-family: $sans-c;
// 		@include fs(15,22,700,0.2);
// 		margin-top: 12px;
// 	}
// 	.fex {
// 		margin: 18px 0 4px;
// 		@include fs(12,20,400);
// 		color: $sea;
// 	}


// }
// .fd-5 {
// 	padding: 80px 0 0;
// 	background-color: $ngrey7;
// 	overflow: hidden;

// 	&.docs {
// 		margin-bottom: 80px;
// 	}
// 	.box {
// 		background-color: #fff;
// 		padding: 50px 50px;

// 		@include mq(767) {
// 			padding: 40px 15px;
// 		}
// 	}
// 	h3 {
// 		// font-family: $sans2;
// 		// @include fs(22,27,400,1);
// 		// text-transform: uppercase;
// 		@include hhh5;
// 		border-bottom: 4px solid $lines;
// 		padding-bottom: 24px;
// 		margin-bottom: 20px;
// 		color: $marine;

// 		small {
// 			font-family: $sans;
// 			color: $ngrey2;
// 			@include fs(13,20,400);
// 			text-transform: none;
// 			display: inline-block;
// 			margin-left: 8px;
// 			position: relative;
// 			top: 0;
// 		}
// 	}
// 	.num {
// 		font-variant-numeric: tabular-nums;
// 	}
// 	.tar {
// 		text-align: right;
// 	}
// 	.button {
// 		@include fs(12,20,700,1);
// 		text-transform: uppercase;
// 		font-family: $sans-c;
// 		display: inline-block;

// 		@include mq(575) {
// 			margin-bottom: 30px;
// 		}

// 		span,
// 		i {
// 			margin-right: 6px;
// 			font-size: 16px;
// 			position: relative;
// 			top: 2px;
// 		}
// 	}
// 	.header {

// 		h3 {
// 			border: none;
// 			margin: 0;
// 		}
// 		.button {
// 			text-align: right;
// 			@include fs(12,20,700,1);
// 			text-transform: uppercase;
// 			font-family: $sans-c;
// 			padding-top: 6px;

// 			@include mq(575) {
// 				text-align: left;
// 			}

// 			span {
// 				margin-right: 5px;
// 			}
// 		}
// 	}
// 	.fex {
// 		margin: 18px 0 4px;
// 		@include fs(12,20,400);
// 	}
// 	.note {
// 		color: $ngrey2;
// 		@include fs(13,20,400);
// 		font-variant-numeric: tabular-nums;

// 		a {
// 			color: $ngrey2;
// 		}
// 	}
// 	.ntbl {

// 		th {
// 			font-family: $sans-c;
// 			@include fs(14,16,700);
// 			text-align: left;
// 			padding: 13px 5px 10px;
// 			vertical-align: bottom;
// 			text-align: left;
// 			border-top: 1px solid $ngrey4;
// 			border-bottom: 1px solid $ngrey4;
// 			position: relative;
// 			white-space: nowrap;

// 			@include mq(929) {
// 				// font-size: 68%;
// 				font-size: 11px;
// 				line-height: 13px
// 			}

// 			&.mpad {
// 				@include mq(767) {
// 					padding-top: 60px;
// 				}
// 			}
// 			&.date,
// 			&.norm {
// 				vertical-align: top;
// 				white-space: normal;
// 			}
// 		}
// 		tr:nth-of-type(odd) {
// 			td {
// 				background-color: $ngrey7;
// 			}
// 		}
// 		td {
// 			font-family: $sans-c;
// 			@include fs(15,24,400,0.2);
// 			vertical-align: middle;
// 			padding: 7px 5px 5px;
// 			text-align: left;
// 			border-bottom: 1px solid $lines;
// 			white-space: nowrap;
// 			font-variant-numeric: tabular-nums;

// 			@include mq(929) {
// 				font-size: 68%;
// 			}

// 			&.label {
// 				font-weight: 700;
// 			}
// 		}
// 	}
// 	.fd-docs {
// 		background-color: $ngrey6;

// 		h3 {
// 			border: none;
// 			margin: 0;
// 		}
// 		ul {
// 			@include font-brown;
// 			@include fs(18,24,400);
// 			display: flex;
// 			flex-wrap: wrap;
// 			justify-content: space-between;

// 			@include mq(991) {
// 				display: block;
// 			}
// 			@include mq(413) {
// 				font-size: 14px;
// 			}
// 		}
// 		li {
// 			flex: 0 0 47%;
// 			max-width: 47%;
// 			border-bottom: 1px solid $ngrey4;

// 			@include mq(991) {
// 				max-width: none;
// 			}
// 		}
// 		@include mq(992up) {

// 			li:first-of-type,
// 			li:nth-of-type(2) {
// 				border-top: 1px solid $ngrey4;
// 			}
// 		}
// 		a {
// 			color: $marine;
// 			display: block;
// 			padding: 20px 0;

// 			&:hover {
// 				color: $orange;
// 			}
// 		}
// 		svg {
// 			display: inline-block;
// 			width: 16px;
// 			margin-right: 15px;
// 			position: relative;
// 			top: 2px;

// 			@include mq(413) {
// 				top: 3px;
// 			}
// 		}
// 	}
// }
// .fd-6 {
// 	padding-top: 80px;

// 	h3 {
// 		@include hhh5;
// 		margin-bottom: 40px;
// 		color: $marine;
// 	}
// 	.posts {
// 		display: flex;
// 		flex-wrap: wrap;
// 		margin: 0 -15px;
// 	}
// 	.card {
// 		flex: 0 0 33.33333%;
// 		max-width: 33.33333%;
// 		margin-bottom: 60px;
// 		padding-left: 15px;
// 		padding-right: 15px;

// 		@include mq(991) {
// 			flex: 0 0 100%;
// 			max-width: 100%;
// 			margin-bottom: 40px;
// 		}
// 	}
// 	.box {
// 		background-color: #fff;
// 		border: 1px solid $ngrey5;
// 		border-top: 2px solid $link-color;
// 		padding: 30px 30px 15px;
// 		height: 100%;
// 		display: flex;
// 		flex-direction: column;
// 		box-shadow: 0 6px 9px 0 rgba(41,52,56,0.17);

// 		@include mq(575) {
// 			height: auto;
// 		}
// 	}
// 	.textwrap {
// 		flex-grow: 1;
// 	}
// 	h4 {
// 		@include font-brown;
// 		@include fs(18,24,400);
// 		margin-bottom: 16px;

// 		a {
// 			color: $marine;
// 		}
// 	}
// 	.rm {
// 		transition: background-color 0.2s ease-out;
// 		@include fs(16,28,700);
// 		position: relative;
// 		align-self: bottom;
// 		@include after-arrow($orange);
// 	}
// 	.dl {
// 		@include fs(16,28,700);
// 		position: relative;
// 		align-self: bottom;
// 	}
// 	a.btn {
// 		@include fs(16,28,700);
// 		color: $orange;
// 		padding-bottom: 5px;
// 		@include after-arrow($orange);
// 	}
// }



// .fd-prices {
// 	margin-bottom: 100px;

// 	h3 {
// 		margin-bottom: 0px;
// 	}
// 	table {
// 		width: 100%;
// 		table-layout: fixed;
// 		border-collapse: separate;
// 	}
// 	td {
// 		font-family: $sans-c;
// 		@include fs(15,24,400,0.2);
// 		border-bottom: 1px solid $lines;
// 		padding: 5px 0;

// 		@include mq(479) {
// 			font-size: 11px;
// 		}

// 		&.first {
// 			width: 40%;
// 			font-weight: 700;
// 			padding-right: 15px;

// 			@include mq(413) {
// 				width: 30%;
// 			}
// 		}
// 		&.second {
// 			width: 20%;

// 			@include mq(413) {
// 				width: 17%;
// 			}
// 		}
// 		&.third {
// 			font-weight: 700;
// 			width: 20%;

// 			@include mq(413) {
// 				width: 24%;
// 			}
// 		}
// 		&.fourth {

// 		}
// 		&.bl {
// 			border-left: 1px solid $lines;
// 			padding-left: 10px;
// 		}
// 	}
// 	small {
// 		top: 3px;
// 		position: relative;
// 	}
// }
// .fd-perf {

// 	.tabs {
// 		padding: 0 5px;

// 		button {
// 			font-family: $sans-c;
// 			@include fs(18,20,700,0.2);
// 			text-align: left;
// 			padding: 5px 15px;
// 			background: none;
// 			border: none;
// 			margin: 5px 5px -1px 5px;
// 			color: $marine;
// 			transition: color 0.2s ease-out;

// 			@include mq(413) {
// 				font-size: 12px;
// 			}

// 			&.active {
// 				background-color: #fff;
// 				border: 1px solid $ngrey4;
// 				border-bottom: 1px solid #fff;
// 			}
// 			&:hover {
// 				color: $orange;
// 			}
// 		}
// 	}
// 	table {
// 		width: 100%;
// 		margin-bottom: 15px;
// 		table-layout: fixed;
// 	}
// 	.date {
// 		width: 40%;

// 		small {
// 			display: block;
// 			font-family: $sans;
// 			@include fs(13,20,400);
// 			color: $ngrey2;
// 			white-space: nowrap;
// 		}
// 	}
// 	.selector {
// 		display: none;

// 		&.show {
// 			display: block;
// 		}
// 	}
// 	tbody {
// 		display: none;
// 		width: 100%;

// 		&.active {
// 			display: table-row-group;
// 		}
// 	}
// 	.select {
// 		display: inline;
// 		position: relative;
// 		cursor: pointer;

// 		&:after {
// 			content: '';
// 			width: 14px;
// 			height: 8px;
// 			position: absolute;
// 			top: 4px;
// 			right: 6px;
// 			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCA4Ij48cGF0aCBkPSJNMi4zMyAxLjkzYS40OC40OCAwIDAxLjE0LS4zMy40Ny40NyAwIDAxLjczLS4wN0w3IDUuMzJsMy43OS0zLjc5YS41Mi41MiAwIDAxLjczLjczTDcuMzggNi4zOGMtLjI2LjI3LS41My4yNy0uNzMuMDdMMi41MyAyLjMzYS41OS41OSAwIDAxLS4yLS40eiIvPjwvc3ZnPg==);
// 		}
// 	}
// 	select {
// 		@include fs(14,16,700);
// 		color: $text-color;
// 		border: 1px solid $ngrey4;
// 		padding: 2px 20px 2px 6px;
// 		border-radius: 4px;
// 		background-color: transparent;
// 		appearance: none;
// 		outline: none;
// 		cursor: inherit;
// 		z-index: 1;
// 		position: relative;
// 	}
// 	select::-ms-expand {
// 		display: none;
// 	}

// 	.post-content {

// 		p {
// 			@include fs(15,20,400,0.2);
// 			color: $ngrey2;
// 			font-family: $sans-c;
// 		}
// 	}
// }
// .fd-details {
// 	background-color: $ngrey7;
// 	padding: 18px 14px;

// 	table {
// 		width: 100%;
// 	}
// 	h3 {
// 		margin-bottom: 5px;
// 	}
// 	h4 {
// 		font-family: $sans-c;
// 		@include fs(18,33,700,0.2);
// 		color: $marine;
// 		position: relative;
// 		transition: color 0.2s ease-out;
// 		border-bottom: 1px solid $lines;
// 		padding: 4px 0;
// 	}
// 	td {
// 		font-family: $sans-c;
// 		@include fs(15,22,700,0.2);
// 		border-bottom: 1px solid $lines;
// 		padding: 2px 0;

// 		&:nth-of-type(odd) {
// 			padding-left: 10px;
// 		}

// 		&:nth-of-type(2n) {
// 			font-weight: 400;
// 			text-align: right;
// 		}
// 	}
// }

// .fd-chars {
// 	margin-bottom: 60px;

// 	h3 {
// 		margin-bottom: 0px;
// 	}
// 	table {
// 		width: 100%;
// 		margin-bottom: 5px;
// 	}
// 	td {
// 		font-family: $sans-c;
// 		@include fs(15,24,400,0.2);
// 		border-bottom: 1px solid $lines;
// 		padding: 6px 5px 4px;

// 		&.label {
// 			font-weight: 700;
// 		}
// 		&.indent {
// 			padding-left: 30px;
// 		}
// 	}
// 	.yr {
// 		color: $granite;
// 		font-weight: 700;
// 		font-size: 13px;
// 		line-height: 24px;
// 		padding: 10px 5px;
// 	}
// }
// .fd-stats {
// 	margin-bottom: 60px;

// 	h3 {
// 		margin: 0;
// 	}
// 	h4 {
// 		color: $ngrey1;
// 		@include fs(18,20,700,0.2);
// 		font-family: $sans-c;
// 		padding: 15px 0;
// 	}
// 	table {
// 		width: 100%;
// 		margin-bottom: 5px;
// 	}
// 	th {
// 		text-transform: uppercase;
// 		font-family: $sans-c;
// 		@include fs(13,20,700,0.2);
// 		color: $ngrey1;
// 		text-align: left;
// 		border-bottom: 1px solid $lines;
// 		padding: 6px 5px 4px;
// 	}
// 	th.vs {
// 		padding-top: 12px;
// 		padding-bottom: 12px;
// 	}
// 	td {
// 		font-family: $sans-c;
// 		@include fs(15,24,400,0.2);
// 		border-bottom: 1px solid $lines;
// 		padding: 6px 5px 4px;

// 		&.label {
// 			font-weight: 700;
// 		}
// 	}
// 	.icon-help {
// 		font-size: 15px;
// 	}
// }
.fd-pies {

	.flex {
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: flex-start;

		h3,
		.pie-wrap,
		.end {
			width: 100%;
		}
		.end {
			align-self: flex-end;
		}
	}
	.pie-wrap {
		display: flex;
		flex-grow: 1;
		// align-items: center;

		@include mq(767) {
			display: block;
		}
	}
	.chart {
		max-width: 200px;
		flex: 0 0 200px;
		margin-bottom: 20px;

		@include mq(767) {
			flex: none;
		}
	}
	.table {
		width: calc(100% - 260px);
		margin-left: 60px;
		margin-bottom: 20px;

		@include mq(767) {
			width: 100%;
			margin: 0 0 20px;
		}

		th {
			text-transform: uppercase;
			font-family: $sans-c;
			@include fs(13,13,700,0.2);
			color: $ngrey1;
			border-bottom: 1px solid $lines;
			padding: 0 0 7px 5px;
			text-align: left;

			&.tar {
				text-align: right;
			}
		}
		td {
			font-family: $sans-c;
			@include fs(15,20,400,0.2);
			border-bottom: 1px solid $lines;
			padding: 3px 5px 1px;

			&:nth-of-type(3) {
				text-align: right;
				padding-left: 30px;
				font-variant-numeric: tabular-nums;
			}
		}
		.dot {
			padding-right: 10px;
			width: 22px;

			span {
				width: 12px;
				height: 12px;
				display: inline-block;
				border-radius: 50%;
				background-color: #DDD;

				&.color1 {
					background-color: #FF5400;
				}
				&.color2 {
					background-color: #002F37;
				}
				&.color3 {
					background-color: #8EB1B2;
				}
				&.color4 {
					background-color: #606060;
				}
				&.color5 {
					background-color: #3d3935;
				}
				&.color6 {
					background-color: #727272;
				}
				&.color7 {
					background-color: #8E8E8E;
				}
				&.color8 {
					background-color: #A9A9A8;
				}
				&.color9 {
					background-color: #BCBEBC;
				}
				&.color10 {
					background-color: #D2D3D1;
				}
				&.color11 {
					background-color: #E5E6E5;
				}
				&.color12 {
					background-color: #F4F4F4;
				}
			}
		}
	}
}
.top-10-wrap {

	@include mq(767){
		overflow: auto;
	}

	table {
		width: 100%;
	}
	.tar {

		@include mq(767) {
			text-align: left;
		}
	}
}
// .options-wrap {
// 	margin-bottom: 60px;

// 	@include mq(767){
// 		overflow: auto;
// 	}

// 	table {
// 		width: 100%;
// 		margin-top: 10px;
// 		table-layout: fixed;
// 		border-collapse: separate;
// 	}
// 	.tar {

// 		@include mq(767) {
// 			text-align: left;
// 		}
// 	}
// 	.tcol-1 {
// 		width: 28%;

// 		@include mq(767) {
// 			width: 206px;
// 		}
// 	}
// 	.tcol-2 {
// 		width: 18%;

// 		@include mq(767) {
// 			width: 132px;
// 		}
// 	}
// 	.tcol-3 {
// 		width: 11%;

// 		@include mq(767) {
// 			width: 82px;
// 		}
// 	}
// 	.tcol-4 {
// 		width: 13%;

// 		@include mq(767) {
// 			width: 96px;
// 		}
// 	}
// 	.tcol-5 {
// 		width: 15%;

// 		@include mq(767) {
// 			width: 111px;
// 		}
// 	}
// 	.tcol-6 {
// 		width: 15%;

// 		@include mq(767) {
// 			width: 111px;
// 		}
// 	}
// }

#modal-invest {

	.modal-dialog {
		max-width: 700px;

		@include mq(767) {
			max-width: 100%;
			margin: 10px;
		}
	}
	.modal-content {
		border-radius: 0;
		padding: 30px 12px 20px;
		background-color: $lines;

		@include mq(413) {
			padding: 30px 4px 20px;
		}

		h3 {
			@include fs(17,21,400,0.5);
			font-family: $sans2;
			text-align: center;
			color: $marine;
			border-bottom: 1px solid $lines;
			margin-bottom: 10px;
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 30px;
		}
		li {
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
			border: 4px solid $lines;

			@include mq(767) {
				flex: 0 0 50%;
				max-width: 50%;
			}
			@include mq(479) {
				font-size: 14px;
			}
			@include mq(374) {
				flex: none;
				max-width: none;
			}

			a {
				display: block;
				padding: 10px 30px 10px 10px;
				position: relative;
				background-color: #fff;
				color: $marine;

				&:hover {
					color: $orange;
				}
			}
		}
		svg {
			width: 8px;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-7px);
		}
	}
	small {
		text-align: center;
		@include fs(14,26,400);
		margin-bottom: 20px;
	}
	.center {
		margin-bottom: 20px;

		a {
			@include fs(16,28,400);
			@include after-arrow($orange);

			@include mq(479) {
				font-size: 14px;
			}
		}
	}
}

#modal-dcal {

	.modal-dialog {
		max-width: 869px;

		@include mq(991) {
			max-width: none;
			margin: 10px;
		}
	}
	.modal-content {
		border-radius: 0;
		padding: 20px;
	}

	.legend {
		text-transform: uppercase;
		padding: 6px 5px 4px;
		vertical-align: top;
		background-color: transparent;
		font-family: $sans-c;
		@include fs(14,20,700,0.5);

		@include mq(479) {
			font-size: 9px;
		}
		span {
			display: block;
			width: 25%;
			float: left;
		}

	}
	.year {
		color: #fff;
		background-color: $orange;
		padding: 6px 5px 4px;
		font-family: $sans-c;
		@include fs(14,20,700,0.5);
		border-bottom: 2px solid #fff;
		cursor: pointer;
		transition: background-color 0.2s ease-out;

		&:hover {
			background-color: $link-hover-color;
		}
	}
	li li {
		border-bottom: 1px solid $lines;
		padding: 6px 5px 4px;
		@include fs(15,20,400,0.2);

		@include mq(479) {
			font-size: 9px;
		}

		span {
			display: block;
			width: 25%;
			float: left;
		}
	}
	li ul {
		display: none;
	}
	li:last-of-type ul {
		display: block;
	}
	.dcal-link {
		background-color: $ngrey7;
		padding: 40px 0;
		text-align: center;
		@include fs(15,20,400,0.2);
		font-family: $sans2;
	}
}
#modal-pdc {

	.modal-dialog {
		max-width: 869px;

		@include mq(991) {
			max-width: none;
			margin: 10px;
		}
	}
	.modal-content {
		border-radius: 0;
		padding: 20px;
	}
	.col1 {
		float: left;
		width: 50%;

		@include mq(991) {
			float: none;
			width: 100%;
		}

		.pad {
			padding-right: 15px;

			h3 {
				margin: 0;
			}
		}
	}
	.col2 {
		float: right;
		width: 50%;

		@include mq(991) {
			float: none;
			width: 100%;
		}

		.pad {
			padding-left: 15px;

			@include mq(991) {
				padding: 30px 0 0;
			}

			h3 {
				margin: 0;
			}
		}
	}
	h3 {
		@include hhh5;
		border-bottom: 4px solid $lines;
		padding-bottom: 12px;
		margin-bottom: 20px;
		color: $marine;

		@include mq(575) {
			font-size: 18px;
		}

		small {
			float: none;
			display: block;
			font-family: $sans;
			color: $ngrey2;
			@include fs(13,20,400);
			text-transform: none;
			position: relative;
		}
	}

	.info {
		font-family: $sans-c;
		color: $ngrey2;
		@include fs(15,20,400,0.2);

		h4 {
			font-weight: 700;
		}
		p {
			margin-bottom: 20px;
		}
		em {
			font-style: italic;
		}
	}
	.cols {
		margin: 15px 0 60px;
	}
	.table1 {
		width: 100%;

		th {
			text-transform: uppercase;
			font-family: $sans-c;
			@include fs(13,24,700,0.2);
			color: $ngrey1;
			text-align: left;
			border-bottom: 1px solid $lines;
			padding: 6px 5px 4px;
		}
		td {
			font-family: $sans-c;
			@include fs(15,24,400,0.2);
			border-bottom: 1px solid $lines;
			padding: 6px 5px 4px;

			&.label {
				font-weight: 700;
			}
		}
		.spacer {
			td {
				padding-top: 30px;
			}
		}
	}
	.button {
		@include fs(12,20,700,1);
		text-transform: uppercase;
		font-family: $sans-c;
		color: $orange;
		cursor: pointer;

		span,
		i {
			margin-right: 5px;
		}
	}
	.icon-help {
		margin-left: 0;
	}
	.popover {
		color: $marine;
	}

	.ct-bar {
		stroke: $orange;
	}
	.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
		margin-left: -4px;
	}
	.hover-note {
		float: left;

		@include mq(575) {
			float: none;
		}
	}
	.date-dropdown {
		float: right;

		@include mq(575) {
			float: none;
			display: block;
			margin-bottom: 15px;
		}
	}

}






/* FAMILY
---------------------------------------------- */

.family-hero {
	background:transparent url('/images/funds-hero.png') top center no-repeat;
	background-size: cover;
	height: 476px;

	img {
		width: 102px;
		height: 105px;
		float: left;
		margin-right: 40px;
		display: block;

		@include mq(639) {
			float: none;
		}
	}
	h1 {
		margin-top: 15px;

		@include mq(991) {
			margin-top: 28px;
		}
	}
}
#family {

	.fam {
		margin-bottom: 60px;

		h2 {
			@include fs(24,30,400,0.5);
			font-family: $sans2;
			margin-bottom: 24px;
			color: $marine;
		}
		p {
			@include fs(16,30,400);
			font-family: $sans2;
			margin-bottom: 24px;
			color: $marine;
		}
		.fam-links {
			border-left: 1px solid $lines;
			max-width: 300px;

			@include mq(767) {
				max-width: none;
			}
		}
		.fam-link {
			width: 50%;
			float: left;
			text-align: center;
			border-top: 1px solid $lines;
			border-right: 1px solid $lines;
			border-bottom: 1px solid $lines;

			&:nth-of-type(3),
			&:nth-of-type(4) {
				border-top: none;

				@include mq(767) {
					border-top: 1px solid $lines;
				}
				@include mq(599) {
					border-top: none;
				}
			}
			@include mq(767) {
				width: 25%;
			}
			@include mq(599) {
				width: 50%;
			}
			&:hover,
			&.active {
				background-color: $ngrey7;
				color: $orange;
			}
			img {
				display: block;
				width: 100%;
			}
			h3 {
				@include fs(15,33,400,0.5);
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom: 15px;
			}
		}
	}
	.fam-group {

		h2 {
			@include hhh4;
			text-transform: uppercase;
			margin-bottom: 25px;
			color: $marine;
		}
		h3 {
			font-family: $sans-c;
			@include fs(20,27,700,0.2);
			color: $marine;
			margin-bottom: 20px;
		}
		h4 {
			background-color: #fff; // background to cover up the <b>NEW!</b>
			display: inline-block;
			@include fs(15,33,400,0.5);
			position: relative;
			z-index: 2;
			padding-right: 5px;

			@include mq(413) {
				@include fs(13,30,400,0.5);
			}
		}
		p {
			margin-bottom: 40px;
		}
		ul {
			border-top: 4px solid $lines;
			max-width: 400px;
		}
		li {
			font-family: $sans-c;
			border-bottom: 1px solid $lines;

			a {
				display: block;
				padding: 2px 0;
				@include fs(15,33,400,0.5);
				position: relative;
			}
			span {
				font-weight: 700;
				display: inline-block;
				padding-left: 4px;
			}
			b {
				display: inline-block;
				position: absolute;
				top: 2px;
				right: 6%;
				font-weight: 700;
				font-size: 12px;
				color: $ngrey2;
			}
		}
	}
}
@keyframes slideTop {from{top: -100px;} to{top:0;}}
@-webkit-keyframes slideTop {from{top: -100px;} to{top:0;}}


/* Multiselect Custom CSS
---------------------------------------------- */

.multiselect{
	min-width: 8rem;

	.multiselect__single {
		@include mq(929) {
			font-size: 90%;
		}
	}

	.multiselect__tags {
		border: 1px solid #ccc !important;
		border-radius: 5px !important;
		.multiselect__single {
			cursor: pointer;
			font-weight: 700;
			&:hover {
				color: $orange;
			}
		}
	}
	.multiselect__content-wrapper {
		font-size: 11px;
		margin: 0.1rem 0;
		border: 1px solid #ccc !important;
		border-radius: 5px !important;
		.multiselect__option--highlight {
			background: #f5f5f5;
			color: $marine;
		}
		.multiselect__option--selected {
			background: #eee;
			color: $marine;
			font-weight: 400;
			&.multiselect__option--highlight {
				background: #eee;
				color: $marine;
			}
		}
	}
}


/* Add
---------------------------------------------- */

.footer_note {
	@include fs(15,27);
	color: $ngrey2;
	margin-bottom: 20px;

	@include mq(991) {
		@include fs(13,24);
	}

	p {
		padding-left: 1em;
		// margin-bottom: 20px;
		position: relative;
	}
	sup {
		position: absolute;
		top: 6px;
		left: 0;
	}
}
