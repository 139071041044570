.p-topNews{
  padding-bottom: 80px;
  padding-top: 40px;
  &__item{
    padding:  30px 0;
    border-top: 1px solid #E5E6E5;
    &:first-child{
      border-top: 2px solid #E5E6E5;
    
    }
    h3 a{
      color: #002F37;
    }
    h4{
      padding-top: 1em;
    
    }
  } 


}
