.p-fundsSideList {
  position: absolute;
  margin-bottom: 0;

  @include mq(991) {
    position: initial;
    margin-bottom: 3rem;
  }
}

.fund-detail {
  .show {
    &-pc {
      display: block;

      @include mq(991) {
        display: none;
      }
    }

    &-sp {
      display: none;

      @include mq(991) {
        display: block;
      }
    }
  }
}